import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { DatePipe, DecimalPipe, NgClass, NgIf } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { concatMap } from 'rxjs';
import { Address } from 'src/app/interfaces/address';
import { AddressesService } from 'src/app/services/crm/addresses.service';
import { CompanyService } from 'src/app/services/crm/company.service';
import { CalculateService } from 'src/app/services/finance/calculate.service';
import { SnackbarComponent } from 'src/app/snackbar/snackbar.component';
import { CustomerComponent } from '../sections/customer/customer.component';
import { ProjectComponent } from '../sections/project/project.component';
import { TextsComponent } from '../sections/texts/texts.component';
import { ContainersComponent } from '../sections/containers/containers.component';
import { SumsComponent } from '../sections/sums/sums.component';
import { OfferService } from 'src/app/services/finance/offer.service';
import { FinancialService } from 'src/app/services/finance/financial.service';
import { OfferDetailsComponent } from '../sections/offer-details/offer-details.component';

@Component({
  selector: 'financehub-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    OfferDetailsComponent,
    CustomerComponent,
    ProjectComponent,
    TextsComponent,
    ContainersComponent,
    NgClass,
    SumsComponent,
    DatePipe,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatExpansionModule,
    NgIf,
    MatToolbarModule,
    MatSnackBarModule,
  ],
  providers: [DecimalPipe],
})
export class OfferComponent {
  constructor(
    public offerService: OfferService,
    private addressService: AddressesService,
    public financialService: FinancialService,
    private _snackBar: MatSnackBar,
    public route: ActivatedRoute,
    public companyService: CompanyService,
    public calculateService: CalculateService,
    private decimalPipe: DecimalPipe,
  ) {
    this.financialService.customerForm = this.financialService.setCustomerForm();
  }

  @ViewChild('scrollTarget', { read: ElementRef }) scrollTarget!: ElementRef;

  ngOnInit() {
    this.route.data.subscribe((params) => {
      this.financialService.isEdit = params['isEdit'];
    });

    if (this.financialService.isEdit) {
      this.getOffer();
    }
  }

  getOffer() {
    this.route.paramMap
      .pipe(
        concatMap((params) => {
          const offerId = params.get('id') || '';
          return this.offerService.getOffer(offerId);
        }),
        concatMap(({ data, loading }) => {
          this.offerService.isEditOffer = data.offer;
          return this.addressService.getAddress(this.offerService.isEditOffer.delivery_address_id);
        }),
        concatMap(({ data, loading }) => {
          this.financialService.isEditDeliveryAddress = data.address;
          return this.addressService.getAddress(this.offerService.isEditOffer.invoice_address_id);
        }),
        concatMap(({ data, loading }) => {
          this.financialService.isEditInvoiceAddress = data.address;
          return this.companyService.getCompany(this.offerService.isEditOffer.customer_id);
        }),
      )
      .subscribe(({ data, loading }) => {
        this.financialService.isEditCompany = data.company
          ? data.company
          : {
              name_company: '',
              uuid: '',
              bds_number: '',
              uid: '',
              company_register_number: '',
              delivery_addresses: [],
              invoice_addresses: [],
            };
        console.log('Angebot:', this.offerService.isEditOffer);
        console.log('Rechnungsaddresse:', this.financialService.isEditInvoiceAddress);
        console.log('Lieferaddresse:', this.financialService.isEditDeliveryAddress);
        console.log('Kunde:', this.financialService.isEditCompany);
        this.setFormValues();
      });
  }

  setFormValues() {
    this.financialService.detailsForm = this.offerService.setDetailsValues();
    const customer = this.financialService.detailsForm.get('customer')?.value;
    this.financialService.filteredInvoiceAddresses = [...customer.invoice_addresses];
    this.financialService.filteredInvoiceAddressesOriginal = customer.invoice_addresses;
    this.financialService.filteredDeliveryAddresses = [...customer.delivery_addresses];
    this.financialService.filteredDeliveryAddressesOriginal = customer.delivery_addresses;
    this.financialService.customerForm = this.financialService.setCustomerValues();
    this.financialService.projectForm = this.offerService.setProjectValues();
    this.financialService.textsForm = this.offerService.setTextsValues();
    this.setLineItemsValues();
    this.calculateService.calcSubtotal();
    this.calculateService.calcTaxes();
  }

  setLineItemsValues() {
    this.financialService.lineItems.controls.splice(0, 1);
    for (let i = 0; i < this.offerService.isEditOffer.offer_line_items.length; i++) {
      const item = this.offerService.isEditOffer.offer_line_items[i];
      const lineItemGroup = this.offerService.fb.group({
        _id: [item._id],
        title: [item.title, Validators.required],
        description: [item.description],
        quantity: [item.quantity, Validators.required],
        unitPrice: [item.unitPrice, Validators.required],
        taxPerc: [item.taxPerc, Validators.required],
        singleSum: [{ value: this.setSingleSum(item), disabled: true }, Validators.required],
      });
      this.financialService.lineItems.push(lineItemGroup);
    }
  }

  setSingleSum(item: any) {
    if (this.financialService.detailsForm.get('inputLocale')?.value == 'de-DE') {
      return this.decimalPipe.transform(item.quantity * item.unitPrice, '1.2-2', 'de-DE');
    } else {
      return this.decimalPipe.transform(item.quantity * item.unitPrice, '1.2-2', 'en-US');
    }
  }

  async submit() {
    if (
      this.financialService.detailsForm.valid &&
      this.financialService.customerForm.valid &&
      this.financialService.lineItemsForm.valid
    ) {
      if (this.financialService.isEdit) {
        this.offerService.updateOffer(this.offerService.isEditOffer._id).subscribe(({ data, loading }) => {
          this._snackBar.openFromComponent(SnackbarComponent, { data: { button: 'OK', message: 'Angebot aktualisiert' } });
          this.financialService.invalidForm = false;
        });
      } else {
        this.offerService.saveOffer().subscribe(({ data, loading }) => {
          this._snackBar.openFromComponent(SnackbarComponent, { data: { button: 'OK', message: 'Angebot erstellt' } });
          this.offerService.resetForms();
          this.financialService.invalidForm = false;
        });
      }
    } else {
      this._snackBar.openFromComponent(SnackbarComponent, { data: { button: 'OK', message: 'Angebot unvollständig' } });
      this.financialService.detailsForm.markAllAsTouched();
      this.financialService.customerForm.markAllAsTouched();
      this.financialService.lineItemsForm.markAllAsTouched();
      this.financialService.invalidForm = true;
    }
  }
}
