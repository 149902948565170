import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { CompanyService } from 'src/app/services/crm/company.service';
import { InvoiceService } from 'src/app/services/finance/invoice.service';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { Company } from 'src/app/interfaces/company';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import * as xml2js from 'xml2js';
import { SnackbarComponent } from 'src/app/snackbar/snackbar.component';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { DialogComponent } from '../../dialog/dialog.component';

@Component({
  selector: 'financehub-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    RouterLink,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    NgIf,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    NgClass,
    MatPaginatorModule,
    MatSnackBarModule,
    MatTabsModule,
    DialogModule,
  ],
})
export class CompaniesComponent implements OnInit, OnDestroy {
  uuidToDelete = '';
  valueFilter = '';
  showOverlay = false;
  companies: any[] = [];
  displayedColumns: string[] = ['bds_number', 'name_company', 'icons'];
  dataSource = new MatTableDataSource<Company>(this.companies);

  dummyCompanies: string[] = [];
  dummyCompaniesUpdated = '';

  private querySubscription!: Subscription;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    public invoiceService: InvoiceService,
    private companyService: CompanyService,
    private router: Router,
    private _snackBar: MatSnackBar,
    public dialog: Dialog,
  ) {}

  ngOnInit(): void {
    //this.invoiceService.companies.sort((a, b) => a.bdsNumber - b.bdsNumber);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.fetchCompanies();
      }
    });
    this.fetchCompanies();

    this.querySubscription = this.companyService.getCompanies().subscribe(({ data, loading }) => {
      this.companies = data.companies;
      this.dataSource = new MatTableDataSource<Company>(this.companies);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.sortTable();
    });
    // get dummy companies from RSS Feed
    this.companyService.getDummyCompanies().subscribe((data) => {
      xml2js.parseString(data, (err, result) => {
        const rssCompanies = result.rss.channel[0];
        this.dummyCompaniesUpdated = rssCompanies.lastBuildDate[0];
        this.dummyCompanies = rssCompanies.item.map((company: any) => {
          return company.title[0];
        });
      });
    });
  }

  openDeleteDialog(company: any): void {
    console.log(company);
    const dialogRef = this.dialog.open<string>(DialogComponent, {
      width: '500px',
      data: {
        headline: 'Aktion bestätigen',
        text: `Wollen Sie die Firma ${company.name_company} wirklich löschen?`,
        element: company,
      },
    });

    dialogRef.closed.subscribe((company) => {
      if (company) this.delete(company);
    });
  }

  fetchCompanies() {
    this.querySubscription = this.companyService.getCompanies().subscribe(({ data, loading }) => {
      this.companies = data.companies;
      this.dataSource = new MatTableDataSource<Company>(this.companies);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  sortTable() {
    const sortState: Sort = { active: 'name_company', direction: 'asc' };
    if (this.dataSource.sort) {
      this.dataSource.sort.active = sortState.active;
      this.dataSource.sort.direction = sortState.direction;
      this.dataSource.sort.sortChange.emit(sortState);
    }
  }

  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  deleteFilter() {
    this.valueFilter = '';
    this.dataSource.filter = '';
  }

  overlayDelete(uuid: string) {
    this.showOverlay = true;
    this.uuidToDelete = uuid;
  }

  deleteCompany(uuid: string) {
    /*  let index = this.invoiceService.companies.findIndex(a => a.uuid == uuid);
    this.invoiceService.companies.splice(index, 1);
    this.showOverlay = false;
    this.update(); */
    this.delete(uuid);
  }

  deleteCompanyAdmin(uuid: string) {
    /* let indexCompany = this.invoiceService.companies.findIndex(a => a.uuid == uuid);
    this.invoiceService.companies.splice(indexCompany, 1);
    const filteredAddresses = this.invoiceService.addresses.map(a => {
      a.companies = a.companies.filter(uuid => uuid !== uuid);
      return a;
    });
    const filteredPersons = this.invoiceService.persons.map(p => {
      p.companies = p.companies.filter(uuid => uuid !== uuid);
      return p;
    });
    this.showOverlay = false;
    this.update(); */
    this.delete(uuid);
  }

  delete(companyToDelete: any) {
    this.companyService.deleteCompany(companyToDelete.uuid).subscribe(({ data, loading }) => {
      this.companies = this.companies.filter((company: Company) => {
        return company.uuid !== companyToDelete.uuid;
      });
      this.update();
      this.showOverlay = false;
      this._snackBar.openFromComponent(SnackbarComponent, { data: { button: 'OK', message: 'Firma gelöscht' } });
      this.uuidToDelete = '';
    });
  }

  update() {
    this.dataSource = new MatTableDataSource<Company>(this.companies);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}
