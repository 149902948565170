import { Component, OnInit, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIf, NgClass, NgFor, DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { InputFieldTemplateComponent } from '../../../finance/input-field-template/input-field-template.component';
import { CompanyService } from 'src/app/services/crm/company.service';
import { InvoiceService } from 'src/app/services/finance/invoice.service';
import { AddressesService } from 'src/app/services/crm/addresses.service';
import { PersonsService } from 'src/app/services/crm/persons.service';
import { Address, AddressType } from 'src/app/interfaces/address';
import { Person } from 'src/app/interfaces/person';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { OrbDemoComponent } from 'src/app/orb-demo/orb-demo.component';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/snackbar/snackbar.component';
import { MatInputModule } from '@angular/material/input';
import { DialogComponent } from '../../../dialog/dialog.component';
import { Dialog, DialogModule } from '@angular/cdk/dialog';

@Component({
  selector: 'financehub-show-company',
  templateUrl: './show-company.component.html',
  styleUrls: ['./show-company.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    MatExpansionModule,
    NgIf,
    MatToolbarModule,
    NgClass,
    FormsModule,
    MatDialogModule,
    MatTableModule,
    MatSortModule,
    MatFormFieldModule,
    MatSelectModule,
    NgFor,
    MatCheckboxModule,
    DatePipe,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatTabsModule,
    InputFieldTemplateComponent,
    ReactiveFormsModule,
    OrbDemoComponent,
    MatSnackBarModule,
    MatInputModule,
    DialogModule,
  ],
})
export class ShowCompanyComponent implements OnInit, OnDestroy {
  constructor(
    public invoiceService: InvoiceService,
    private companyService: CompanyService,
    private addressesService: AddressesService,
    private personsService: PersonsService,
    public route: ActivatedRoute,
    private datePipe: DatePipe,
    public dialog: Dialog,
    private _snackBar: MatSnackBar,
  ) {
    this.companyForm = new FormGroup({
      name_company: new FormControl('', [Validators.required]),
      uuid: new FormControl('', [Validators.required]),
      bds_number: new FormControl(100000, [Validators.required]),
      company_register_number: new FormControl(''),
      iban: new FormControl(''),
      bic: new FormControl(''),
      bank_name: new FormControl(''),
      url: new FormControl(''),
      info: new FormControl(''),
      uid: new FormControl(''),
    });
  }
  companyForm!: FormGroup;

  uuid!: string;
  company!: any;

  displayedColumnsAddresses: string[] = [
    'bds_number',
    'name',
    'country',
    'zip',
    'city',
    'street',
    'house_number',
    'invoice',
    'delivery',
    'actions',
  ];
  displayedColumnsPersons: string[] = [
    'bds_number',
    'gender',
    'title_academic',
    'first_name',
    'last_name',
    'position',
    'birthday',
    'phone_office',
    'mail_office',
    'info',
    'actions',
  ];

  dataSourceAddresses!: MatTableDataSource<Address>;
  editAddressesActive = false;
  availableAddresses: Address[] = [];
  addressesOfCompany: AddressType[] = [];
  addAddressOverlay = false;
  selectedAddress = '';
  deleteAddressOverlay = false;
  addressToDelete!: AddressType;
  valueFilterAdresses = '';

  dataSourcePersons!: MatTableDataSource<Person>;
  editPersonsActive = false;
  availablePersons: Person[] = [];
  personsOfCompany: Person[] = [];
  addPersonOverlay = false;
  selectedPerson = '';
  deletePersonOverlay = false;
  personToDelete!: Person;
  valueFilterPersons = '';

  editCompanyActive = false;

  checkboxes = {
    delivery: false,
    invoice: false,
  };

  numberExistsCompany = null;
  numberNotValidCompany = false;

  numberExistsAddressStates: boolean[] = [];
  numberNotValidAddressStates: boolean[] = [];
  numberExistsAddress = null;
  numberNotValidAddress = false;

  numberExistsPerson = null;
  numberNotValidPerson = false;
  numberExistsPersonStates: boolean[] = [];
  numberNotValidPersonStates: boolean[] = [];

  companyPersons: Person[] = [];

  private querySubscription!: Subscription;

  dialogCall!: string;
  @ViewChild('addresses', { read: MatSort, static: true }) sortAddresses!: MatSort;
  @ViewChild('persons', { read: MatSort, static: true }) sortPersons!: MatSort;
  @ViewChild('matTabGroup') matTabGroup!: MatTabGroup;

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.uuid = params.get('uuid') || '';
      this.querySubscription = this.companyService.getCompany(this.uuid).subscribe(({ data, loading }) => {
        this.company = { ...data.company };
        this.createCompanyForm();
        this.addressesOfCompany = this.company.delivery_addresses
          .map((dA: Address) => {
            return { type: 'delivery', ...dA };
          })
          .concat(
            this.company.invoice_addresses.map((iA: Address) => {
              return { type: 'invoice', ...iA };
            }),
          );
        console.log('aa', this.addressesOfCompany);
        this.personsOfCompany = [...this.company.persons];
        this.getAddresses();
        this.getPersons();
      });
    });
  }

  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }

  createCompanyForm() {
    this.companyForm = new FormGroup({
      name_company: new FormControl({ value: this.company.name_company, disabled: true }, [Validators.required]),
      uuid: new FormControl({ value: this.company.uuid, disabled: true }, [Validators.required]),
      bds_number: new FormControl({ value: this.company.bds_number, disabled: true }, [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
      ]),
      company_register_number: new FormControl({ value: this.company.company_register_number, disabled: true }),
      iban: new FormControl({ value: this.company.iban, disabled: true }),
      bic: new FormControl({ value: this.company.bic, disabled: true }),
      bank_name: new FormControl({ value: this.company.bank_name, disabled: true }),
      url: new FormControl({ value: this.company.url, disabled: false }),
      info: new FormControl({ value: this.company.info, disabled: true }),
      uid: new FormControl({ value: this.company.uid, disabled: true }),
    });
  }

  saveCheckboxChanges(element: any, event: any) {}

  navigateToURL() {
    if (!this.editCompanyActive) {
      const urlValue = this.companyForm.get('url')?.value;
      if (urlValue && typeof urlValue === 'string') {
        const absoluteURL = urlValue.includes('://') ? urlValue : `http://${urlValue}`;
        window.open(absoluteURL, '_blank');
      }
    }
  }

  editCompany() {
    this.editCompanyActive = true;
    this.enableFields();
  }

  updateCompany() {
    this.company.name_company = this.companyForm.get('name_company')?.value;
    this.company.bds_number = parseInt(this.companyForm.get('bds_number')?.value);
    this.company.uid = this.companyForm.get('uid')?.value;
    this.company.company_register_number = this.companyForm.get('company_register_number')?.value;
    this.company.bank_name = this.companyForm.get('bank_name')?.value;
    this.company.iban = this.companyForm.get('iban')?.value;
    this.company.bic = this.companyForm.get('bic')?.value;
    this.company.url = this.companyForm.get('url')?.value;
    this.company.info = this.companyForm.get('info')?.value;
    this.companyService.updateCompany(this.uuid, this.company).subscribe(({ data, loading }) => {});
    this.editCompanyActive = false;
    this._snackBar.openFromComponent(SnackbarComponent, { data: { button: 'OK', message: 'Firma aktualisiert' } });
    this.disableFields();
  }

  enableFields() {
    this.companyForm.get('name_company')?.enable();
    this.companyForm.get('bds_number')?.enable();
    this.companyForm.get('company_register_number')?.enable();
    this.companyForm.get('iban')?.enable();
    this.companyForm.get('bic')?.enable();
    this.companyForm.get('bank_name')?.enable();
    this.companyForm.get('info')?.enable();
    this.companyForm.get('uid')?.enable();
  }

  disableFields() {
    this.companyForm.get('name_company')?.disable();
    this.companyForm.get('bds_number')?.disable();
    this.companyForm.get('company_register_number')?.disable();
    this.companyForm.get('iban')?.disable();
    this.companyForm.get('bic')?.disable();
    this.companyForm.get('bank_name')?.disable();
    this.companyForm.get('info')?.disable();
    this.companyForm.get('uid')?.disable();
  }

  getAddresses() {
    this.addressesService.getAddresses().subscribe(({ data, loading }) => {
      this.addressesService.availableAddresses = data.addresses.filter((addr: Address) => {
        return !this.addressIsInUse(addr.uuid, 'invoice') || !this.addressIsInUse(addr.uuid, 'delivery');
      });
      this.dataSourceAddresses = new MatTableDataSource<Address>(this.addressesOfCompany);
      this.dataSourceAddresses.sort = this.sortAddresses;
    });
  }

  getAddressesLabel() {
    const addressesCount = this.dataSourceAddresses?.data?.length || 0;
    return `2 - Adressen (${addressesCount})`;
  }

  openAddAddressDialog(): void {
    this.addressesService.dialogCall = 'addAddressToCompany';
    const dialogRef = this.dialog.open<string>(DialogComponent, {
      width: '500px',
      data: {
        headline: 'Adresse auswählen',
        text: null,
        element: 'element',
      },
    });
    dialogRef.closed.subscribe((el) => {
      if (el) {
        this.addAddress();
      }
      this.addressesService.dialogCall = '';
    });
  }

  addAddress() {
    this.companyService
      .saveCompanyAddress(this.uuid, {
        type_delivery: this.addressesService.addressToCompanyForm.get('delivery')?.value,
        type_invoice: this.addressesService.addressToCompanyForm.get('invoice')?.value,
        address_uuid: this.addressesService.addressToCompanyForm.get('selectedAddress')?.value,
      })
      .subscribe(({ data, loading }) => {
        const newAddr = this.addressesService.availableAddresses.find((addr: Address) => {
          return addr.uuid === this.addressesService.addressToCompanyForm.get('selectedAddress')?.value;
        });
        if (newAddr) {
          if (this.checkboxes.delivery) this.addressesOfCompany.push({ type: 'delivery', ...newAddr });
          if (this.checkboxes.invoice) this.addressesOfCompany.push({ type: 'invoice', ...newAddr });

          if (this.addressIsInUse(this.selectedAddress, 'delivery') && this.addressIsInUse(this.selectedAddress, 'invoice'))
            this.addressesService.availableAddresses = this.addressesService.availableAddresses.filter(
              (addr: Address) => addr.uuid !== this.selectedAddress,
            );

          this.dataSourceAddresses = new MatTableDataSource<Address>(this.addressesOfCompany);
        }
        this.addAddressOverlay = false;
        this.selectedAddress = '';
        this.checkboxes.delivery = false;
        this.checkboxes.invoice = false;
        this._snackBar.openFromComponent(SnackbarComponent, { data: { button: 'OK', message: 'Adresse hinzugefügt' } });
      });
  }

  editAddresses() {
    this.editAddressesActive = true;
  }

  updateAddresses() {
    this.editAddressesActive = false;
  }

  openDeleteAddressDialog(el: any): void {
    const dialogRef = this.dialog.open<string>(DialogComponent, {
      width: '500px',
      data: {
        headline: 'Aktion bestätigen',
        text: `Wollen Sie die Addresse ${el.name} wirklich löschen?`,
        element: el,
      },
    });

    dialogRef.closed.subscribe((el) => {
      if (el) this.deleteAddress(el);
    });
  }

  deleteAddress(address: any) {
    this.addressToDelete = address;
    this.companyService
      .deleteCompanyAddress(this.uuid, { type: this.addressToDelete.type, address_uuid: this.addressToDelete.uuid })
      .subscribe(() => {
        const deleteIndex = this.addressesOfCompany.findIndex((addr: AddressType) => {
          return addr.uuid === this.addressToDelete.uuid && addr.type === this.addressToDelete.type;
        });
        // Add address back to available array if it was used as delivery and invoice address
        if (
          this.addressIsInUse(this.addressToDelete.uuid, 'delivery') &&
          this.addressIsInUse(this.addressToDelete.uuid, 'invoice')
        )
          this.availableAddresses.push(this.addressesOfCompany[deleteIndex]);
        this.addressesOfCompany.splice(deleteIndex, 1);
        this.dataSourceAddresses = new MatTableDataSource<Address>(this.addressesOfCompany);
        this.deleteAddressOverlay = false;
        this._snackBar.openFromComponent(SnackbarComponent, { data: { button: 'OK', message: 'Adresse entfernt' } });
      });
  }

  addressIsInUse(addrUuid: string, type: string) {
    return (
      this.addressesOfCompany.findIndex((addr: AddressType) => {
        return addr.uuid === addrUuid && addr.type === type;
      }) !== -1
    );
  }
  openAddPersonDialog(): void {
    this.personsService.dialogCall = 'addPersonToCompany';
    const dialogRef = this.dialog.open<string>(DialogComponent, {
      width: '500px',
      data: {
        headline: 'Person auswählen',
        text: null,
        element: 'element',
      },
    });
    dialogRef.closed.subscribe((el) => {
      if (el) {
        this.addPerson();
      }
      this.personsService.dialogCall = '';
    });
  }

  getPersons() {
    this.personsService.getPersons().subscribe(({ data, loading }) => {
      this.personsService.availablePersons = data.persons.filter((person: Person) => {
        return (
          this.company.persons.findIndex((p: Person) => {
            return p.uuid === person.uuid;
          }) === -1
        );
      });
      this.dataSourcePersons = new MatTableDataSource<Person>(this.personsOfCompany);
      this.dataSourcePersons.sort = this.sortPersons;
      const updatedData = this.dataSourcePersons.data.map((item) => ({
        ...item,
        birthday_formatted: item.birthday ? this.datePipe.transform(new Date(item.birthday)) : null,
      }));
      this.dataSourcePersons.data = updatedData;
    });
  }

  getPersonsLabel() {
    const personsCount = this.dataSourcePersons?.data?.length || 0;
    return `3 - Personen (${personsCount})`;
  }

  addPerson() {
    this.companyService
      .saveCompanyPerson(this.uuid, this.personsService.personToCompanyForm.get('selectedPerson')?.value)
      .subscribe(({ data, loading }) => {
        const newPerson = this.personsService.availablePersons.find((person: Person) => {
          return person.uuid === this.personsService.personToCompanyForm.get('selectedPerson')?.value;
        });
        if (newPerson) {
          this.personsService.availablePersons = this.personsService.availablePersons.filter(
            (person: Person) => person.uuid !== this.selectedPerson,
          );
          this.personsOfCompany.push(newPerson);
          this.dataSourcePersons = new MatTableDataSource<Person>(this.personsOfCompany);
          this.selectedPerson = '';
          this.addPersonOverlay = false;
          this._snackBar.openFromComponent(SnackbarComponent, { data: { button: 'OK', message: 'Person hinzugefügt' } });
        }
      });
  }

  editPersons() {
    this.editPersonsActive = !this.editPersonsActive;
  }

  openDeletePersonDialog(el: any): void {
    const dialogRef = this.dialog.open<string>(DialogComponent, {
      width: '500px',
      data: {
        headline: 'Aktion bestätigen',
        text: `Wollen Sie die Person ${el.first_name} ${el.last_name} wirklich löschen?`,
        element: el,
      },
    });
    dialogRef.closed.subscribe((el) => {
      if (el) this.deletePerson(el);
    });
  }

  getDeletePersonOverlay(person: Person) {
    this.deletePersonOverlay = true;
    this.personToDelete = person;
  }

  deletePerson(personToDelete: any) {
    this.companyService.deleteCompanyPerson(this.uuid, personToDelete.uuid).subscribe(() => {
      const deleteIndex = this.personsOfCompany.findIndex((person: Person) => {
        return person.uuid === personToDelete.uuid;
      });
      this.personsService.availablePersons.push(this.personsOfCompany[deleteIndex]);
      this.personsOfCompany.splice(deleteIndex, 1);
      this.dataSourcePersons = new MatTableDataSource<Person>(this.companyPersons);
      this.deletePersonOverlay = false;
      this._snackBar.openFromComponent(SnackbarComponent, { data: { button: 'OK', message: 'Person entfernt' } });
    });
  }

  applyFilter(event: KeyboardEvent, dataSource: MatTableDataSource<any>) {
    if (event.key == 'Escape') {
      (event.target as HTMLInputElement).value = '';
      dataSource.filter = '';
    } else {
      const filterValue = (event.target as HTMLInputElement).value;
      dataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  deleteValue(value: string, dataSource: MatTableDataSource<any>) {
    dataSource.filter = '';
    switch (value) {
      case 'addresses':
        this.valueFilterAdresses = '';
        break;
      case 'persons':
        this.valueFilterPersons = '';
        break;
    }
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const isInputFocused = document.activeElement instanceof HTMLInputElement;
    if (!isInputFocused) {
      switch (event.key) {
        case '1':
          this.matTabGroup.selectedIndex = 0;
          break;
        case '2':
          this.matTabGroup.selectedIndex = 1;
          break;
        case '3':
          this.matTabGroup.selectedIndex = 2;
          break;
        default:
          break;
      }
    }
  }
}
