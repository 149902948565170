import { NgFor, NgIf } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteModule, MatAutocomplete } from '@angular/material/autocomplete';
import { CalculateService } from 'src/app/services/finance/calculate.service';
import { CompanyService } from 'src/app/services/crm/company.service';
import { InvoiceService } from 'src/app/services/finance/invoice.service';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { Company } from 'src/app/interfaces/company';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { InputFieldTemplateComponent } from '../../input-field-template/input-field-template.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FinancialService } from 'src/app/services/finance/financial.service';

@Component({
  selector: 'financehub-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    MatExpansionModule,
    MatAutocompleteModule,
    NgFor,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    NgIf,
    MatFormFieldModule,
    MatInputModule,
    InputFieldTemplateComponent,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
})
export class InvoiceDetailsComponent implements OnInit {
  constructor(
    public invoiceService: InvoiceService,
    public calculateService: CalculateService,
    private companyService: CompanyService,
    public financialService: FinancialService,
  ) {
    this.financialService.detailsForm = this.invoiceService.setDetailsForm();
  }

  @ViewChild('customerAuto', { static: true }) customerAuto!: MatAutocomplete;

  ngOnInit() {
    this.invoiceService.subscribeDateFields();
    this.companyService.getCompanies().subscribe(({ data, loading }) => {
      this.financialService.filteredCompaniesOriginal = data.companies;
      this.financialService.filteredCompanies = [...data.companies];
      this.setDates();
      this.sortCompanies();
      this.financialService.customerAuto = this.customerAuto;
    });
  }

  setDates() {
    if (!this.financialService.isEdit) {
      this.invoiceService.changeDueDate('14', true);
      this.invoiceService.changeInvoiceDate('tod', true);
    }
  }

  sortCompanies() {
    const sortedArray = this.financialService.filteredCompanies.sort((a, b) => {
      const nameA = a.name_company.toUpperCase();
      const nameB = b.name_company.toUpperCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  onCompanyChange(event: KeyboardEvent) {
    let inputValue = '';
    if (event.key == 'Escape') {
      (event.target as HTMLInputElement).value = '';
      this.financialService.detailsForm.get('customer')?.setValue('');
    }
    inputValue = (event.target as HTMLInputElement).value;
    this.financialService.filteredCompanies = this.financialService.filteredCompaniesOriginal.filter((c) =>
      c.name_company.toLowerCase().includes(inputValue),
    );
    this.sortCompanies();
  }

  onCompanySelected(event: MatAutocompleteSelectedEvent) {
    this.financialService.customerForm.reset();
    const selectedCustomer = event.option.value;
    this.financialService.filteredInvoiceAddresses = [...selectedCustomer.invoice_addresses];
    this.financialService.filteredInvoiceAddressesOriginal = selectedCustomer.invoice_addresses;
    this.financialService.filteredDeliveryAddresses = [...selectedCustomer.delivery_addresses];
    this.financialService.filteredDeliveryAddressesOriginal = selectedCustomer.delivery_addresses;
    this.financialService.customerForm.get('bds_number')?.setValue(selectedCustomer.bds_number);
    this.financialService.customerForm.get('company_register_number')?.setValue(selectedCustomer.company_register_number);
    this.financialService.customerForm.get('uid')?.setValue(selectedCustomer.uid);
    this.financialService.customerForm.get('uuid')?.setValue(selectedCustomer.uuid);
    this.sortInvoiceAddresses();
    this.sortDeliveryAddresses();
    this.setDefaultAddresses();
  }

  setDefaultAddresses() {
    if (this.financialService.filteredInvoiceAddresses.length > 0) {
      const address = this.financialService.filteredInvoiceAddresses[0];
      this.financialService.setInvoiceAddressValues(address);
    }
    if (this.financialService.filteredDeliveryAddresses.length > 0) {
      const address = this.financialService.filteredDeliveryAddresses[0];
      this.financialService.setDeliveryAddressValues(address);
    }
  }

  sortInvoiceAddresses() {
    const sortedArray = this.financialService.filteredInvoiceAddresses.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  sortDeliveryAddresses() {
    const sortedArray = this.financialService.filteredDeliveryAddresses.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  displayCustomerName(customer: Company) {
    return customer ? customer.name_company : '';
  }
}
