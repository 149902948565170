<form class="flex justify-end" [formGroup]="financialService.sumsForm">
  <table>
    <tr>
      <td>Summe netto</td>
      <td class="a-data" *ngIf="financialService.detailsForm?.get('outputLocale')?.value == 'de-DE'">
        {{ financialService.sumsForm.get('subtotal')?.value | number: '1.2-2' : 'de-DE' }}
      </td>
      <td class="a-data" *ngIf="financialService.detailsForm?.get('outputLocale')?.value == 'en-EN'">
        {{ financialService.sumsForm.get('subtotal')?.value | number: '1.2-2' : 'en-EN' }}
      </td>
    </tr>
    <tr *ngFor="let t of financialService.sumsForm.get('taxes')?.value">
      <ng-container *ngIf="t.taxPerc !== null">
        <td>+{{ t.taxPerc | number: '1.0-2' }}% USt.</td>
        <td class="a-data" *ngIf="financialService.detailsForm?.get('outputLocale')?.value == 'de-DE'">
          {{ t.taxEuro | number: '1.2-2' : 'de-DE' }}
        </td>
        <td class="a-data" *ngIf="financialService.detailsForm?.get('outputLocale')?.value == 'en-EN'">
          {{ t.taxEuro | number: '1.2-2' : 'en-EN' }}
        </td>
      </ng-container>
    </tr>
    <tr>
      <td>Summe USt.</td>
      <td class="a-data" *ngIf="financialService.detailsForm?.get('outputLocale')?.value == 'de-DE'">
        {{ financialService.sumsForm.get('totalTaxes')?.value | number: '1.2-2' : 'de-DE' }}
      </td>
      <td class="a-data" *ngIf="financialService.detailsForm?.get('outputLocale')?.value == 'en-EN'">
        {{ financialService.sumsForm.get('totalTaxes')?.value | number: '1.2-2' : 'en-EN' }}
      </td>
    </tr>
    <tr>
      <td>Summe brutto</td>
      <td class="a-data" *ngIf="financialService.detailsForm?.get('outputLocale')?.value == 'de-DE'">
        {{ financialService.sumsForm.get('totalSum')?.value | number: '1.2-2' : 'de-DE' }}
      </td>
      <td class="a-data" *ngIf="financialService.detailsForm?.get('outputLocale')?.value == 'en-EN'">
        {{ financialService.sumsForm.get('totalSum')?.value | number: '1.2-2' : 'en-EN' }}
      </td>
    </tr>
  </table>
</form>
