import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { status } from '../../interfaces/status';

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  constructor(private apollo: Apollo) {}

  invoiceStatuses: status[] = [
    { type: 'draft', title: 'Entwurf' },
    { type: 'ready', title: 'Bereit' },
    { type: 'open', title: 'Offen' },
    { type: 'due', title: 'Überfällig' },
    { type: 'paid', title: 'Bezahlt' },
    { type: 'partially paid', title: 'Teilw. bezahlt' },
    { type: 'cancelled', title: 'Storniert' },
  ];

  offerStatuses: status[] = [
    { type: 'draft', title: 'Entwurf' },
    { type: 'ready', title: 'Ausstehend' },
    { type: 'due', title: 'Überfällig' },
    { type: 'accepted', title: 'Akzeptiert' },
    { type: 'cancelled', title: 'Storniert' },
  ];
}
