import { APP_INITIALIZER, ApplicationConfig, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { provideRouter, withEnabledBlockingInitialNavigation, withRouterConfig } from '@angular/router';
import { appRoutes } from './app.routes';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { GraphQLModule } from './graphql.module';
import { KeycloakAngularModule, KeycloakBearerInterceptor, KeycloakService } from 'keycloak-angular';
import { lastValueFrom, switchMap } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { ConfigInitService } from './services/config-init-service';
import { GLOBAL_RX_STATE, GlobalState } from './global-state';
import { RxState } from '@rx-angular/state';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { MockedKeycloakService } from './services/mocked-keycloak.service';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from './services/in-memory-data.service';
import { DatePipe } from '@angular/common';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';

function initializeKeycloak(keycloak: KeycloakService, configService: ConfigInitService) {
  return () =>
    lastValueFrom(
      configService.getConfig().pipe(
        switchMap<any, any>((config) => {
          return fromPromise(
            keycloak.init({
              config: {
                url: config['KEYCLOAK_URL'],
                realm: config['KEYCLOAK_REALM'],
                clientId: config['KEYCLOAK_CLIENT_ID'],
              },
              initOptions: {
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
              },
            }),
          );
        }),
      ),
    );
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes, withRouterConfig({ onSameUrlNavigation: 'reload' })),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(GraphQLModule),
    // importProvidersFrom(KeycloakAngularModule),
    {
      provide: KeycloakService,
      useClass: environment.production ? KeycloakService : MockedKeycloakService,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, ConfigInitService],
    },
    {
      provide: GLOBAL_RX_STATE,
      useFactory: () => new RxState<GlobalState>(),
    },
    // on development load data from mock service
    environment.production
      ? []
      : importProvidersFrom(
          InMemoryWebApiModule.forRoot(InMemoryDataService, { dataEncapsulation: false, passThruUnknownUrl: true }),
        ),
    importProvidersFrom([BrowserAnimationsModule]),
    DatePipe,
    { provide: LOCALE_ID, useValue: 'de-DE' },
    provideAnimations(),
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
  ],
};
