<mat-tab-group class="flex w-full" #matTabGroup>
  <mat-tab label="1- Details">
    <div class="my-5 flex w-full justify-end px-5 lg:w-4/5 lg:px-0">
      <button *ngIf="!editCompanyActive" (click)="editCompany(); $event.stopPropagation()" mat-raised-button color="primary">
        <mat-icon> edit </mat-icon> bearbeiten
      </button>
      <button *ngIf="editCompanyActive" (click)="updateCompany()" mat-raised-button color="primary">
        <mat-icon> done </mat-icon> speichern
      </button>
    </div>

    <div class="flex w-full justify-center">
      <form
        class="flex grid w-full grid-cols-1 flex-col items-start px-5 lg:w-4/5 lg:grid-cols-2 lg:gap-x-5 lg:px-0"
        [formGroup]="companyForm"
        (submit)="updateCompany(); $event.stopPropagation()"
      >
        <financehub-input-field-template [formGroup]="companyForm" controlName="name_company" label="Name">
        </financehub-input-field-template>

        <financehub-input-field-template [readonly]="true" [formGroup]="companyForm" controlName="uuid" label="UUID">
        </financehub-input-field-template>

        <financehub-input-field-template [formGroup]="companyForm" controlName="bds_number" label="BDS-Nummer">
        </financehub-input-field-template>

        <financehub-input-field-template [formGroup]="companyForm" controlName="uid" label="UID">
        </financehub-input-field-template>

        <financehub-input-field-template [formGroup]="companyForm" controlName="company_register_number" label="FBNR">
        </financehub-input-field-template>

        <financehub-input-field-template [formGroup]="companyForm" controlName="bank_name" label="Bankname">
        </financehub-input-field-template>

        <financehub-input-field-template [formGroup]="companyForm" controlName="iban" label="IBAN">
        </financehub-input-field-template>

        <financehub-input-field-template [formGroup]="companyForm" controlName="bic" label="BIC">
        </financehub-input-field-template>
        <div [ngClass]="{ 'disabled-style': !editCompanyActive }">
          <mat-form-field class="w-full" appearance="outline">
            <mat-label>URL</mat-label>
            <input
              class="z-10"
              [ngClass]="{ '!cursor-pointer': !editCompanyActive }"
              [readonly]="!editCompanyActive"
              (click)="navigateToURL()"
              formControlName="url"
              matInput
            />
            <mat-error class="text-end" *ngIf="companyForm.get('url')?.errors?.['required']">Pflichtfeld</mat-error>
            <mat-error class="text-end" *ngIf="companyForm.get('url')?.errors?.['email']">URL ungültig</mat-error>
            <mat-error class="text-end" *ngIf="companyForm.get('url')?.errors?.['pattern']">nur Zahlen möglich</mat-error>
          </mat-form-field>
        </div>
        <financehub-input-field-template class="mb-20" [formGroup]="companyForm" type="textarea" controlName="info" label="Info">
        </financehub-input-field-template>
      </form>
    </div>
  </mat-tab>

  <mat-tab [label]="getAddressesLabel()">
    <!-- TODO: TBD no editing of addresses in this view -->
    <div class="w-full lg:w-4/5">
      <div class="my-5 flex w-full justify-between px-5 lg:px-0">
        <mat-form-field class="company-filter w-2/5 lg:w-2/5" appearance="outline">
          <mat-label>Filter</mat-label>
          <input #input0 [(ngModel)]="valueFilterAdresses" (keyup)="applyFilter($event, dataSourceAddresses)" matInput />
          <mat-icon
            class="cursor-pointer !p-1"
            *ngIf="valueFilterAdresses"
            (click)="deleteValue('addresses', dataSourceAddresses)"
            matSuffix
            >close</mat-icon
          >
        </mat-form-field>
        <!-- <div class="hidden sm:flex"> -->
          <button class="!hidden sm:!flex w-2/5" (click)="openAddAddressDialog(); $event.stopPropagation()" mat-raised-button color="primary">
            <mat-icon> add </mat-icon>Adresse
          </button>
          <!-- <button *ngIf="!editAddressesActive" (click)="editAddresses()" mat-raised-button color="primary">
            <mat-icon> edit </mat-icon>bearbeiten
          </button>
          <button *ngIf="editAddressesActive" (click)="updateAddresses()" mat-raised-button color="primary">
            <mat-icon> check </mat-icon>speichern
          </button>
          -->
        <!-- </div> -->
      </div>

      <div class="mat-elevation-z8 mx-5 mb-5 overflow-x-auto lg:mx-0">
        <table #addresses [dataSource]="dataSourceAddresses" mat-table matSort>
          <ng-container matColumnDef="bds_number">
            <th class="!pl-5 !pr-2" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by number">
              bds-Nr.
            </th>
            <td class="!pl-5 !pr-2" *matCellDef="let element; let even = even; let i = index" mat-cell>
              {{ element.bds_number }}
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th class="!px-2" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by name">Adresse</th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              {{ element.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="country">
            <th class="!px-2" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by country">Land</th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              {{ element.country }}
            </td>
          </ng-container>

          <ng-container matColumnDef="zip">
            <th class="!px-2" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by zip">PLZ</th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              {{ element.zip }}
            </td>
          </ng-container>

          <ng-container matColumnDef="city">
            <th class="!px-2" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by city">Stadt</th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              {{ element.city }}
            </td>
          </ng-container>

          <ng-container matColumnDef="street">
            <th class="!px-2" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by street">Straße</th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              {{ element.street }}
            </td>
          </ng-container>

          <ng-container matColumnDef="house_number">
            <th class="!px-2" *matHeaderCellDef mat-header-cell>Nr.</th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              {{ element.house_number }}
            </td>
          </ng-container>

          <ng-container matColumnDef="delivery">
            <th class="!px-2" *matHeaderCellDef mat-header-cell><mat-icon>local_shipping</mat-icon></th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              <mat-icon *ngIf="element.type === 'delivery'">done</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="invoice">
            <th class="!pl-2 !pr-5" *matHeaderCellDef mat-header-cell><mat-icon>euro</mat-icon></th>
            <td class="!pl-2 !pr-5" *matCellDef="let element; let even = even" mat-cell>
              <mat-icon *ngIf="element.type === 'invoice'">done</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th class="!pl-2 !pr-5" *matHeaderCellDef mat-header-cell></th>
            <td class="!pl-2 !pr-5" *matCellDef="let element; let even = even" mat-cell>
              <div class="flex flex-col justify-end xs:flex-row">
                <mat-icon class="mr-3 cursor-pointer" [routerLink]="'/address/' + element.uuid"> edit </mat-icon>
                <mat-icon class="cursor-pointer" (click)="openDeleteAddressDialog(element)">delete</mat-icon>
              </div>
            </td>
          </ng-container>
          <tr *matHeaderRowDef="displayedColumnsAddresses" mat-header-row></tr>
          <tr
            *matRowDef="let row; let even = even; columns: displayedColumnsAddresses"
            [ngClass]="{ '!bg-gray-100': even, '!bg-gray-200': addressToDelete == row && deleteAddressOverlay }"
            mat-row
          ></tr>
        </table>
        <mat-toolbar class="sm:!hidden fixed bottom-4 !bg-white !bg-opacity-0 py-10 lg:col-span-2 lg:!px-0">
          <button class="mr-3" (click)="openAddAddressDialog()" mat-fab color="primary">
            <mat-icon> add </mat-icon>
          </button>
         <!--  <button *ngIf="!editAddressesActive" (click)="editAddresses(); $event.stopPropagation()" mat-mini-fab color="primary">
            <mat-icon> edit </mat-icon>
          </button>
          <button *ngIf="editAddressesActive" (click)="updateAddresses(); $event.stopPropagation()" mat-mini-fab color="primary">
            <mat-icon> check </mat-icon>
          </button> -->
        </mat-toolbar>
      </div>
    </div>
  </mat-tab>

  <mat-tab [label]="getPersonsLabel()">
    <!-- TODO: TBD no editing of persons in this view -->

    <div class="sticky top-0 my-5 flex w-full justify-between px-5 lg:w-4/5 lg:px-0">
      <mat-form-field class="company-filter w-2/5 lg:w-2/5" appearance="outline">
        <mat-label>Filter</mat-label>
        <input #input0 [(ngModel)]="valueFilterPersons" (keyup)="applyFilter($event, dataSourcePersons)" matInput />
        <mat-icon
          class="cursor-pointer !p-1"
          *ngIf="valueFilterPersons"
          (click)="deleteValue('persons', dataSourcePersons)"
          matSuffix
          >close</mat-icon
        >
      </mat-form-field>
      <!-- <div class="hidden sm:flex"> -->
        <button class="!hidden sm:!flex w-2/5" (click)="openAddPersonDialog(); $event.stopPropagation()" mat-raised-button color="primary">
          <mat-icon> add </mat-icon>Person
        </button>
       <!--  <button *ngIf="!editPersonsActive" (click)="editPersons()" mat-raised-button color="primary">
          <mat-icon> edit </mat-icon>bearbeiten
        </button>
        <button *ngIf="editPersonsActive" (click)="updatePersons()" mat-raised-button color="primary">
          <mat-icon> check </mat-icon>speichern
        </button> -->
      <!-- </div> -->
    </div>

    <div class="w-full lg:w-4/5">
      <div class="mat-elevation-z8 mx-5 mb-5 overflow-x-auto lg:mx-0">
        <table #persons [dataSource]="dataSourcePersons" mat-table matSort>
          <ng-container matColumnDef="bds_number">
            <th class="!pl-5 !pr-2" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by number">
              bds-Nr.
            </th>
            <td class="!pl-5 !pr-2" *matCellDef="let element; let even = even; let i = index" mat-cell>
              <span>{{ element.bds_number }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="gender">
            <th class="!px-2" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by gender">Gender</th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              <span>{{ element.gender }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="title_academic">
            <th class="!px-2" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by title">Titel</th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              <span>{{ element.title_academic }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="first_name">
            <th class="!px-2" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by first name">
              Vorname
            </th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              <span>{{ element.first_name }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="last_name">
            <th class="!px-2" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by last name">
              Nachname
            </th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              <span>{{ element.last_name }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="position">
            <th class="!px-2" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by position">
              Position
            </th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              <span>{{ element.position }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="birthday">
            <th class="!px-2" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by birthday">
              Geburtstag
            </th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              <span>{{ element.birthday | date: 'dd.MM.yyyy' }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="phone_office">
            <th class="!px-2" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by phone">
              Handy Firma
            </th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              <span>{{ element.phone_office }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="mail_office">
            <th class="!px-2" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by mail">
              Email Firma
            </th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              <span>{{ element.mail_office }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="info">
            <th class="!px-2" *matHeaderCellDef mat-header-cell>Info</th>
            <td class="!pl-2 !pr-5" *matCellDef="let element; let even = even" mat-cell>
              <span>{{ element.info }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th class="!pl-2 !pr-5" *matHeaderCellDef mat-header-cell></th>
            <td class="!pl-2 !pr-5" *matCellDef="let element; let even = even" mat-cell>
              <div class="flex flex-col justify-end xs:flex-row">
                <mat-icon class="mr-3 cursor-pointer" [routerLink]="'/person/' + element.uuid">edit</mat-icon>
                <mat-icon class="cursor-pointer" (click)="openDeletePersonDialog(element)">delete</mat-icon>
              </div>
            </td>
          </ng-container>

          <tr *matHeaderRowDef="displayedColumnsPersons" mat-header-row></tr>
          <tr
            *matRowDef="let row; let even = even; columns: displayedColumnsPersons"
            [ngClass]="{ '!bg-gray-100': even, '!bg-gray-200': personToDelete == row && deletePersonOverlay }"
            mat-row
          ></tr>
        </table>
        <mat-toolbar class="sm:!hidden fixed bottom-4 !bg-white !bg-opacity-0 py-10 lg:col-span-2 lg:!px-0">
          <button class="mr-3" (click)="openAddPersonDialog()" mat-fab color="primary">
            <mat-icon> add </mat-icon>
          </button>
          <!-- <button *ngIf="!editPersonsActive" mat-mini-fab color="primary"><mat-icon> edit </mat-icon></button>
          <button *ngIf="editPersonsActive" mat-mini-fab color="primary"><mat-icon> check </mat-icon></button> -->
        </mat-toolbar>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>

<financehub-orb-demo [companyUuid]="uuid"></financehub-orb-demo>
