import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InMemoryDataService {

  // have a look at the angular tutorial for further information
  // https://angular.io/tutorial/tour-of-heroes/toh-pt6#simulate-a-data-server
  // this service enables all crud operations locally
  // also see https://github.com/angular/in-memory-web-api/blob/master/README.md
  createDb() {
    const invoices = [
      {id: 12, number: '2023/0001', customer_name: 'Dr. Nice'},
      {id: 13, number: '2023/0001', customer_name: 'Bombasto'},
      {id: 14, number: '2023/0001', customer_name: 'Celeritas'},
      {id: 15, number: '2023/0001', customer_name: 'Magneta'},
      {id: 16, number: '2023/0001', customer_name: 'RubberMan'},
      {id: 17, number: '2023/0001', customer_name: 'Dynama'},
      {id: 18, number: '2023/0001', customer_name: 'Dr. IQ'},
      {id: 19, number: '2023/0001', customer_name: 'Magma'},
      {id: 20, number: '2023/0001', customer_name: 'Tornado'}
    ];
    return {invoices};
  }

}