<div class="flex justify-center">
  <form
    class="mt-5 flex grid w-full grid-cols-1 flex-col items-start lg:w-4/5 lg:grid-cols-2 lg:gap-x-5"
    [formGroup]="addressForm"
    (submit)="create()"
  >
    <financehub-input-field-template class="w-full" [formGroup]="addressForm" controlName="name" label="Name">
    </financehub-input-field-template>

    <financehub-input-field-template class="w-full" [readonly]="true" [formGroup]="addressForm" controlName="uuid" label="UUID">
    </financehub-input-field-template>

    <financehub-input-field-template class="w-full" [formGroup]="addressForm" controlName="bds_number" label="BDS-Nummer">
    </financehub-input-field-template>

    <financehub-input-field-template class="w-full" [formGroup]="addressForm" controlName="country" label="Land">
    </financehub-input-field-template>

    <financehub-input-field-template class="w-full" [formGroup]="addressForm" controlName="zip" label="PLZ">
    </financehub-input-field-template>

    <financehub-input-field-template class="w-full" [formGroup]="addressForm" controlName="city" label="Stadt">
    </financehub-input-field-template>

    <financehub-input-field-template class="w-full" [formGroup]="addressForm" controlName="street" label="Straße">
    </financehub-input-field-template>

    <financehub-input-field-template class="w-full" [formGroup]="addressForm" controlName="house_number" label="Hausnummer">
    </financehub-input-field-template>

    <mat-toolbar class="sticky bottom-0 !bg-white !bg-opacity-0 lg:col-span-2 lg:!px-0">
      <button class="w-full" mat-raised-button color="primary">Adresse anlegen</button>
    </mat-toolbar>
  </form>
</div>
