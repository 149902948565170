import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { Address, AddressType } from 'src/app/interfaces/address';

const GET_ADDRESSES = gql`
  query addresses {
    addresses {
      name
      uuid
      bds_number
      country
      street
      city
      house_number
      zip
    }
  }
`;

const GET_ADDRESS = gql`
  query address($uuid: String!) {
    address(uuid: $uuid) {
      name
      uuid
      bds_number
      country
      street
      city
      house_number
      zip
    }
  }
`;

const CREATE_ADDRESS = gql`
  mutation CreateAddress($createAddressInput: CreateAddressInput) {
    createAddress(createAddressInput: $createAddressInput) {
      _id
    }
  }
`;

const UPDATE_ADDRESS = gql`
  mutation UpdateAddress($uuid: String!, $updateAddressInput: UpdateAddressInput) {
    updateAddress(uuid: $uuid, updateAddressInput: $updateAddressInput) {
      _id
    }
  }
`;

const DELETE_ADDRESS = gql`
  mutation DeleteAddress($uuid: String!) {
    deleteAddress(uuid: $uuid) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AddressesService {
  constructor(private apollo: Apollo) {}

  addressToCompanyForm!: FormGroup;
  dialogCall = '';
  availableAddresses: Address[] = [];
  companyAddresses: AddressType[] = [];

  setAddressToCompanyForm() {
    this.addressToCompanyForm = new FormGroup(
      {
        selectedAddress: new FormControl('', Validators.required),
        delivery: new FormControl({ value: false, disabled: false }),
        invoice: new FormControl({ value: false, disabled: false }),
      },
      { validators: this.addressToCompanyCheckboxesValidation },
    );
  }

  addressToCompanyCheckboxesValidation(control: AbstractControl): { [key: string]: boolean } | null {
    const deliveryValue = control.get('delivery')?.value;
    const invoiceValue = control.get('invoice')?.value;
    if (!deliveryValue && !invoiceValue) {
      return { atLeastOneCheckbox: true };
    }
    return null;
  }

  addressToCompanyDisablingCheckboxes() {
    if (this.addressToCompanyIsInUse(this.addressToCompanyForm.get('selectedAddress')?.value, 'delivery')) {
      this.addressToCompanyForm.get('delivery')?.disable();
    } else {
      this.addressToCompanyForm.get('delivery')?.enable();
    }
    if (this.addressToCompanyIsInUse(this.addressToCompanyForm.get('selectedAddress')?.value, 'invoice')) {
      this.addressToCompanyForm.get('invoice')?.disable();
    } else {
      this.addressToCompanyForm.get('invoice')?.enable();
    }
  }

  addressToCompanyIsInUse(addrUuid: string, type: string) {
    return (
      this.companyAddresses.findIndex((addr: AddressType) => {
        return addr.uuid === addrUuid && addr.type === type;
      }) !== -1
    );
  }

  getAddresses(): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: GET_ADDRESSES,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  getAddress(uuid: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: GET_ADDRESS,
      variables: { uuid },
    }).valueChanges;
  }

  saveAddress(address: Address): Observable<any> {
    const addressInput = {
      name: address.name,
      uuid: address.uuid,
      bds_number: address.bds_number,
      country: address.country,
      street: address.street,
      city: address.city,
      house_number: address.house_number,
      zip: address.zip,
    };
    return this.apollo.mutate({
      mutation: CREATE_ADDRESS,
      variables: { createAddressInput: addressInput },
    });
  }

  updateAddress(uuid: string, address: Address): Observable<any> {
    const addressInput = {
      name: address.name,
      bds_number: address.bds_number,
      country: address.country,
      street: address.street,
      city: address.city,
      house_number: address.house_number,
      zip: address.zip,
    };
    return this.apollo.mutate({
      mutation: UPDATE_ADDRESS,
      variables: { uuid: uuid, updateAddressInput: addressInput },
      refetchQueries: [{ query: GET_ADDRESS, variables: { uuid } }],
    });
  }

  deleteAddress(uuid: string): Observable<any> {
    return this.apollo.mutate({ mutation: DELETE_ADDRESS, variables: { uuid } });
  }
}
