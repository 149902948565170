<div class="flex justify-center">
  <form
    class="flex grid w-full grid-cols-1 flex-col items-start px-5 lg:grid-cols-2 lg:gap-x-5"
    [formGroup]="financialService.textsForm"
  >
    <mat-form-field class="w-full" *ngIf="!financialService.isEdit" appearance="outline">
      <mat-label>Text davor</mat-label>
      <mat-select (selectionChange)="onTextBeforeSelected($event.value)" formControlName="textBeforeTitle">
        <mat-option *ngFor="let t of financialService.textsBefore; let i = index" [value]="t.title">{{ t.title }}</mat-option>
      </mat-select>
      <mat-error class="text-end" *ngIf="financialService.textsForm.get('textBeforeTitle')?.errors?.['required']"
        >Pflichtfeld</mat-error
      >
    </mat-form-field>

    <financehub-input-field-template
      [formGroup]="financialService.textsForm"
      label="Text davor"
      controlName="textBefore"
      type="textarea"
    ></financehub-input-field-template>

    <mat-form-field class="w-full" *ngIf="!financialService.isEdit" appearance="outline">
      <mat-label>Text danach</mat-label>
      <mat-select (selectionChange)="onTextAfterSelected($event.value)" formControlName="textAfterTitle">
        <mat-option *ngFor="let t of financialService.textsAfter; let i = index" [value]="t.title">{{ t.title }}</mat-option>
      </mat-select>
      <mat-error class="text-end" *ngIf="financialService.textsForm.get('textAfterTitle')?.errors?.['required']"
        >Pflichtfeld</mat-error
      >
    </mat-form-field>

    <financehub-input-field-template
      [formGroup]="financialService.textsForm"
      label="Text danach"
      controlName="textAfter"
      type="textarea"
    ></financehub-input-field-template>
  </form>
</div>
