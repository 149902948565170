import { Component, Input, OnInit } from '@angular/core';
import { Orb } from '@memgraph/orb';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import * as neo4j from 'neo4j-driver';
import { MatInputModule } from '@angular/material/input';
import { environment } from 'src/environments/environment';
import { NgIf } from '@angular/common';

@Component({
  selector: 'financehub-memgraph-orb',
  templateUrl: './memgraph-orb.component.html',
  styleUrls: ['./memgraph-orb.component.scss'],
  standalone: true,
  imports: [MatSelectModule, MatInputModule, FormsModule, NgIf],
})
export class MemgraphOrbComponent implements OnInit {
  @Input() companyUuid: string | null = null;
  container!: HTMLElement;

  orb: any;
  selectedGraphTypes: string[] = ['supplyChain', 'customerSegmentation'];
  driver: any;
  cypherQuery = 'MATCH (n)-[e]->(m) RETURN n, e, m;';
  hasError = false;
  memgraphUri = environment.memgraphUri;
  errorMessage = '';

  ngOnInit(): void {
    this.driver = neo4j.driver(this.memgraphUri, neo4j.auth.basic('', ''));
    
    this.runCypherQuery();
    this.container = document.getElementById('graph') as HTMLElement;
    this.orb = new Orb(this.container);

  }

  onGraphTypeChange() {
     //TODO: add logic for selected graph types
     if (this.selectedGraphTypes.includes('customerSegmentation'))
      this.cypherQuery = 'MATCH (n) RETURN n;';

     //TODO: add logic for selected graph types
     if (this.selectedGraphTypes.includes('supplyChain'))
     this.cypherQuery = 'MATCH (n)-[e]->(m) RETURN n, e, m;';
    
    this.runCypherQuery();
  }

  renderGraph(graph: any) {
    // Initialize nodes and edges
    this.orb.data.setDefaultStyle({
      getNodeStyle(node: any) {
        const labels = node.data.labels.join(':');
        const name = node.data.properties?.title ?? node.data.properties?.name ?? labels;
        let nodeSize = 0;
        switch (node.getEdges().length) {
          case 0:
            nodeSize = 2;
            break;
          case 1:
            nodeSize = 3;
            break;
          case 2:
          case 3:
            nodeSize = 4;
            break;
          case 4:
          case 5:
            nodeSize = 5;
            break;
          default:
            nodeSize = 6;
            break;
        }
        const basicStyle = {
          color: '#DD2222',
          colorHover: '#e7644e',
          fontSize: 3,
          label: name,
          size: nodeSize,
        };

        if (node.data.labels[0] === 'Company') {
          return {
            ...basicStyle,
            color: '#22c55e',
            colorHover: '#86efac',
          };
        }

        if (node.data.labels[0] === 'Invoice_Address') {
          return {
            ...basicStyle,
            color: '#3b82f6',
            colorHover: '#93c5fd',
          };
        }

        if (node.data.labels[0] === 'Delivery_Address') {
          return {
            ...basicStyle,
            color: '#8b5cf6',
            colorHover: '#c4b5fd',
          };
        }

        if (node.data.labels[0] === 'Person') {
          return {
            ...basicStyle,
            color: '#f59e0b',
            colorHover: '#fcd34d',
          };
        }

        if (node.data.labels[0] === 'Invoice') {
          return {
            ...basicStyle,
            color: '#f43f5e',
            colorHover: '#fda4af',
          };
        }

        return {
          ...basicStyle,
        };
      },
      getEdgeStyle(edge: any) {
        return {
          color: '#999999',
          colorHover: '#1d1d1d',
          colorSelected: '#1d1d1d',
          fontSize: 3,
          width: 0.3,
          widthHover: 0.9,
          widthSelected: 0.9,
          label: '',
        };
      },
    });
    this.orb.data.setup({ nodes: graph.nodes, edges: graph.edges });
    // Render and recenter the view
    this.orb.view.render(() => {
      this.orb.view.recenter();
    });
  }

  async executeCypherQuery(query: any) {
    const session = this.driver.session();

    try {
      const neo4jResult = await session.run(query);
      return this.parseNeo4jResult(neo4jResult);
    } finally {
      session.close();
    }
  }

  async runCypherQuery() {
    try {
      const mgResult = await this.executeCypherQuery(this.cypherQuery);          
      const graph = this.extractGraphFromMgResult(mgResult);
      if (graph.nodes.length === 0 && graph.edges.length === 0) {
        throw new Error(`Query was successful, but the graph can't be shown because there are no nodes and edges in the response.`);
      }
      this.hasError = false;
      this.renderGraph(graph);
    } catch (error: any) {
      this.errorMessage = error;
      this.hasError = true;
    }
  };

  runQueryFromInputValue(e: KeyboardEvent) {
    if (e.key === 'Enter') this.runCypherQuery();
  }

  extractGraphFromMgResult(mgResult: any) {
    const nodeById: any = {};
    const edgeById: any = {};

    mgResult.records.forEach((record: any) => {
      Object.values(record).forEach((value: any) => {
        if (this._isMemgraphNode(value)) {
          nodeById[value.id] = value;
        }
        if (this._isMemgraphEdge(value)) {
          edgeById[value.id] = value;
        }
        if (this._isMemgraphPath(value)) {
          value.nodes.forEach((node: any) => nodeById[node.id] = node);
          value.relationships.forEach((edge: any) => edgeById[edge.id] = edge);
        }
      });
    });

    return { nodes: Object.values(nodeById), edges: Object.values(edgeById) };
  };

  parseNeo4jResult(result: any) {
    if (!this._isObject(result)) {
      return { records: [] };
    }

    return {
      records: (result.records ?? []).map((r: any) => this.parseNeo4jRecord(r)),
      summary: result.summary,
    };
  }

  parseNeo4jRecord(record: any) {
     if (!this._isObject(record)) {
      return {};
    }

    const newRecord: any = {};
    record.keys.forEach((key: any) => {
      newRecord[key] = this.parseNeo4jField(record.get(key));
    });
    return newRecord;
  }

  parseNeo4jField(field: any) {
    if (field === undefined || field === null) {
      return null;
    }

    if (this._isArray(field)) {
      return field.map((item: any) => this.parseNeo4jField(item));
    }

    if (this._isNeo4jNumber(field)) {
      return field.toNumber();
    }

    if (this._isNeo4jNode(field)) {
      return this._toMemgraphNode(field);
    }

    if (this._isNeo4jEdge(field)) {
      return this._toMemgraphEdge(field);
    }

    if (this._isNeo4jPath(field)) {
      return this._toMemgraphPath(field);
    }

    if (this._isObject(field)) {
      const newObject: any = {};
      Object.keys(field).forEach((key) => {
        // eslint-disable-next-line no-prototype-builtins
        if (field.hasOwnProperty(key)) {
          newObject[key] = this.parseNeo4jField(field[key]);
        }
      });
      return newObject;
    }

    return field;
  }

  _isNumber(value: any) {
    return typeof value === 'number';
  }

  _isString(value: any) {
    return typeof value === 'string';
  }

  _isObject(value: any) {
    return typeof value === 'object' && value !== null;
  }

  _isArray(value: any) {
    return Array.isArray(value);
  }

  _isMemgraphNode(field: any) {
    return this._isObject(field) && this._isNumber(field.id) && field.type === 'node';
  }

  _isMemgraphEdge(field: any) {
    return (
      this._isObject(field) &&
      this._isNumber(field.id) &&
      this._isNumber(field.start) &&
      this._isNumber(field.end) &&
      field.type === 'relationship'
    );
  }

  _isMemgraphPath(field: any) {
    return (
      this._isObject(field) &&
      this._isArray(field.nodes) &&
      field.nodes.every((node: any) => this._isMemgraphNode(node)) &&
      this._isArray(field.relationships) &&
      field.relationships.every((edge: any) => this._isMemgraphEdge(edge)) &&
      field.type === 'path'
    );
  }

  _toMemgraphNode(neo4jNode: any): any {
    return {
      id: this.parseNeo4jField(neo4jNode.identity),
      labels: this.parseNeo4jField(neo4jNode.labels),
      properties: this.parseNeo4jField(neo4jNode.properties),
      type: 'node',
    };
  }

  _toMemgraphEdge(neo4jEdge: any): any {
    return {
      id: this.parseNeo4jField(neo4jEdge.identity),
      start: this.parseNeo4jField(neo4jEdge.start),
      end: this.parseNeo4jField(neo4jEdge.end),
      label: this.parseNeo4jField(neo4jEdge.type),
      properties: this.parseNeo4jField(neo4jEdge.properties),
      type: 'relationship',
    };
  }

  _toMemgraphPath(neo4jPath: any) {
    const nodeById: any = {};
    const edgeById: any = {};

    (neo4jPath.segments ?? []).forEach((segment: any) => {
      if (this._isNeo4jNode(segment.start)) {
        const node = this._toMemgraphNode(segment.start);
        nodeById[node.id] = node;
      }

      if (this._isNeo4jNode(segment.end)) {
        const node = this._toMemgraphNode(segment.end);
        nodeById[node.id] = node;
      }

      if (this._isNeo4jEdge(segment.relationship)) {
        const edge = this._toMemgraphEdge(segment.relationship);
        edgeById[edge.id] = edge;
      }
    });

    return {
      nodes: Object.values(nodeById),
      relationships: Object.values(edgeById),
      type: 'path',
    };
  }

  _isNeo4jNumber(field: any) {
    return this._isObject(field) && Object.keys(field).length === 2 && this._isNumber(field.low) && this._isNumber(field.high);
  }

  _isNeo4jNode(field: any) {
    return (
      this._isObject(field) &&
      this._isNeo4jNumber(field.identity) &&
      this._isArray(field.labels) &&
      field.labels.every((label: any) => this._isString(label)) &&
      this._isObject(field.properties)
    );
  }

  _isNeo4jEdge(field: any) {
    return (
      this._isObject(field) &&
      this._isNeo4jNumber(field.identity) &&
      this._isNeo4jNumber(field.start) &&
      this._isNeo4jNumber(field.end) &&
      this._isString(field.type) &&
      this._isObject(field.properties)
    );
  }

  _isNeo4jPath(field: any) {
    return (
      this._isObject(field) &&
      this._isNeo4jNode(field.start) &&
      this._isNeo4jNode(field.end) &&
      this._isArray(field.segments) &&
      field.segments.every((segment: any) => {
        return (
          this._isObject(segment) &&
          this._isNeo4jNode(segment.start) &&
          this._isNeo4jEdge(segment.relationship) &&
          this._isNeo4jNode(segment.end)
        );
      })
    );
  }
}
