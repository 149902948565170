import { Injectable } from '@angular/core';
import { FinancialService } from './financial.service';
import { Apollo, gql } from 'apollo-angular';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, debounceTime, switchMap } from 'rxjs';
import moment from 'moment';

const GET_INVOICES = gql`
  query invoices {
    invoices {
      _id
      title
      invoice_number
      input_locale
      output_locale
      invoice_date
      due_date
      text_before
      text_after
      subtotal
      total_taxes
      customer_id
      delivery_address_id
      invoice_address_id
    }
  }
`;

const GET_INVOICES_BY_STATUS = gql`
  query invoicesByStatus {
    invoicesByStatus {
      _id
      invoices {
        _id
        title
        invoice_number
        input_locale
        output_locale
        invoice_date
        due_date
        text_before
        text_after
        subtotal
        total_taxes
        customer_id
        delivery_address_id
        invoice_address_id
        customer {
          name_company
        }
      }
    }
  }
`;

const GET_INVOICE = gql`
  query invoice($id: String!) {
    invoice(id: $id) {
      _id
      title
      invoice_number
      input_locale
      output_locale
      invoice_date
      due_date
      text_before
      text_after
      subtotal
      total_taxes
      customer_id
      customer {
        bds_number
        uid
        company_register_number
      }
      project_title
      project_number
      project_manager
      project_co_manager
      delivery_address_id
      invoice_address_id
      invoice_line_items {
        _id
        invoice_id
        title
        description
        quantity
        unitPrice
        taxPerc
      }
    }
  }
`;

const CREATE_INVOICE = gql`
  mutation CreateInvoice($createInvoiceInput: CreateInvoiceInput) {
    createInvoice(createInvoiceInput: $createInvoiceInput) {
      _id
    }
  }
`;

const UPDATE_INVOICE = gql`
  mutation UpdateInvoice($id: String!, $createInvoiceInput: CreateInvoiceInput) {
    updateInvoice(id: $id, createInvoiceInput: $createInvoiceInput) {
      _id
    }
  }
`;

const DELETE_INVOICE = gql`
  mutation DeleteInvoice($id: String!) {
    deleteInvoice(id: $id) {
      _id
    }
  }
`;

const UPDATE_INVOICE_STATUS = gql`
  mutation UpdateInvoiceStatus($id: String!, $status: String!) {
    updateInvoiceStatus(id: $id, status: $status) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  constructor(
    public apollo: Apollo,
    public fb: FormBuilder,
    public financialService: FinancialService,
  ) {}
  isEditInvoice: any;

  setDetailsForm() {
    return new FormGroup({
      customer: new FormControl('', [Validators.required]),
      invoiceDateDays: new FormControl('', [Validators.required]),
      invoiceDate: new FormControl({ value: '', disabled: true }, [Validators.required]),
      dueDateDays: new FormControl('', [Validators.required]),
      dueDate: new FormControl({ value: '', disabled: true }, [Validators.required]),
      inputLocale: new FormControl('de-DE', [Validators.required]),
      outputLocale: new FormControl('de-DE', [Validators.required]),
    });
  }

  setDetailsValues() {
    return new FormGroup({
      customer: new FormControl(this.financialService.isEditCompany, [Validators.required]),
      invoiceDate: new FormControl({ value: new Date(parseInt(this.isEditInvoice.invoice_date)), disabled: false }, [
        Validators.required,
      ]),
      dueDate: new FormControl({ value: new Date(parseInt(this.isEditInvoice.due_date)), disabled: false }, [
        Validators.required,
      ]),
      inputLocale: new FormControl(this.isEditInvoice.input_locale, [Validators.required]),
      outputLocale: new FormControl(this.isEditInvoice.output_locale, [Validators.required]),
    });
  }

  setProjectValues() {
    return new FormGroup({
      search: new FormControl(this.isEditInvoice.title, [Validators.required]),
      title: new FormControl({ value: this.isEditInvoice.title, disabled: true }, [Validators.required]),
      number: new FormControl({ value: this.isEditInvoice.project_number, disabled: true }, [Validators.required]),
      manager: new FormControl({ value: this.isEditInvoice.project_manager, disabled: true }, [Validators.required]),
      coManager: new FormControl({ value: this.isEditInvoice.project_co_manager, disabled: true }, [Validators.required]),
    });
  }

  setTextsValues() {
    return new FormGroup({
      textBeforeTitle: new FormControl(''),
      textBefore: new FormControl(this.isEditInvoice.text_before),
      textAfterTitle: new FormControl(''),
      textAfter: new FormControl(this.isEditInvoice.text_after),
    });
  }

  async changeInvoiceDate(inputValue: string, isInit = false) {
    if (isInit) this.financialService.detailsForm.get('invoiceDateDays')?.setValue('today');
    if (isInit) this.financialService.detailsForm.get('invoiceDateDays')?.setValue('today');
    if (inputValue.startsWith('tod')) {
      this.financialService.detailsForm.get('invoiceDate')?.setValue(moment().format('DD.MM.yyyy'));
    } else if (inputValue.startsWith('tom')) {
      this.financialService.detailsForm.get('invoiceDate')?.setValue(moment().add('1', 'days').format('DD.MM.yyyy'));
    } else {
      this.financialService.detailsForm.get('invoiceDate')?.setValue(moment().add(inputValue, 'days').format('DD.MM.yyyy'));
    }
    this.changeDueDate(this.financialService.detailsForm.get('dueDateDays')?.value);
  }

  async changeDueDate(inputValue: string, isInit = false) {
    const invoiceDate = this.financialService.detailsForm.get('invoiceDate')?.value;
    if (isInit) this.financialService.detailsForm.get('dueDateDays')?.setValue(inputValue);
    const dueDate = moment(invoiceDate, 'DD.MM.yyyy').add(inputValue, 'days');
    this.financialService.detailsForm.get('dueDate')?.setValue(dueDate.format('DD.MM.yyyy'));
  }

  getInvoices(): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: GET_INVOICES,
    }).valueChanges;
  }

  getInvoicesByStatus(): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: GET_INVOICES_BY_STATUS,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  getInvoice(id: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: GET_INVOICE,
      variables: { id },
    }).valueChanges;
  }

  subscribeDateFields() {
    this.financialService.detailsForm
      .get('dueDateDays')
      ?.valueChanges.pipe(
        debounceTime(200),
        switchMap((changedValue) => this.changeDueDate(changedValue)),
      )
      .subscribe();
    this.financialService.detailsForm
      .get('invoiceDateDays')
      ?.valueChanges.pipe(
        debounceTime(200),
        switchMap((changedValue) => this.changeInvoiceDate(changedValue)),
      )
      .subscribe();
  }

  saveInvoice(): Observable<any> {
    const invoiceInput = this.getInvoiceInput();
    return this.apollo.mutate({
      mutation: CREATE_INVOICE,
      variables: { createInvoiceInput: invoiceInput },
    });
  }

  updateInvoice(invoiceId: string): Observable<any> {
    const invoiceInput = this.getInvoiceInput();
    return this.apollo.mutate({
      mutation: UPDATE_INVOICE,
      variables: { id: invoiceId, createInvoiceInput: invoiceInput },
    });
  }

  deleteInvoice(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_INVOICE,
      variables: { id },
      refetchQueries: [{ query: GET_INVOICES_BY_STATUS }],
    });
  }

  resetForms() {
    this.financialService.detailsForm = this.setDetailsForm();
    this.changeDueDate('14', true);
    this.changeInvoiceDate('tod', true);
    this.financialService.resetForms();
    this.subscribeDateFields();
  }

  getInvoiceInput(): any {
    return {
      ...this.financialService.getInputs(),
      invoice_date: moment(this.financialService.detailsForm.get('invoiceDate')?.value, 'DD.MM.yyyy').format('yyyy-MM-DD'),
      due_date: moment(this.financialService.detailsForm.get('dueDate')?.value, 'DD.MM.yyyy').format('yyyy-MM-DD'),
      invoice_line_items: this.financialService.lineItems.value.map((lineItem: any) => {
        return {
          _id: lineItem._id ? lineItem._id : null,
          title: lineItem.title,
          description: lineItem.description,
          quantity: +lineItem.quantity.toString().replace(',', '.'),
          unitPrice: +lineItem.unitPrice.toString().replace(',', '.'),
          taxPerc: +lineItem.taxPerc.toString().replace(',', '.'),
        };
      }),
    };
  }

  updateStatus(id: string, status: string): Observable<any> {
    return this.apollo.mutate({
      mutation: UPDATE_INVOICE_STATUS,
      variables: { id, status },
      refetchQueries: [{ query: GET_INVOICES_BY_STATUS }],
    });
  }
}
