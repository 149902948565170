import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { InvoiceService } from 'src/app/services/finance/invoice.service';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Person } from 'src/app/interfaces/person';
import { Subscription } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { PersonsService } from 'src/app/services/crm/persons.service';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/snackbar/snackbar.component';

@Component({
  selector: 'financehub-persons',
  templateUrl: './persons.component.html',
  styleUrls: ['./persons.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    NgIf,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    NgClass,
    MatPaginatorModule,
    MatIconModule,
    MatSnackBarModule,
  ],
})
export class PersonsComponent implements OnInit, OnDestroy {
  deleteUUID = '';
  value = '';
  valueFilter: string = '';
  showOverlay = false;
  persons = [];
  displayedColumns: string[] = ['bds_number', 'last_name', 'icons'];
  dataSource = new MatTableDataSource<Person>(this.persons);

  private querySubscription!: Subscription;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    public invoiceService: InvoiceService,
    public personsService: PersonsService,
    private router: Router,
    private _snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    //this.invoiceService.persons.sort((a, b) => a.bdsNumber - b.bdsNumber);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.fetchPersons();
      }
    });
    this.fetchPersons();
  }

  fetchPersons() {
    this.querySubscription = this.personsService.getPersons().subscribe(({ data, loading }) => {
      this.persons = data.persons;
      this.dataSource = new MatTableDataSource<Person>(this.persons);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }

  deleteValue() {
    this.value = '';
    this.dataSource.filter = '';
  }

  applyFilter(event: KeyboardEvent) {
    if (event.key == 'Escape') {
      (event.target as HTMLInputElement).value = '';
      this.dataSource.filter = '';
    } else {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  deleteFilter() {
    this.valueFilter = '';
    this.dataSource.filter = '';
  }

  overlayDelete(uuid: string) {
    this.showOverlay = true;
    this.deleteUUID = uuid;
  }

  deletePerson(uuid: string) {
    this.personsService.deletePerson(uuid).subscribe(({ data, loading }) => {
      this.persons = this.persons.filter((person: Person) => {
        return person.uuid !== uuid;
      });
      this.update();
      this.showOverlay = false;
      this._snackBar.openFromComponent(SnackbarComponent, { data: { button: 'OK', message: 'Person  gelöscht' } });
    });
  }

  update() {
    this.dataSource = new MatTableDataSource<Person>(this.persons);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}
