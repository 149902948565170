import { Injectable } from '@angular/core';
import { FinancialService } from './financial.service';
import { Apollo, gql } from 'apollo-angular';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, debounceTime, switchMap } from 'rxjs';
import moment from 'moment';

const GET_OFFERS = gql`
  query offers {
    offers {
      _id
      title
      offer_number
      input_locale
      output_locale
      offer_date
      validity_date
      text_before
      text_after
      subtotal
      total_taxes
      customer_id
      delivery_address_id
      invoice_address_id
    }
  }
`;

const GET_OFFERS_BY_STATUS = gql`
  query offersByStatus {
    offersByStatus {
      _id
      offers {
        _id
        title
        offer_number
        input_locale
        output_locale
        offer_date
        validity_date
        text_before
        text_after
        subtotal
        total_taxes
        customer_id
        delivery_address_id
        invoice_address_id
        customer {
          name_company
        }
      }
    }
  }
`;

const GET_OFFER = gql`
  query offer($id: String!) {
    offer(id: $id) {
      _id
      title
      offer_number
      input_locale
      output_locale
      offer_date
      validity_date
      text_before
      text_after
      subtotal
      total_taxes
      customer_id
      customer {
        bds_number
        uid
        company_register_number
      }
      project_title
      project_number
      project_manager
      project_co_manager
      delivery_address_id
      invoice_address_id
      offer_line_items {
        _id
        offer_id
        title
        description
        quantity
        unitPrice
        taxPerc
      }
    }
  }
`;

const CREATE_OFFER = gql`
  mutation CreateOffer($createOfferInput: CreateOfferInput) {
    createOffer(createOfferInput: $createOfferInput) {
      _id
    }
  }
`;

const UPDATE_OFFER = gql`
  mutation UpdateOffer($id: String!, $createOfferInput: CreateOfferInput) {
    updateOffer(id: $id, createOfferInput: $createOfferInput) {
      _id
    }
  }
`;

const DELETE_OFFER = gql`
  mutation DeleteOffer($id: String!) {
    deleteOffer(id: $id) {
      _id
    }
  }
`;

const UPDATE_OFFER_STATUS = gql`
  mutation UpdateOfferStatus($id: String!, $status: String!) {
    updateOfferStatus(id: $id, status: $status) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class OfferService {
  constructor(
    public apollo: Apollo,
    public fb: FormBuilder,
    public financialService: FinancialService,
  ) {}

  isEditOffer: any;
  isEditDeliveryAddress: any;
  isEditInvoiceAddress: any;

  setDetailsForm() {
    return new FormGroup({
      customer: new FormControl('', [Validators.required]),
      offerDateDays: new FormControl('', [Validators.required]),
      offerDate: new FormControl({ value: '', disabled: true }, [Validators.required]),
      validityDateDays: new FormControl('', [Validators.required]),
      validityDate: new FormControl({ value: '', disabled: true }, [Validators.required]),
      inputLocale: new FormControl('de-DE', [Validators.required]),
      outputLocale: new FormControl('de-DE', [Validators.required]),
    });
  }

  setDetailsValues() {
    return new FormGroup({
      customer: new FormControl(this.financialService.isEditCompany, [Validators.required]),
      offerDate: new FormControl({ value: new Date(parseInt(this.isEditOffer.offer_date)), disabled: false }, [
        Validators.required,
      ]),
      validityDate: new FormControl({ value: new Date(parseInt(this.isEditOffer.validity_date)), disabled: false }, [
        Validators.required,
      ]),
      inputLocale: new FormControl(this.isEditOffer.input_locale, [Validators.required]),
      outputLocale: new FormControl(this.isEditOffer.output_locale, [Validators.required]),
    });
  }

  setProjectValues() {
    return new FormGroup({
      search: new FormControl(this.isEditOffer.title, [Validators.required]),
      title: new FormControl({ value: this.isEditOffer.title, disabled: true }, [Validators.required]),
      number: new FormControl({ value: this.isEditOffer.project_number, disabled: true }, [Validators.required]),
      manager: new FormControl({ value: this.isEditOffer.project_manager, disabled: true }, [Validators.required]),
      coManager: new FormControl({ value: this.isEditOffer.project_co_manager, disabled: true }, [Validators.required]),
    });
  }

  setTextsValues() {
    return new FormGroup({
      textBeforeTitle: new FormControl(''),
      textBefore: new FormControl(this.isEditOffer.text_before),
      textAfterTitle: new FormControl(''),
      textAfter: new FormControl(this.isEditOffer.text_after),
    });
  }

  async changeOfferDate(inputValue: string, isInit = false) {
    if (isInit) this.financialService.detailsForm.get('offerDateDays')?.setValue('today');
    if (isInit) this.financialService.detailsForm.get('offerDateDays')?.setValue('today');
    if (inputValue.startsWith('tod')) {
      this.financialService.detailsForm.get('offerDate')?.setValue(moment().format('DD.MM.yyyy'));
    } else if (inputValue.startsWith('tom')) {
      this.financialService.detailsForm.get('offerDate')?.setValue(moment().add('1', 'days').format('DD.MM.yyyy'));
    } else {
      this.financialService.detailsForm.get('offerDate')?.setValue(moment().add(inputValue, 'days').format('DD.MM.yyyy'));
    }
    this.changeValidityDate(this.financialService.detailsForm.get('validityDateDays')?.value);
  }

  async changeValidityDate(inputValue: string, isInit = false) {
    const offerDate = this.financialService.detailsForm.get('offerDate')?.value;
    if (isInit) this.financialService.detailsForm.get('validityDateDays')?.setValue(inputValue);
    const validityDate = moment(offerDate, 'DD.MM.yyyy').add(inputValue, 'days');
    this.financialService.detailsForm.get('validityDate')?.setValue(validityDate.format('DD.MM.yyyy'));
  }

  getOffers(): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: GET_OFFERS,
    }).valueChanges;
  }

  getOffersByStatus(): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: GET_OFFERS_BY_STATUS,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  getOffer(id: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: GET_OFFER,
      variables: { id },
    }).valueChanges;
  }

  subscribeDateFields() {
    this.financialService.detailsForm
      .get('validityDateDays')
      ?.valueChanges.pipe(
        debounceTime(200),
        switchMap((changedValue) => this.changeValidityDate(changedValue)),
      )
      .subscribe();
    this.financialService.detailsForm
      .get('offerDateDays')
      ?.valueChanges.pipe(
        debounceTime(200),
        switchMap((changedValue) => this.changeOfferDate(changedValue)),
      )
      .subscribe();
  }

  saveOffer(): Observable<any> {
    const offerInput = this.getOfferInput();
    return this.apollo.mutate({
      mutation: CREATE_OFFER,
      variables: { createOfferInput: offerInput },
    });
  }

  updateOffer(offerId: string): Observable<any> {
    const offerInput = this.getOfferInput();
    return this.apollo.mutate({
      mutation: UPDATE_OFFER,
      variables: { id: offerId, createOfferInput: offerInput },
    });
  }

  deleteOffer(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_OFFER,
      variables: { id },
      refetchQueries: [{ query: GET_OFFERS_BY_STATUS }],
    });
  }

  resetForms() {
    this.financialService.detailsForm = this.setDetailsForm();
    this.changeValidityDate('14', true);
    this.changeOfferDate('tod', true);
    this.financialService.resetForms();
    this.subscribeDateFields();
  }

  getOfferInput(): any {
    return {
      ...this.financialService.getInputs(),
      offer_date: moment(this.financialService.detailsForm.get('offerDate')?.value, 'DD.MM.yyyy').format('yyyy-MM-DD'),
      validity_date: moment(this.financialService.detailsForm.get('validityDate')?.value, 'DD.MM.yyyy').format('yyyy-MM-DD'),
      offer_line_items: this.financialService.lineItems.value.map((lineItem: any) => {
        return {
          _id: lineItem._id ? lineItem._id : null,
          title: lineItem.title,
          description: lineItem.description,
          quantity: +lineItem.quantity.toString().replace(',', '.'),
          unitPrice: +lineItem.unitPrice.toString().replace(',', '.'),
          taxPerc: +lineItem.taxPerc.toString().replace(',', '.'),
        };
      }),
    };
  }

  updateStatus(id: string, status: string): Observable<any> {
    return this.apollo.mutate({
      mutation: UPDATE_OFFER_STATUS,
      variables: { id, status },
      refetchQueries: [{ query: GET_OFFERS_BY_STATUS }],
    });
  }
}
