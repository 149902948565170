<mat-sidenav-container class="h-full">
  <mat-sidenav
    class="w-max"
    #drawer
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
    fixedInViewport
  >
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list class="navigation-list">
      <financehub-navigation-template
        [route]="currentRoute"
        title="Dashboard"
        icon="dashboard"
        iconWhite="dashboard_w"
        routeIs="/"
        link=""
        borderColor="border-dashboard"
        bgColor="bg-dashboard"
      ></financehub-navigation-template>

      <div mat-subheader>Finance</div>

      <financehub-navigation-template
        [route]="currentRoute"
        title="Invoices"
        icon="invoice"
        iconWhite="invoice_w"
        routeIncludes="invoice"
        link="invoices"
        borderColor="border-invoices"
        bgColor="bg-invoices"
      ></financehub-navigation-template>

      <financehub-navigation-template
        [route]="currentRoute"
        title="Offers"
        icon="offer"
        iconWhite="offer_w"
        routeIncludes="offer"
        link="offers"
        borderColor="border-offers"
        bgColor="bg-offers"
      ></financehub-navigation-template>

      <div mat-subheader>CRM</div>

      <financehub-navigation-template
        [route]="currentRoute"
        title="Companies"
        icon="company"
        iconWhite="company_w"
        routeIncludes="compan"
        link="companies"
        borderColor="border-companies"
        bgColor="bg-companies"
      ></financehub-navigation-template>

      <financehub-navigation-template
        [route]="currentRoute"
        title="People"
        icon="people"
        iconWhite="people_w"
        routeIncludes="person"
        link="persons"
        borderColor="border-people"
        bgColor="bg-people"
      ></financehub-navigation-template>

      <financehub-navigation-template
        [route]="currentRoute"
        title="Addresses"
        icon="address"
        iconWhite="address_w"
        routeIncludes="address"
        link="addresses"
        borderColor="border-addresses"
        bgColor="bg-addresses"
      ></financehub-navigation-template>

      <financehub-navigation-template
        [route]="currentRoute"
        title="Time tracking"
        icon="track"
        iconWhite="track_w"
        routeIncludes="time"
        link="timeTracking"
        borderColor="border-timeTracking"
        bgColor="bg-timeTracking"
      ></financehub-navigation-template>

      <financehub-navigation-template
        [route]="currentRoute"
        title="Graph"
        icon="KG"
        iconWhite="KG_w"
        routeIncludes="graph"
        link="graph"
        borderColor="border-graph"
        bgColor="bg-graph"
      ></financehub-navigation-template>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar
      class="border-l-8 border-r-8"
      [ngClass]="{
        '!border-invoices': currentRoute.toLowerCase().includes('allinv'),
        '!border-companies': currentRoute.toLowerCase().includes('compan'),
        '!border-people': currentRoute.toLowerCase().includes('person'),
        '!border-addresses': currentRoute.toLowerCase().includes('address'),
        '!border-timeTracking': currentRoute.toLowerCase().includes('time') || currentRoute.toLowerCase().includes('graph'),
        '!border-dashboard': currentRoute.toLowerCase() == '/'
      }"
      color="primary"
    >
      <button *ngIf="isHandset$ | async" (click)="drawer.toggle()" type="button" aria-label="Toggle sidenav" mat-icon-button>
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <img class="h-8 sm:hidden" src="../../assets/fc_logo_small.png" />
      <img class="hidden h-8 sm:block" src="../../assets/fc_logo.png" />
      <span class="example-spacer"></span>
      <button [matMenuTriggerFor]="menu" mat-button>
        <mat-icon>account_circle</mat-icon>
        <span>{{ usercode }}</span>
      </button>
      <mat-menu #menu="matMenu">
        <button *ngIf="isLoggedIn" (click)="logout()" mat-menu-item>Sign Out</button>
      </mat-menu>
    </mat-toolbar>

    <div>
      <ng-content></ng-content>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
