import { Component, OnInit } from '@angular/core';
import SnowflakeId from 'snowflake-id';
import { CompanyService } from 'src/app/services/crm/company.service';
import { NgIf, NgClass } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { InputFieldTemplateComponent } from '../../../finance/input-field-template/input-field-template.component';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/snackbar/snackbar.component';

@Component({
  selector: 'financehub-new-company',
  templateUrl: './new-company.component.html',
  styleUrls: ['./new-company.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    FormsModule,
    NgIf,
    NgClass,
    MatToolbarModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    InputFieldTemplateComponent,
    MatSnackBarModule,
  ],
})
export class NewCompanyComponent implements OnInit {
  constructor(
    private companyService: CompanyService,
    private _snackBar: MatSnackBar,
  ) {
    this.companyForm = new FormGroup({
      name_company: new FormControl('', [Validators.required]),
      uuid: new FormControl('', [Validators.required]),
      bds_number: new FormControl(100000, [Validators.required]),
      company_register_number: new FormControl(''),
      iban: new FormControl(''),
      bic: new FormControl(''),
      bank_name: new FormControl(''),
      url: new FormControl(''),
      info: new FormControl(''),
      uid: new FormControl(''),
    });
  }

  companyForm: FormGroup;

  snowflake = new SnowflakeId({
    mid: 1,
    offset: 0,
  });

  ngOnInit() {
    this.companyForm.controls['uuid'].setValue(this.snowflake.generate());
  }

  create() {
    const newCompany = this.getValuesFromForm();
    this.companyService.saveCompany(newCompany).subscribe(({ data, loading }) => {
      this.companyForm.reset();
      this.companyForm.controls['uuid'].setValue(this.snowflake.generate());
      this._snackBar.openFromComponent(SnackbarComponent, { data: { button: 'OK', message: 'Firma erstellt' } });
    });
  }

  getValuesFromForm() {
    return {
      uuid: this.companyForm.get('uuid')?.value,
      name_company: this.companyForm.get('name_company')?.value,
      bds_number: this.companyForm.get('bds_number')?.value,
      uid: this.companyForm.get('uid')?.value,
      company_register_number: this.companyForm.get('company_register_number')?.value,
      info: this.companyForm.get('info')?.value,
      iban: this.companyForm.get('iban')?.value,
      bic: this.companyForm.get('bic')?.value,
      bank_name: this.companyForm.get('bank_name')?.value,
      url: this.companyForm.get('url')?.value,
      addresses: [],
      persons: [],
    };
  }
}
