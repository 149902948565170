export const environment = {
  production: true,
  configFile: 'assets/config/config.dev.json',
  keyCloakUser: {
    username: 'bds421',
    firstName: 'BDS421',
    lastName: 'GmbH',
    email: 'office@bds421.com',
  },
  memgraphUri: 'bolt://localhost:7687',
  uri: 'https://financecompass.bds421-cloud.com/graphql',
};
