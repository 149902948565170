import { Component } from '@angular/core';
import { InvoiceService } from 'src/app/services/finance/invoice.service';
import { NgIf, NgFor, DecimalPipe } from '@angular/common';
import { FormArray, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FinancialService } from 'src/app/services/finance/financial.service';

@Component({
  selector: 'financehub-sums',
  templateUrl: './sums.component.html',
  styleUrls: ['./sums.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, DecimalPipe, FormsModule, ReactiveFormsModule],
})
export class SumsComponent {
  constructor(
    public invoiceService: InvoiceService,
    public financialService: FinancialService,
    public fb: FormBuilder,
  ) {
    this.financialService.sumsForm = this.financialService.setSumsForm();
    this.financialService.taxes = this.financialService.sumsForm.get('taxes') as FormArray;
  }
}
