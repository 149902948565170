<!-- <mat-toolbar class="matSM:top-16 sticky top-14 z-50 flex h-20 justify-start bg-white shadow-xl">
  <span class="text-gray-700">neue Firma: {{ companyForm.get('name_company')?.value }}</span>
</mat-toolbar> -->

<div class="flex justify-center">
  <form
    class="mt-5 flex grid w-full grid-cols-1 flex-col items-start lg:w-4/5 lg:grid-cols-2 lg:gap-x-5"
    [formGroup]="companyForm"
    (submit)="create()"
  >
    <financehub-input-field-template class="w-full" [formGroup]="companyForm" controlName="name_company" label="Name">
    </financehub-input-field-template>

    <financehub-input-field-template class="w-full" [readonly]="true" [formGroup]="companyForm" controlName="uuid" label="UUID">
    </financehub-input-field-template>

    <financehub-input-field-template class="w-full" [formGroup]="companyForm" controlName="bds_number" label="BDS-Nummer">
    </financehub-input-field-template>

    <financehub-input-field-template class="w-full" [readonly]="false" [formGroup]="companyForm" controlName="uid" label="UID">
    </financehub-input-field-template>

    <financehub-input-field-template class="w-full" [formGroup]="companyForm" controlName="company_register_number" label="FBNR">
    </financehub-input-field-template>

    <financehub-input-field-template class="w-full" [formGroup]="companyForm" controlName="bank_name" label="Bankname">
    </financehub-input-field-template>

    <financehub-input-field-template class="w-full" [formGroup]="companyForm" controlName="iban" label="IBAN">
    </financehub-input-field-template>

    <financehub-input-field-template class="w-full" [formGroup]="companyForm" controlName="bic" label="BIC">
    </financehub-input-field-template>

    <financehub-input-field-template class="w-full" [formGroup]="companyForm" controlName="url" label="URL">
    </financehub-input-field-template>

    <financehub-input-field-template class="w-full" [formGroup]="companyForm" type="textarea" controlName="info" label="Info">
    </financehub-input-field-template>
    <mat-toolbar class="sticky bottom-0 !bg-white !bg-opacity-0 lg:col-span-2 lg:!px-0">
      <button class="w-full" [disabled]="companyForm.invalid" mat-raised-button color="primary" type="submit">
        Firma erstellen
      </button>
    </mat-toolbar>
  </form>
</div>
