import {InjectionToken} from "@angular/core";
import {RxState} from "@rx-angular/state";

export interface Project {
    id: string
    title: string
    visible: boolean
}

export interface GlobalState {
    current_project: Project | null
    projects: Project[]
}

export const GLOBAL_RX_STATE = new InjectionToken<RxState<GlobalState>>('GLOBAL_RX_STATE')
