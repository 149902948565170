import { Component, OnInit } from '@angular/core';
import SnowflakeId from 'snowflake-id';
import { PersonsService } from 'src/app/services/crm/persons.service';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { InputFieldTemplateComponent } from '../../../finance/input-field-template/input-field-template.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/snackbar/snackbar.component';

@Component({
  selector: 'financehub-new-person',
  templateUrl: './new-person.component.html',
  styleUrls: ['./new-person.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    FormsModule,
    NgIf,
    NgFor,
    NgClass,
    MatToolbarModule,
    MatButtonModule,
    ReactiveFormsModule,
    InputFieldTemplateComponent,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatSnackBarModule,
  ],
})
export class NewPersonComponent implements OnInit {
  constructor(
    private personsService: PersonsService,
    private _snackBar: MatSnackBar,
  ) {
    this.personForm = new FormGroup({
      first_name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      uuid: new FormControl('', [Validators.required]),
      bds_number: new FormControl(200000, [Validators.required]),
      birthday: new FormControl(''),
      phone_private: new FormControl(''),
      mail_private: new FormControl('', [Validators.email]),
      fax: new FormControl(''),
      gender: new FormControl(''),
      title_academic: new FormControl(''),
    });
  }

  snowflake = new SnowflakeId({
    mid: 1,
    offset: 0,
  });
  numberExistsName = null;
  numberNotValid = false;
  personForm: FormGroup;
  genders = ['Weiblich', 'Männlich', 'Divers', 'Inter', 'Offen', 'Kein Eintrag'];

  ngOnInit() {
    this.personForm.controls['uuid'].setValue(this.snowflake.generate());
    // this.nextNumber();
  }

  create() {
    let newPerson = this.getValuesFromForm();
    if (this.personForm.valid) {
      this.personsService.savePerson(newPerson).subscribe(({ data, loading }) => {
        this.personForm.reset();
        this.personForm.controls['uuid'].setValue(this.snowflake.generate());
        this._snackBar.openFromComponent(SnackbarComponent, { data: { button: 'OK', message: 'Person  erstellt' } });
      });
    } else {
      this.personForm.markAllAsTouched();
    }
  }

  getValuesFromForm() {
    return {
      uuid: this.personForm.get('uuid')?.value,
      bds_number: this.personForm.get('bds_number')?.value,
      first_name: this.personForm.get('first_name')?.value,
      last_name: this.personForm.get('last_name')?.value,
      birthday: this.personForm.get('birthday')?.value,
      phone_private: this.personForm.get('phone_private')?.value,
      mail_private: this.personForm.get('mail_private')?.value,
      fax: this.personForm.get('fax')?.value,
      gender: this.personForm.get('gender')?.value,
      title_academic: this.personForm.get('title_academic')?.value,
      companies: [],
      addresses: [],
    };
  }

  nextNumber() {
    //TODO: next number
    /*  const allNumbers = this.invoiceService.persons.filter(c => c.bdsNumber !== null).map(c => c.bdsNumber);
    if (allNumbers.length === 0) {
      this.newPerson.bds_number = 300000;
    } else {
      const maxNumber = Math.max(...allNumbers);
      this.newPerson.bds_number = maxNumber + 1;
    } */
  }

  checkNumber() {
    //TODO: check number
    /* let exists = this.invoiceService.persons.find(c => c.bdsNumber == this.newPerson.bdsNumber);
    if (exists) {
      this.numberExistsName = exists.firstname+' '+exists.lastname;
    } else {
      this.numberExistsName = null;
    }
    if (this.newPerson.bdsNumber !== null) {
    if (!this.newPerson.bdsNumber.toString().startsWith('3') || this.newPerson.bdsNumber.toString().length !== 6) {
      this.numberNotValid = true;
    } else {
      this.numberNotValid = false;
    }
    } else {
      this.numberNotValid = true;
    } */
  }

  // create() {
  //   this.personsService.savePerson(this.newPerson).subscribe(({ data, loading }) => {
  //     this.newPerson = {
  //       uuid: '',
  //       companies: [],
  //       addresses: [],
  //       bds_number: 300000,
  //       first_name: '',
  //       last_name: '',
  //       birthday: '',
  //       phone_private: '',
  //       mail_private: '',
  //       fax: '',
  //       gender: '',
  //       title_academic: '',
  //     };
  //     this.newPerson.uuid = this.snowflake.generate();
  //     this.nextNumber();
  //   });
  // }
}
