import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Address } from '../../interfaces/address';
import { AddressesService } from '../../services/crm/addresses.service';
import { InvoiceService } from '../../services/finance/invoice.service';
import { MatIconModule } from '@angular/material/icon';
import { SnackbarComponent } from 'src/app/snackbar/snackbar.component';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';

@Component({
  selector: 'financehub-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    NgIf,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    NgClass,
    MatPaginatorModule,
    MatIconModule,
    MatSnackBarModule,
  ],
})
export class AddressesComponent implements OnInit, OnDestroy {
  deleteUUID: string = '';
  valueFilter: string = '';
  showOverlay: boolean = false;
  addresses = [];
  displayedColumns: string[] = ['bds_number', 'name', 'icons'];
  dataSource = new MatTableDataSource<Address>(this.addresses);

  private querySubscription!: Subscription;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    public invoiceService: InvoiceService,
    public addressesService: AddressesService,
    private router: Router,
    private _snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.fetchAddresses();
      }
    });
    this.fetchAddresses();
  }

  fetchAddresses() {
    this.querySubscription = this.addressesService.getAddresses().subscribe(({ data, loading }) => {
      this.addresses = data.addresses;
      this.dataSource = new MatTableDataSource<Address>(this.addresses);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }

  applyFilter(event: KeyboardEvent) {
    if (event.key == 'Escape') {
      (event.target as HTMLInputElement).value = '';
      this.dataSource.filter = '';
    } else {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  deleteFilter() {
    this.valueFilter = '';
    this.dataSource.filter = '';
  }

  overlayDelete(uuid: string) {
    this.showOverlay = true;
    this.deleteUUID = uuid;
  }

  deleteAddress(uuid: string) {
    /* let index = this.invoiceService.addresses.findIndex(a => a.uuid == uuid);
    this.invoiceService.addresses.splice(index, 1);
    this.showOverlay = false;
    this.update(); */
    this.delete(uuid);
  }

  deleteAddressAdmin(uuid: string) {
    /* let index = this.invoiceService.addresses.findIndex(a => a.uuid == uuid);
    this.invoiceService.addresses.splice(index, 1);
    const updatedCompanies = this.invoiceService.companies.map(c => {
      c.addresses = c.addresses.filter(a => a.uuid !== uuid);
      return c;
    });
    const updatedPersons = this.invoiceService.persons.map(p => {
      p.addresses = p.addresses.filter(a => a.uuid !== uuid);
      return p;
    });
    this.showOverlay = false;
    this.update(); */
    this.delete(uuid);
  }

  delete(uuid: string) {
    this.addressesService.deleteAddress(uuid).subscribe(({ data, loading }) => {
      this.addresses = this.addresses.filter((addr: Address) => {
        return addr.uuid !== uuid;
      });
      this.update();
      this.showOverlay = false;
      this._snackBar.openFromComponent(SnackbarComponent, { data: { button: 'OK', message: 'Adresse  gelöscht' } });
    });
  }

  update() {
    //this.invoiceService.addresses.sort((a, b) => a.bdsNumber - b.bdsNumber);
    this.dataSource = new MatTableDataSource<Address>(this.addresses);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}
