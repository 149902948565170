import { Component, OnInit } from '@angular/core';
import SnowflakeId from 'snowflake-id';
import { AddressesService } from 'src/app/services/crm/addresses.service';
import { NgIf, NgClass } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { Address } from 'src/app/interfaces/address';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { InputFieldTemplateComponent } from '../../../finance/input-field-template/input-field-template.component';
import { SnackbarComponent } from 'src/app/snackbar/snackbar.component';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';

@Component({
  selector: 'financehub-new-address',
  templateUrl: './new-address.component.html',
  styleUrls: ['./new-address.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    FormsModule,
    NgIf,
    NgClass,
    MatToolbarModule,
    MatButtonModule,
    InputFieldTemplateComponent,
    ReactiveFormsModule,
    MatSnackBarModule,
  ],
})
export class NewAddressComponent implements OnInit {
  constructor(
    private addressesService: AddressesService,
    private _snackBar: MatSnackBar,
  ) {
    this.addressForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      uuid: new FormControl({ value: '', disabled: true }, [Validators.required]),
      bds_number: new FormControl(200000, [Validators.required]),
      country: new FormControl(''),
      street: new FormControl(''),
      city: new FormControl(''),
      house_number: new FormControl(''),
      zip: new FormControl(''),
    });
  }

  snowflake = new SnowflakeId({
    mid: 1,
    offset: 0,
  });

  newAddress: Address = {
    uuid: '',
    companies: [],
    persons: [],
    name: '',
    street: '',
    house_number: '',
    zip: '',
    city: '',
    country: '',
    bds_number: 200000,
  };

  addressForm: FormGroup;

  ngOnInit() {
    this.addressForm.controls['uuid'].setValue(this.snowflake.generate());
  }

  create() {
    let newAddress = this.getValuesFromForm();
    if (this.addressForm.valid) {
      this.addressesService.saveAddress(newAddress).subscribe(({ data, loading }) => {
        this.addressForm.reset();
        this.addressForm.controls['uuid'].setValue(this.snowflake.generate());
        this._snackBar.openFromComponent(SnackbarComponent, { data: { button: 'OK', message: 'Adresse  erstellt' } });
      });
    } else {
      this.addressForm.markAllAsTouched();
    }
  }

  getValuesFromForm() {
    return {
      uuid: this.addressForm.get('uuid')?.value,
      bds_number: parseInt(this.addressForm.get('bds_number')?.value),
      name: this.addressForm.get('name')?.value,
      country: this.addressForm.get('country')?.value,
      zip: this.addressForm.get('zip')?.value,
      city: this.addressForm.get('city')?.value,
      street: this.addressForm.get('street')?.value,
      house_number: this.addressForm.get('house_number')?.value,
      companies: [],
      persons: [],
    };
  }
}
