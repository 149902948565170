import { Component, OnInit, QueryList, ViewChild, ViewChildren, HostListener } from '@angular/core';
import { InvoiceService } from '../../services/finance/invoice.service';
import { NgIf, NgClass, NgFor, DatePipe, DecimalPipe } from '@angular/common';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { DialogComponent } from '../../dialog/dialog.component';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { StatusService } from '../../services/finance/status.service';
import { status } from '../../interfaces/status';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { FinancialService } from '../../services/finance/financial.service';

@Component({
  selector: 'financehub-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    NgIf,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    NgClass,
    MatPaginatorModule,
    NgFor,
    MatIconModule,
    DatePipe,
    DialogModule,
    MatTabsModule,
    MatToolbarModule,
    DecimalPipe,
    MatTooltipModule,
    MatSnackBarModule,
  ],
})
export class InvoicesComponent implements OnInit {
  constructor(
    public invoiceService: InvoiceService,
    public statusService: StatusService,
    public financialService: FinancialService,
    private datePipe: DatePipe,
    private router: Router,
    public dialog: Dialog,
    private _snackBar: MatSnackBar,
  ) {}

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild('matTabGroup') matTabGroup!: MatTabGroup;

  displayedColumns: string[] = ['number', 'invoiceDate', 'dueDate', 'net', 'gross', 'customerID', 'status', 'icon'];
  dataSources: { [key: string]: MatTableDataSource<any> } = {};

  allInvoices: any = {};
  notSwitching = true;

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.fetchInvoices();
      }
    });
    this.fetchInvoices();
  }

  fetchInvoices() {
    this.invoiceService.getInvoicesByStatus().subscribe(({ data, loading }) => {
      this.statusService.invoiceStatuses.forEach((status: status, index: number) => {
        const statusType = status.type;
        const invoicesByStatus = data.invoicesByStatus.find((i: any) => i._id === statusType);
        if (invoicesByStatus) {
          this.initDataTables(invoicesByStatus, index);
        } else {
          this.dataSources[statusType] = new MatTableDataSource<any>([]);
          this.dataSources[statusType].paginator = this.paginator.toArray()[index];
          this.dataSources[statusType].sort = this.sort.toArray()[index];
        }
      });
    });
  }

  initDataTables(items: any, index: number) {
    this.allInvoices[items._id] = items.invoices;
    this.financialService.filterValues[items._id] = '';
    this.dataSources[items._id] = new MatTableDataSource<any>(this.allInvoices[items._id]);
    this.dataSources[items._id].paginator = this.paginator.toArray()[index];
    this.sortTable(this.dataSources[items._id], this.sort.toArray()[index]);
    const updatedData = this.dataSources[items._id].data.map((item) => ({
      ...item,
      invoice_date_formatted: this.datePipe.transform(new Date(parseInt(item.invoice_date))),
      due_date_formatted: this.datePipe.transform(new Date(parseInt(item.due_date))),
      total_sum: item.subtotal + item.total_taxes,
      name: item.customer ? item.customer.name_company : '',
    }));
    this.dataSources[items._id].data = updatedData;
  }

  sortTable(dataSource: MatTableDataSource<any>, sort: MatSort) {
    dataSource.sort = sort;
    dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'number':
          return item.invoice_number;
        case 'invoiceDate':
          return item.invoice_date;
        case 'dueDate':
          return item.due_date;
        case 'net':
          return item.subtotal;
        case 'gross':
          return item.subtotal;
        case 'customerID':
          return item.customer_id;
        default:
          return item[property];
      }
    };
    const sortState: Sort = { active: 'invoiceDate', direction: 'desc' };
    dataSource.sort.active = sortState.active;
    dataSource.sort.direction = sortState.direction;
    dataSource.sort.sortChange.emit(sortState);
  }

  openDeleteDialog(invoice: any): void {
    const dialogRef = this.dialog.open<string>(DialogComponent, {
      width: '500px',
      data: {
        headline: 'Aktion bestätigen',
        text: `Wollen Sie die Rechnung ${invoice.title} wirklich löschen?`,
        element: invoice,
      },
    });

    dialogRef.closed.subscribe((invoice) => {
      if (invoice) this.deleteInvoice(invoice);
    });
  }

  deleteInvoice(invoice: any) {
    this.invoiceService.deleteInvoice(invoice._id).subscribe(({ data, loading }) => {
      this._snackBar.openFromComponent(SnackbarComponent, { data: { button: 'OK', message: 'Rechnung gelöscht' } });
    });
  }

  statusChanged(value: string, id: string) {
    this.invoiceService.updateStatus(id, value).subscribe(({ data, loading }) => {
      this._snackBar.openFromComponent(SnackbarComponent, { data: { button: 'OK', message: 'Status aktualisiert' } });
    });
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const isInputFocused = document.activeElement instanceof HTMLInputElement;
    const statusCount = this.statusService.invoiceStatuses.length;
    if (!isInputFocused) {
      for (let i = 0; i < statusCount; i++) {
        if (event.key === '' + (i + 1)) this.matTabGroup.selectedIndex = i;
      }
    }
  }

  getTabLabel(status: status, index: number) {
    const statusCount = this.dataSources[status.type]?.data?.length || 0;
    return `${index + 1} - ${status.title} (${statusCount})`;
  }

  availableStatuses(statusType: string) {
    return this.statusService.invoiceStatuses.filter((s) => s.type !== statusType);
  }
}
