<form>
  <div class="flex justify-center">
    <form
      class="flex grid w-full grid-cols-1 flex-col items-start px-5 lg:grid-cols-2 lg:gap-x-5"
      [formGroup]="financialService.customerForm"
    >
      <financehub-input-field-template
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="UUID"
        controlName="uuid"
      ></financehub-input-field-template>

      <financehub-input-field-template
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="bds-Nummer"
        controlName="bds_number"
      ></financehub-input-field-template>

      <financehub-input-field-template
        [formGroup]="financialService.customerForm"
        label="UID"
        controlName="uid"
      ></financehub-input-field-template>

      <financehub-input-field-template
        [formGroup]="financialService.customerForm"
        label="Firmenbuchnummer"
        controlName="company_register_number"
      ></financehub-input-field-template>

      <span class="a-flex-center text-center text-base lg:col-span-2 lg:col-start-1">
        <mat-icon>euro</mat-icon><span>&nbsp;Rechnungsadresse</span>
      </span>
      <br />

      <mat-form-field class="lg:col-span-2 lg:col-start-1" appearance="outline">
        <mat-label>Suche</mat-label>
        <input
          [matAutocomplete]="invoiceAddress"
          (keyup)="onInvoiceAddressChange($event)"
          formControlName="invoice_search"
          matInput
        />
        <mat-error class="text-end" *ngIf="financialService.customerForm.get('invoice_search')?.errors?.['required']"
          >Pflichtfeld</mat-error
        >
        <mat-autocomplete #invoiceAddress="matAutocomplete" (optionSelected)="onInvoiceAddressSelected($event)" panelWidth="auto">
          <mat-option *ngFor="let a of financialService.filteredInvoiceAddresses" [value]="a.uuid">{{ a.name }}</mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <financehub-input-field-template
        class="lg:col-span-2 lg:col-start-1"
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="Name"
        controlName="invoice_name"
      ></financehub-input-field-template>

      <financehub-input-field-template
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="Straße"
        controlName="invoice_street"
      ></financehub-input-field-template>

      <financehub-input-field-template
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="Hausnummer"
        controlName="invoice_house_number"
      ></financehub-input-field-template>

      <financehub-input-field-template
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="Stadt"
        controlName="invoice_city"
      ></financehub-input-field-template>

      <financehub-input-field-template
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="PLZ"
        controlName="invoice_zip"
      ></financehub-input-field-template>

      <financehub-input-field-template
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="Land"
        controlName="invoice_country"
      ></financehub-input-field-template>

      <span class="a-flex-center text-center text-base lg:col-span-2 lg:col-start-1">
        <mat-icon>euro</mat-icon><span>&nbsp;Lieferadresse</span>
      </span>
      <br />

      <mat-form-field class="lg:col-span-2 lg:col-start-1" appearance="outline">
        <mat-label>Suche</mat-label>
        <input
          [matAutocomplete]="deliveryAddress"
          (keyup)="onDeliveryAddressChange($event)"
          formControlName="delivery_search"
          matInput
        />
        <mat-error class="text-end" *ngIf="financialService.customerForm.get('delivery_search')?.errors?.['required']"
          >Pflichtfeld</mat-error
        >
        <mat-autocomplete
          #deliveryAddress="matAutocomplete"
          (optionSelected)="onDeliveryAddressSelected($event)"
          panelWidth="auto"
        >
          <mat-option *ngFor="let a of financialService.filteredDeliveryAddresses" [value]="a.uuid">{{ a.name }}</mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <financehub-input-field-template
        class="lg:col-span-2 lg:col-start-1"
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="Name"
        controlName="delivery_name"
      ></financehub-input-field-template>

      <financehub-input-field-template
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="Land"
        controlName="delivery_country"
      ></financehub-input-field-template>

      <financehub-input-field-template
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="PLZ"
        controlName="delivery_zip"
      ></financehub-input-field-template>

      <financehub-input-field-template
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="Stadt"
        controlName="delivery_city"
      ></financehub-input-field-template>

      <financehub-input-field-template
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="Straße"
        controlName="delivery_street"
      ></financehub-input-field-template>

      <financehub-input-field-template
        [readonly]="true"
        [formGroup]="financialService.customerForm"
        label="Hausnummer"
        controlName="delivery_house_number"
      ></financehub-input-field-template>
    </form>
  </div>
</form>
