import { NgFor, NgForOf, NgIf } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'financehub-input-field-template',
  templateUrl: './input-field-template.component.html',
  styleUrls: ['./input-field-template.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    NgIf,
    MatSelectModule,
    NgFor,
    MatDatepickerModule,
  ],
})
export class InputFieldTemplateComponent {
  @Input() label!: string;
  @Input() readonly!: boolean;
  @Input() formGroup!: FormGroup;
  @Input() controlName!: string;
  @Input() type!: string;
  @Input() optionsArray!: string[];
  @Output() inputChange = new EventEmitter();

  onInputChange(event: any) {
    this.inputChange.emit(event);
  }
}
