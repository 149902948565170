import {Component, Inject} from '@angular/core';
import {ActivatedRoute, Router, RouterModule, RoutesRecognized} from '@angular/router';
import {NavigationComponent} from "./navigation/navigation.component";
import {ReactiveFormsModule} from '@angular/forms';
import {GraphQLModule} from "./graphql.module";
import {GLOBAL_RX_STATE, GlobalState} from "./global-state";
import {RxState} from "@rx-angular/state";
import {combineLatest, filter, Observable, of, switchMap, tap} from "rxjs";
import {KeycloakEventType, KeycloakService} from "keycloak-angular";
import {MatToolbarModule} from "@angular/material/toolbar";
import {registerLocaleData} from "@angular/common";
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';

@Component({
    standalone: true,
    imports: [
        RouterModule,
        ReactiveFormsModule,
        GraphQLModule,
        NavigationComponent,
        MatToolbarModule,
    ],
    selector: 'financehub-bds421-com-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    title = 'financehub.bds421.com';

    private readonly projects$ = this.state.select('projects');

    constructor(
        @Inject(GLOBAL_RX_STATE) private state: RxState<GlobalState>,
        private router: Router,
        private readonly keycloak: KeycloakService,
    ) {
        // init localization
        registerLocaleData(localeDe, 'de-DE');
        registerLocaleData(localeEn, 'en-EN');

        this.keycloak.keycloakEvents$.subscribe(event => {
                if (event.type == KeycloakEventType.OnAuthSuccess) {
                    // do nothing
                    //this.router.navigateByUrl('/');
                }
                else if (event.type == KeycloakEventType.OnTokenExpired) {
                    console.log('Token refreshed at ' + new Date());

                    this.keycloak.updateToken(20);
                }
                else if (event.type == KeycloakEventType.OnAuthRefreshError) {
                    this.router.navigateByUrl('/');
                }
        });

        const updateProjectIdOnUrlChange$ = this.router.events.pipe(
            filter(val => val instanceof RoutesRecognized),
            switchMap(val => {
                if (val instanceof RoutesRecognized) {
                    return of(val.state.root.firstChild?.params['project_id'])
                }
                return of(null)
            })
        )

        const combinedProject$ = combineLatest([updateProjectIdOnUrlChange$, this.projects$]).pipe(
            switchMap(([project_id, projects]) => {
                if (project_id) {
                    const project = projects.find(project => project.id === project_id);
                    return of({current_project: project});
                }
                return of({current_project: null});
            })
        );

        this.state.connect(combinedProject$);

    }




}
