import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'financehub-navigation-template',
  templateUrl: './navigation-template.component.html',
  styleUrls: ['./navigation-template.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, RouterLink, MatListModule],
})
export class NavigationTemplateComponent {
  @Input() title!: string;
  @Input() icon!: string;
  @Input() iconWhite!: string;
  @Input() route!: string;
  @Input() routeIncludes!: string;
  @Input() routeIs!: string;
  @Input() link!: string;
  @Input() borderColor!: string;
  @Input() bgColor!: string;
}
