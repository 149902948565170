<div class="flex-col rounded bg-white p-5">
  <h1>{{ data.headline }}</h1>
  <p *ngIf="data.text">{{ data.text }}</p>

  <!-- ADD ADDRESS TO COMPANY -->
  <form *ngIf="addressesService.dialogCall === 'addAddressToCompany'" [formGroup]="addressesService.addressToCompanyForm">
    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Adresse</mat-label>
      <mat-select (selectionChange)="addressesService.addressToCompanyDisablingCheckboxes()" formControlName="selectedAddress">
        <mat-option *ngFor="let a of addressesService.availableAddresses" [value]="a.uuid">{{ a.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <section class="mb-3 flex w-full">
      <div class="a-flex-center mr-2">
        <mat-checkbox formControlName="delivery"><mat-icon>local_shipping</mat-icon> </mat-checkbox>
      </div>
      <div class="a-flex-center ml-2">
        <mat-checkbox formControlName="invoice"><mat-icon>euro</mat-icon> </mat-checkbox>
      </div>
    </section>
  </form>

  <!-- ADD PERSON TO COMPANY -->
  <form *ngIf="personsService.dialogCall === 'addPersonToCompany'" [formGroup]="personsService.personToCompanyForm">
    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Person</mat-label>
      <mat-select formControlName="selectedPerson">
        <mat-option *ngFor="let person of personsService.availablePersons" [value]="person.uuid"
          >{{ person.first_name }} {{ person.last_name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </form>

  <div class="flex w-full justify-end">
    <button (click)="dialogRef.close()" mat-button color="primary">Abbrechen</button>

    <!-- DELETE DIALOG -->
    <button
      *ngIf="!addressesService.dialogCall && !personsService.dialogCall"
      (click)="dialogRef.close(data.element)"
      mat-button
      color="primary"
    >
      OK
    </button>

    <!-- ADD DIALOG -->
    <button
      *ngIf="addressesService.dialogCall || personsService.dialogCall"
      [disabled]="addressesService.addressToCompanyForm.invalid && personsService.personToCompanyForm.invalid"
      (click)="dialogRef.close(data.element)"
      mat-button
      color="primary"
    >
      OK
    </button>
  </div>
</div>
