import { Component, OnInit, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Address } from 'src/app/interfaces/address';
import { Company } from 'src/app/interfaces/company';
import { Person } from 'src/app/interfaces/person';
import { InvoiceService } from 'src/app/services/finance/invoice.service';
import { Subscription } from 'rxjs';
import { PersonsService } from 'src/app/services/crm/persons.service';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgIf, NgClass, DatePipe } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { InputFieldTemplateComponent } from '../../../finance/input-field-template/input-field-template.component';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { SnackbarComponent } from 'src/app/snackbar/snackbar.component';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';

@Component({
  selector: 'financehub-show-person',
  templateUrl: './show-person.component.html',
  styleUrls: ['./show-person.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    MatExpansionModule,
    NgClass,
    FormsModule,
    MatTableModule,
    MatSortModule,
    DatePipe,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    InputFieldTemplateComponent,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatSnackBarModule,
  ],
})
export class ShowPersonComponent implements OnInit, OnDestroy {
  constructor(
    public invoiceService: InvoiceService,
    private personsService: PersonsService,
    public route: ActivatedRoute,
    private _snackBar: MatSnackBar,
  ) {
    this.personForm = new FormGroup({
      first_name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      uuid: new FormControl('', [Validators.required]),
      bds_number: new FormControl(100000, [Validators.required]),
      gender: new FormControl(''),
      birthday: new FormControl(''),
      phone_private: new FormControl(''),
      mail_private: new FormControl(''),
      fax: new FormControl(''),
      title_academic: new FormControl(''),
    });
  }

  @ViewChild('companies', { read: MatSort, static: true }) sortCompanies!: MatSort;
  @ViewChild('addresses', { read: MatSort, static: true }) sortAddresses!: MatSort;
  @ViewChild('matTabGroup') matTabGroup!: MatTabGroup;

  private querySubscription!: Subscription;
  uuid!: string;
  personForm!: FormGroup;
  person!: Person;
  genders = ['Weiblich', 'Männlich', 'Divers', 'Inter', 'Offen', 'Kein Eintrag'];
  editPersonActive = false;
  displayedColumnsAddresses: string[] = [
    'bdsNumber',
    'name',
    'country',
    'zip',
    'city',
    'street',
    'house_number',
    'primary',
    'secondary',
  ];
  dataSourceAddresses!: MatTableDataSource<Address>;
  editAddressesActive: boolean = false;
  addAddressOverlay: boolean = false;
  valueFilterAdresses!: string;

  displayedColumnsCompanies: string[] = ['bdsNumber', 'name', 'position', 'phoneOffice', 'mailOffice', 'info'];
  dataSourceCompanies!: MatTableDataSource<Company>;
  editCompaniesActive: boolean = false;
  addCompanyOverlay: boolean = false;
  valueFilterCompanies!: string;

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.uuid = params.get('uuid') || '';
      this.querySubscription = this.personsService.getPerson(this.uuid).subscribe(({ data, loading }) => {
        this.person = { ...data.person };
        this.personForm = new FormGroup({
          first_name: new FormControl({ value: this.person.first_name, disabled: true }, [Validators.required]),
          last_name: new FormControl({ value: this.person.last_name, disabled: true }, [Validators.required]),
          uuid: new FormControl({ value: this.person.uuid, disabled: true }, [Validators.required]),
          bds_number: new FormControl({ value: this.person.bds_number, disabled: true }, [Validators.required]),
          gender: new FormControl({ value: this.person.gender, disabled: true }),
          birthday: new FormControl({ value: this.person.birthday, disabled: true }),
          phone_private: new FormControl({ value: this.person.phone_private, disabled: true }),
          mail_private: new FormControl({ value: this.person.mail_private, disabled: true }),
          fax: new FormControl({ value: this.person.fax, disabled: true }),
          title_academic: new FormControl({ value: this.person.title_academic, disabled: true }),
        });
      });
    });

    // TODO:
    //this.getAddresses();
    //this.getCompanies();
  }

  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }

  enableFields() {
    this.personForm.get('first_name')?.enable();
    this.personForm.get('last_name')?.enable();
    this.personForm.get('bds_number')?.enable();
    this.personForm.get('gender')?.enable();
    this.personForm.get('birthday')?.enable();
    this.personForm.get('phone_private')?.enable();
    this.personForm.get('mail_private')?.enable();
    this.personForm.get('fax')?.enable();
    this.personForm.get('title_academic')?.enable();
  }

  disableFields() {
    this.personForm.get('first_name')?.disable();
    this.personForm.get('last_name')?.disable();
    this.personForm.get('bds_number')?.disable();
    this.personForm.get('gender')?.disable();
    this.personForm.get('birthday')?.disable();
    this.personForm.get('phone_private')?.disable();
    this.personForm.get('mail_private')?.disable();
    this.personForm.get('fax')?.disable();
    this.personForm.get('title_academic')?.disable();
  }

  editPerson() {
    this.editPersonActive = true;
    this.enableFields();
  }

  updatePerson() {
    this.person.first_name = this.personForm.get('first_name')?.value;
    this.person.last_name = this.personForm.get('last_name')?.value;
    this.person.title_academic = this.personForm.get('title_academic')?.value;
    this.person.bds_number = this.personForm.get('bds_number')?.value;
    this.person.birthday = this.personForm.get('birthday')?.value;
    this.person.fax = this.personForm.get('fax')?.value;
    this.person.mail_private = this.personForm.get('mail_private')?.value;
    this.person.phone_private = this.personForm.get('phone_private')?.value;
    this.person.gender = this.personForm.get('gender')?.value;
    this.personsService.updatePerson(this.uuid, this.person).subscribe(({ data, loading }) => {
      console.log(data);
      this._snackBar.openFromComponent(SnackbarComponent, { data: { button: 'OK', message: 'Person  aktualisiert' } });
    });
    this.editPersonActive = false;
    this.disableFields();
  }

  editAddresses() {
    this.editAddressesActive = !this.editAddressesActive;
  }

  updateAddresses() {
    this.editAddressesActive = false;
  }

  editCompanies() {
    this.editCompaniesActive = !this.editCompaniesActive;
  }

  updateCompanies() {
    this.editCompaniesActive = !this.editCompaniesActive;
  }

  getAddressesLabel() {
    const addressesCount = this.dataSourceAddresses?.data?.length || 0;
    return `2 - Adressen (${addressesCount})`;
  }

  getCompaniesLabel() {
    const companiesCount = this.dataSourceCompanies?.data?.length || 0;
    return `3 - Firmen (${companiesCount})`;
  }

  applyFilter(event: KeyboardEvent, dataSource: MatTableDataSource<any>) {
    if (event.key == 'Escape') {
      (event.target as HTMLInputElement).value = '';
      dataSource.filter = '';
    } else {
      const filterValue = (event.target as HTMLInputElement).value;
      dataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  deleteValue(value: string, dataSource: MatTableDataSource<any>) {
    dataSource.filter = '';
    switch (value) {
      case 'addresses':
        this.valueFilterAdresses = '';
        break;
      case 'persons':
        this.valueFilterCompanies = '';
        break;
    }
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const isInputFocused = document.activeElement instanceof HTMLInputElement;
    if (!isInputFocused) {
      switch (event.key) {
        case '1':
          this.matTabGroup.selectedIndex = 0;
          break;
        case '2':
          this.matTabGroup.selectedIndex = 1;
          break;
        case '3':
          this.matTabGroup.selectedIndex = 2;
          break;
        default:
          break;
      }
    }
  }

  /* ngAfterViewInit() {
    //this.dataSourceAddresses.sort = this.sortAddresses;
    //this.dataSourceCompanies.sort = this.sortCompanies;
  } */

  /* getAddresses() {
    this.addresses = this.invoiceService.addresses
    .filter(a => a.persons.includes(this.uuid))
    .map(a => {
      const primary = this.person.addresses.some(addr => addr.uuid === a.uuid && addr.primary);
      const secondary = this.person.addresses.some(addr => addr.uuid === a.uuid && addr.secondary);
      return {
        ...a,
        primary,
        secondary
      };
    });
    this.dataSourceAddresses = new MatTableDataSource<Address>(this.addresses);
  }

  getCompanies() {
    this.companies = this.invoiceService.companies
    .filter(c => c.persons.some(pers => pers.uuid === this.uuid))
    .map(c => {
      const personData = c.persons.find(pers => pers.uuid === this.uuid) || {};
      const phoneOffice = personData.phoneOffice || '';
      const mailOffice = personData.mailOffice || '';
      const info = personData.info || '';
      const position = personData.position || '';
      return {
        ...c,
        phoneOffice,
        mailOffice,
        info,
        position
      };
    });
    this.dataSourceCompanies = new MatTableDataSource<Company>(this.companies);
  } */

  checkBdsNumberPerson(uuid: string, number: number) {
    /*  //TODO: 
    let check = this.invoiceService.persons.find(p => p.bdsNumber == number && p.uuid !== uuid);
    this.numberExistsPerson = check ? (check.lastname + ' ' + check.firstname) : null;
    this.numberNotValidPerson = !number.toString().startsWith('3') || number.toString().length !== 6;  */
  }
}
