<mat-tab-group class="flex w-full" #matTabGroup>
  <!-- DRAFT -->
  <mat-tab
    *ngFor="let status of statusService.offerStatuses; index as i"
    [label]="getTabLabel(status, i)"
    [labelClass]="status.type"
  >
    <div class="w-full lg:w-4/5">
      <div class="my-5 flex items-center justify-between px-5 lg:px-0">
        <mat-form-field class="all-invoices-filter w-2/5 lg:w-2/5" appearance="outline">
          <mat-label>Filter</mat-label>
          <input
            [(ngModel)]="financialService.filterValues[status.type]"
            (keyup)="financialService.applyFilter($event, dataSources[status.type])"
            matInput
          />
          <mat-icon
            class="cursor-pointer !p-1"
            *ngIf="financialService.filterValues[status.type]"
            (click)="financialService.deleteValue(status.type, dataSources[status.type])"
            matSuffix
            >close</mat-icon
          >
        </mat-form-field>
        <button class="w-2/5" [routerLink]="'../offer/create'" mat-raised-button color="primary">
          <mat-icon>add</mat-icon>Angebot
        </button>
      </div>
      <div class="mat-elevation-z8 mx-5 mb-5 overflow-x-auto lg:mx-0">
        <table [dataSource]="dataSources[status.type]" mat-table matSort>
          <ng-container matColumnDef="number">
            <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by number">Ang.nr.</th>
            <td *matCellDef="let element" mat-cell>{{ element.offer_number }}</td>
          </ng-container>
          <ng-container matColumnDef="offerDate">
            <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by offerDate">Ang.dat.</th>
            <td *matCellDef="let element" mat-cell>{{ element.offer_date | date }}</td>
          </ng-container>
          <ng-container matColumnDef="validityDate">
            <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by validityDate">Gült.dat.</th>
            <td *matCellDef="let element" mat-cell>{{ element.validity_date | date }}</td>
          </ng-container>
          <ng-container matColumnDef="customerID">
            <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by customerID">Kunde</th>
            <td *matCellDef="let element" mat-cell>
              <div
                class="line-clamp-2"
                [matTooltip]="
                  element.customer ? element.customer.name_company : 'Gelöschter Kunde (ID: ' + element.customer_id + ')'
                "
              >
                {{ element.customer ? element.customer.name_company : 'Gelöschter Kunde (ID: ' + element.customer_id + ')' }}
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="net">
            <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by net">Netto</th>
            <td class="!text-end" *matCellDef="let element" mat-cell>
              {{ element.subtotal | number: '1.2-2' : 'de-DE' }}&nbsp;€
            </td>
          </ng-container>
          <ng-container matColumnDef="gross">
            <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by gross">Brutto</th>
            <td class="!text-end" *matCellDef="let element" mat-cell>
              {{ element.subtotal + element.total_taxes | number: '1.2-2' : 'de-DE' }}&nbsp;€
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by status">Status</th>
            <td *matCellDef="let element; let even = even" mat-cell>
              <div class="custom-select">
                <mat-select
                  class="all-invoices-select border-0"
                  id=""
                  [ngClass]="{ 'bg-gray-200': even }"
                  (selectionChange)="statusChanged($event.value, element._id)"
                  name=""
                >
                  <mat-option 
                    *ngFor="let availableStatus of availableStatuses(status.type)" 
                    [value]="availableStatus.type"
                    class="w-40"
                  >
                    {{ availableStatus.title }}
                  </mat-option>
                </mat-select>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="icon">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef="let element" mat-cell>
              <div class="flex flex-col justify-end xs:flex-row">
                <mat-icon class="mr-3 cursor-pointer" [routerLink]="'../offer/' + element._id">edit</mat-icon>
                <mat-icon class="cursor-pointer" (click)="openDeleteDialog(element)">delete</mat-icon>
              </div>
            </td>
          </ng-container>
          <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr *matRowDef="let row; let even = even; columns: displayedColumns" [ngClass]="{ '!bg-gray-200': even }" mat-row></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons aria-label="Select page of periodic elements">
        </mat-paginator>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
