import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { InvoiceService } from '../../services/finance/invoice.service';
import { SumsComponent } from '../sections/sums/sums.component';
import { DatePipe, DecimalPipe, NgClass, NgIf } from '@angular/common';
import { ContainersComponent } from '../sections/containers/containers.component';
import { TextsComponent } from '../sections/texts/texts.component';
import { ProjectComponent } from '../sections/project/project.component';
import { CustomerComponent } from '../sections/customer/customer.component';
import { InvoiceDetailsComponent } from '../sections/invoice-details/invoice-details.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { concatMap } from 'rxjs';
import { Address } from '../../interfaces/address';
import { AddressesService } from '../../services/crm/addresses.service';
import { CompanyService } from '../../services/crm/company.service';
import { CalculateService } from '../../services/finance/calculate.service';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { FinancialService } from '../../services/finance/financial.service';

@Component({
  selector: 'financehub-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    InvoiceDetailsComponent,
    CustomerComponent,
    ProjectComponent,
    TextsComponent,
    ContainersComponent,
    NgClass,
    SumsComponent,
    DatePipe,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatExpansionModule,
    NgIf,
    MatToolbarModule,
    MatSnackBarModule,
  ],
  providers: [DecimalPipe],
})
export class InvoiceComponent implements OnInit {
  constructor(
    public invoiceService: InvoiceService,
    public financialService: FinancialService,
    private addressService: AddressesService,
    private _snackBar: MatSnackBar,
    public route: ActivatedRoute,
    public companyService: CompanyService,
    public calculateService: CalculateService,
    private decimalPipe: DecimalPipe,
  ) {}

  @ViewChild('scrollTarget', { read: ElementRef }) scrollTarget!: ElementRef;

  ngOnInit() {
    this.route.data.subscribe((params) => {
      this.financialService.isEdit = params['isEdit'];
    });

    if (this.financialService.isEdit) {
      this.getInvoice();
    }
  }

  getInvoice() {
    this.route.paramMap
      .pipe(
        concatMap((params) => {
          const invoiceId = params.get('id') || '';
          return this.invoiceService.getInvoice(invoiceId);
        }),
        concatMap(({ data, loading }) => {
          this.invoiceService.isEditInvoice = data.invoice;
          return this.addressService.getAddress(this.invoiceService.isEditInvoice.delivery_address_id);
        }),
        concatMap(({ data, loading }) => {
          this.financialService.isEditDeliveryAddress = data.address;
          return this.addressService.getAddress(this.invoiceService.isEditInvoice.invoice_address_id);
        }),
        concatMap(({ data, loading }) => {
          this.financialService.isEditInvoiceAddress = data.address;
          return this.companyService.getCompany(this.invoiceService.isEditInvoice.customer_id);
        }),
      )
      .subscribe(({ data, loading }) => {
        this.financialService.isEditCompany = data.company
          ? data.company
          : {
              name_company: '',
              uuid: '',
              bds_number: '',
              uid: '',
              company_register_number: '',
              delivery_addresses: [],
              invoice_addresses: [],
            };
        console.log('Rechnung:', this.invoiceService.isEditInvoice);
        console.log('Rechnungsaddresse:', this.financialService.isEditInvoiceAddress);
        console.log('Lieferaddresse:', this.financialService.isEditDeliveryAddress);
        console.log('Kunde:', this.financialService.isEditCompany);
        this.setFormValues();
      });
  }

  setFormValues() {
    this.financialService.detailsForm = this.invoiceService.setDetailsValues();
    const customer = this.financialService.detailsForm.get('customer')?.value;
    this.financialService.filteredInvoiceAddresses = [...customer.invoice_addresses];
    this.financialService.filteredInvoiceAddressesOriginal = customer.invoice_addresses;
    this.financialService.filteredDeliveryAddresses = [...customer.delivery_addresses];
    this.financialService.filteredDeliveryAddressesOriginal = customer.delivery_addresses;
    this.financialService.customerForm = this.financialService.setCustomerValues();
    this.financialService.projectForm = this.invoiceService.setProjectValues();
    this.financialService.textsForm = this.invoiceService.setTextsValues();
    this.setLineItemsValues();
    this.calculateService.calcSubtotal();
    this.calculateService.calcTaxes();
  }

  setLineItemsValues() {
    this.financialService.lineItems.controls.splice(0, 1);
    for (let i = 0; i < this.invoiceService.isEditInvoice.invoice_line_items.length; i++) {
      const item = this.invoiceService.isEditInvoice.invoice_line_items[i];
      const lineItemGroup = this.invoiceService.fb.group({
        _id: [item._id],
        title: [item.title, Validators.required],
        description: [item.description],
        quantity: [item.quantity, Validators.required],
        unitPrice: [item.unitPrice, Validators.required],
        taxPerc: [item.taxPerc, Validators.required],
        singleSum: [{ value: this.setSingleSum(item), disabled: true }, Validators.required],
      });
      this.financialService.lineItems.push(lineItemGroup);
    }
  }

  setSingleSum(item: any) {
    if (this.financialService.detailsForm.get('inputLocale')?.value == 'de-DE') {
      return this.decimalPipe.transform(item.quantity * item.unitPrice, '1.2-2', 'de-DE');
    } else {
      return this.decimalPipe.transform(item.quantity * item.unitPrice, '1.2-2', 'en-US');
    }
  }

  async submit() {
    if (
      this.financialService.detailsForm.valid &&
      this.financialService.customerForm.valid &&
      this.financialService.lineItemsForm.valid
    ) {
      if (this.financialService.isEdit) {
        this.invoiceService.updateInvoice(this.invoiceService.isEditInvoice._id).subscribe(({ data, loading }) => {
          this._snackBar.openFromComponent(SnackbarComponent, { data: { button: 'OK', message: 'Rechnung âktualisiert' } });
          this.financialService.invalidForm = false;
        });
      } else {
        this.invoiceService.saveInvoice().subscribe(({ data, loading }) => {
          this._snackBar.openFromComponent(SnackbarComponent, { data: { button: 'OK', message: 'Rechnung erstellt' } });
          this.invoiceService.resetForms();
          this.financialService.invalidForm = false;
        });
      }
    } else {
      this._snackBar.openFromComponent(SnackbarComponent, { data: { button: 'OK', message: 'Rechnung unvollständig' } });
      this.financialService.detailsForm.markAllAsTouched();
      this.financialService.customerForm.markAllAsTouched();
      this.financialService.lineItemsForm.markAllAsTouched();
      this.financialService.invalidForm = true;
    }
  }
}
