<div class="flex justify-center">
  <form
    class="mt-5 flex grid w-full grid-cols-1 flex-col items-start lg:w-4/5 lg:grid-cols-2 lg:gap-x-5"
    [formGroup]="personForm"
    (submit)="create()"
  >
    <financehub-input-field-template class="w-full" [formGroup]="personForm" controlName="first_name" label="Vorname">
    </financehub-input-field-template>

    <financehub-input-field-template class="w-full" [formGroup]="personForm" controlName="last_name" label="Nachname">
    </financehub-input-field-template>

    <financehub-input-field-template class="w-full" [readonly]="true" [formGroup]="personForm" controlName="uuid" label="UUID">
    </financehub-input-field-template>

    <financehub-input-field-template class="w-full" [formGroup]="personForm" controlName="bds_number" label="BDS-Nummer">
    </financehub-input-field-template>

    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Geburtstag</mat-label>
      <input [matDatepicker]="picker" matInput formControlName="birthday" readonly="true" />
      <mat-datepicker-toggle [for]="picker" matIconSuffix></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <financehub-input-field-template class="w-full" [formGroup]="personForm" controlName="phone_private" label="Handy privat">
    </financehub-input-field-template>

    <financehub-input-field-template class="w-full" [formGroup]="personForm" controlName="mail_private" label="Mail privat">
    </financehub-input-field-template>

    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Geschlecht</mat-label>
      <mat-select formControlName="gender" matInput>
        <mat-option *ngFor="let g of genders; let i = index" [value]="g">{{ g }}</mat-option>
      </mat-select>
    </mat-form-field>

    <financehub-input-field-template class="w-full" [formGroup]="personForm" controlName="fax" label="Fax">
    </financehub-input-field-template>

    <financehub-input-field-template class="w-full" [formGroup]="personForm" controlName="title_academic" label="Titel">
    </financehub-input-field-template>

    <mat-toolbar class="sticky bottom-0 !bg-white !bg-opacity-0 lg:col-span-2 lg:!px-0">
      <button class="w-full" mat-raised-button color="primary">Person anlegen</button>
    </mat-toolbar>
  </form>
</div>
