import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { Person } from '../../interfaces/person';
import { FormControl, FormGroup, Validators } from '@angular/forms';

const GET_PERSONS = gql`
  query persons {
    persons {
      first_name
      last_name
      uuid
      bds_number
      birthday
      phone_private
      mail_private
      fax
      gender
      title_academic
    }
  }
`;

const GET_PERSON = gql`
  query person($uuid: String!) {
    person(uuid: $uuid) {
      first_name
      last_name
      uuid
      bds_number
      birthday
      phone_private
      mail_private
      fax
      gender
      title_academic
    }
  }
`;

const CREATE_PERSON = gql`
  mutation CreatePerson($createPersonInput: CreatePersonInput) {
    createPerson(createPersonInput: $createPersonInput) {
      _id
    }
  }
`;

const UPDATE_PERSON = gql`
  mutation UpdatePerson($uuid: String!, $updatePersonInput: UpdatePersonInput) {
    updatePerson(uuid: $uuid, updatePersonInput: $updatePersonInput) {
      _id
    }
  }
`;

const DELETE_PERSON = gql`
  mutation DeletePerson($uuid: String!) {
    deletePerson(uuid: $uuid) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class PersonsService {
  constructor(private apollo: Apollo) {}

  personToCompanyForm!: FormGroup;
  availablePersons: Person[] = [];
  dialogCall = '';

  setPersonToCompanyForm() {
    this.personToCompanyForm = new FormGroup({
      selectedPerson: new FormControl('', Validators.required),
    });
  }

  getPersons(): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: GET_PERSONS,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  getPerson(uuid: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: GET_PERSON,
      variables: { uuid },
    }).valueChanges;
  }

  savePerson(person: Person): Observable<any> {
    const personInput = {
      first_name: person.first_name,
      last_name: person.last_name,
      bds_number: person.bds_number,
      uuid: person.uuid,
      birthday: person.birthday,
      phone_private: person.phone_private,
      mail_private: person.mail_private,
      fax: person.fax,
      gender: person.gender,
      title_academic: person.title_academic,
    };
    return this.apollo.mutate({
      mutation: CREATE_PERSON,
      variables: { createPersonInput: personInput },
      refetchQueries: [{ query: GET_PERSONS }],
    });
  }

  updatePerson(uuid: string, person: Person): Observable<any> {
    const personInput = {
      first_name: person.first_name,
      last_name: person.last_name,
      bds_number: person.bds_number,
      birthday: person.birthday,
      phone_private: person.phone_private,
      mail_private: person.mail_private,
      fax: person.fax,
      gender: person.gender,
      title_academic: person.title_academic,
    };
    return this.apollo.mutate({
      mutation: UPDATE_PERSON,
      variables: { uuid, updatePersonInput: personInput },
      refetchQueries: [{ query: GET_PERSONS }, { query: GET_PERSON, variables: { uuid } }],
    });
  }

  deletePerson(uuid: string): Observable<any> {
    return this.apollo.mutate({ mutation: DELETE_PERSON, variables: { uuid } });
  }
}
