import { Injectable, QueryList } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { Address } from '../../interfaces/address';
import { MatAutocomplete } from '@angular/material/autocomplete';
import SnowflakeId from 'snowflake-id';
import { MatTableDataSource } from '@angular/material/table';

@Injectable({
  providedIn: 'root',
})
export class FinancialService {
  constructor(
    public apollo: Apollo,
    public fb: FormBuilder,
  ) {}

  projects = [
    { title: 'Anker', coManager: 'test', number: 101, manager: 'Rene Heinzl' },
    { title: 'ohne', coManager: 'Rene Heinzl', number: 100, manager: 'Rene Heinzl' },
    { title: 'Recruiting', coManager: 'test', number: 102, manager: 'Markus Nissl' },
    { title: 'Financehub', coManager: 'test', number: 103, manager: 'Rene Heinzl' },
    { title: 'A1', coManager: 'test', number: 104, manager: 'Christoph Burger' },
    { title: 'Brantner', coManager: 'test', number: 105, manager: 'Rene Heinzl' },
  ];

  products = [
    {
      title: 'Softwareentwicklung',
      unitPrice: 1500,
      tax: 20,
      description: 'Gestaltung, Entwicklung und Implementierung von maßgeschneiderten Softwarelösungen.',
    },
    {
      title: 'Cybersicherheit',
      unitPrice: 50,
      tax: 10,
      description:
        'Sicherung von IT-Systemen und -Daten vor Bedrohungen, Angriffen und unbefugtem Zugriff. Dies umfasst Penetrationstests, Sicherheitsaudits, Implementierung von Firewalls, Antiviren-Software und Schulungen für Mitarbeiter.',
    },
    {
      title: 'Netzwerk- und Infrastruktur',
      unitPrice: 199.9,
      tax: 10,
      description:
        'Planung, Implementierung und Wartung von Netzwerken, Servern, Storage-Systemen und anderen IT-Infrastrukturen.',
    },
    {
      title: 'Datenbankmanagement und -optimierung',
      unitPrice: 500,
      tax: 20,
      description:
        'Verwaltung von Datenbanken, die Optimierung von Abfragen, die Datensicherung, die Wiederherstellung und die Skalierung, um sicherzustellen, dass Daten effizient gespeichert, verwaltet und abgerufen werden können.',
    },
    {
      title: 'IT-Beratung',
      unitPrice: 3500,
      tax: 15,
      description:
        'Fachwissen und Empfehlungen in Bezug auf Technologiestrategien, Infrastruktur, Prozessoptimierung und Technologieauswahl',
    },
    {
      title: 'Datenanalyse und Business Intelligence',
      unitPrice: 10,
      tax: 10,
      description: 'Sammlung, Verarbeitung und Analyse von Unternehmensdaten',
    },
    { title: 'Mobile App-Entwicklung', unitPrice: 2399.99, tax: 20, description: '' },
    {
      title: 'IT-Infrastrukturüberwachung und -wartung',
      unitPrice: 745.9,
      tax: 10,
      description:
        'kontinuierliche Überwachung, Wartung und Aktualisierung der IT-Infrastruktur gewährleisten, um Ausfallzeiten zu minimieren und die Leistung aufrechtzuerhalten',
    },
  ];

  textsBefore = [
    { title: 'leer', text: '' },
    {
      title: 'Variante 1',
      text: `Guten Tag!\n\nVielen Dank für Ihren Auftrag. Vereinbarungsgemäß berechnen wir Ihnen hiermit folgende Leistungen:`,
    },
    {
      title: 'Variante 2',
      text: `Sehr geehrte Damen und Herren!\n\nVielen Dank für Ihr Vertrauen in unsere Produkte.\nWir hoffen, Sie sind zufrieden und würden uns freuen, wieder von Ihnen zu hören.`,
    },
    {
      title: 'Variante 3',
      text: 'Wir bedanken uns für die gute Zusammenarbeit und stellen Ihnen vereinarungsgemäß folgende Lieferungen und Leistungen in Rechnung:',
    },
  ];

  textsAfter = [
    { title: 'leer', text: '' },
    { title: 'Variante 1', text: 'Bei Rückfragen stehe ich Ihnen wie gewohnt jederzeit gerne zur Verfügung!' },
    { title: 'Variante 2', text: 'Vielen Dank für Ihren Auftrag!' },
    { title: 'Variante 3', text: 'Ich danke Ihnen für die gute Zusammenarbeit!' },
  ];

  localsOutput = ['de-DE', 'en-EN'];
  localsInput = ['de-DE', 'en-EN'];
  invalidForm = false;
  detailsForm!: FormGroup;
  customerForm!: FormGroup;
  projectForm!: FormGroup;
  textsForm!: FormGroup;
  sumsForm!: FormGroup;
  taxes!: FormArray;
  lineItemsForm!: FormGroup;
  lineItems!: FormArray;
  allPanelsClosed = false;
  snowflake = new SnowflakeId({
    mid: 1,
    offset: 0,
  });
  filteredCompanies!: any[];
  filteredCompaniesOriginal!: any[];
  customerAuto!: MatAutocomplete;
  lineItemsAuto!: QueryList<MatAutocomplete>;
  filteredInvoiceAddresses: Array<Address> = [];
  filteredInvoiceAddressesOriginal: Array<Address> = [];
  filteredDeliveryAddresses: Array<Address> = [];
  filteredDeliveryAddressesOriginal: Array<Address> = [];
  filterValues: any = {};
  isEdit!: boolean;
  isEditDeliveryAddress: any;
  isEditInvoiceAddress: any;
  isEditCompany: any;

  setCustomerForm() {
    return new FormGroup({
      uuid: new FormControl({ value: '', disabled: true }, [Validators.required]),
      bds_number: new FormControl({ value: '', disabled: true }, [Validators.required]),
      uid: new FormControl('', [Validators.required]),
      company_register_number: new FormControl(''),
      invoice_search: new FormControl('', [Validators.required]),
      invoice_uuid: new FormControl({ value: '', disabled: true }, [Validators.required]),
      invoice_name: new FormControl({ value: '', disabled: true }, [Validators.required]),
      invoice_country: new FormControl({ value: '', disabled: true }, [Validators.required]),
      invoice_zip: new FormControl({ value: '', disabled: true }, [Validators.required]),
      invoice_city: new FormControl({ value: '', disabled: true }, [Validators.required]),
      invoice_street: new FormControl({ value: '', disabled: true }, [Validators.required]),
      invoice_house_number: new FormControl({ value: '', disabled: true }, [Validators.required]),
      delivery_search: new FormControl('', [Validators.required]),
      delivery_uuid: new FormControl({ value: '', disabled: true }, [Validators.required]),
      delivery_name: new FormControl({ value: '', disabled: true }, [Validators.required]),
      delivery_country: new FormControl({ value: '', disabled: true }, [Validators.required]),
      delivery_zip: new FormControl({ value: '', disabled: true }, [Validators.required]),
      delivery_city: new FormControl({ value: '', disabled: true }, [Validators.required]),
      delivery_street: new FormControl({ value: '', disabled: true }, [Validators.required]),
      delivery_house_number: new FormControl({ value: '', disabled: true }, [Validators.required]),
    });
  }

  setProjectForm() {
    return new FormGroup({
      search: new FormControl('ohne', [Validators.required]),
      title: new FormControl({ value: 'ohne', disabled: true }, [Validators.required]),
      number: new FormControl({ value: 100, disabled: true }, [Validators.required]),
      manager: new FormControl({ value: 'Rene Heinzl', disabled: true }, [Validators.required]),
      coManager: new FormControl({ value: 'Rene Heinzl', disabled: true }, [Validators.required]),
    });
  }

  setTextsForm() {
    return new FormGroup({
      textBeforeTitle: new FormControl('leer', [Validators.required]),
      textBefore: new FormControl(''),
      textAfterTitle: new FormControl('leer', [Validators.required]),
      textAfter: new FormControl(''),
    });
  }

  setSumsForm() {
    return this.fb.group({
      subtotal: new FormControl(''),
      taxes: this.fb.array([]),
      totalTaxes: new FormControl(''),
      totalSum: new FormControl(''),
    });
  }

  setLineItemsForm() {
    return this.fb.group({
      lineItems: this.fb.array([
        this.fb.group({
          title: ['', Validators.required],
          description: [''],
          quantity: ['', Validators.required],
          unitPrice: ['', Validators.required],
          taxPerc: ['', Validators.required],
          singleSum: [{ value: 'ohne', disabled: true }, Validators.required],
        }),
      ]),
    });
  }

  applyFilter(event: KeyboardEvent, dataSource: MatTableDataSource<any>) {
    if (event.key == 'Escape') {
      (event.target as HTMLInputElement).value = '';
      dataSource.filter = '';
    } else {
      const filterValue = (event.target as HTMLInputElement).value;
      dataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  deleteValue(value: string, dataSource: MatTableDataSource<any>) {
    dataSource.filter = '';
    this.filterValues[value] = '';
  }

  setInvoiceAddressValues(address: any) {
    this.customerForm.get('invoice_search')?.setValue(address?.name);
    this.customerForm.get('invoice_uuid')?.setValue(address?.uuid);
    this.customerForm.get('invoice_name')?.setValue(address?.name);
    this.customerForm.get('invoice_country')?.setValue(address?.country);
    this.customerForm.get('invoice_zip')?.setValue(address?.zip);
    this.customerForm.get('invoice_city')?.setValue(address?.city);
    this.customerForm.get('invoice_street')?.setValue(address?.street);
    this.customerForm.get('invoice_house_number')?.setValue(address?.house_number);
  }

  setDeliveryAddressValues(address: any) {
    this.customerForm.get('delivery_search')?.setValue(address?.name);
    this.customerForm.get('delivery_uuid')?.setValue(address?.uuid);
    this.customerForm.get('delivery_name')?.setValue(address?.name);
    this.customerForm.get('delivery_country')?.setValue(address?.country);
    this.customerForm.get('delivery_zip')?.setValue(address?.zip);
    this.customerForm.get('delivery_city')?.setValue(address?.city);
    this.customerForm.get('delivery_street')?.setValue(address?.street);
    this.customerForm.get('delivery_house_number')?.setValue(address?.house_number);
  }

  setCustomerValues() {
    return new FormGroup({
      uuid: new FormControl({ value: this.isEditCompany.uuid, disabled: true }, [Validators.required]),
      bds_number: new FormControl({ value: this.isEditCompany.bds_number, disabled: true }, [Validators.required]),
      uid: new FormControl(this.isEditCompany.uid, [Validators.required]),
      company_register_number: new FormControl(this.isEditCompany.company_register_number),
      invoice_search: new FormControl(this.isEditInvoiceAddress.name, [Validators.required]),
      invoice_uuid: new FormControl({ value: this.isEditInvoiceAddress.uuid, disabled: true }, [Validators.required]),
      invoice_name: new FormControl({ value: this.isEditInvoiceAddress.name, disabled: true }, [Validators.required]),
      invoice_country: new FormControl({ value: this.isEditInvoiceAddress.country, disabled: true }, [Validators.required]),
      invoice_zip: new FormControl({ value: this.isEditInvoiceAddress.zip, disabled: true }, [Validators.required]),
      invoice_city: new FormControl({ value: this.isEditInvoiceAddress.city, disabled: true }, [Validators.required]),
      invoice_street: new FormControl({ value: this.isEditInvoiceAddress.street, disabled: true }, [Validators.required]),
      invoice_house_number: new FormControl({ value: this.isEditInvoiceAddress.house_number, disabled: true }, [
        Validators.required,
      ]),
      delivery_search: new FormControl(this.isEditDeliveryAddress.name, [Validators.required]),
      delivery_uuid: new FormControl({ value: this.isEditDeliveryAddress.uuid, disabled: true }, [Validators.required]),
      delivery_name: new FormControl({ value: this.isEditDeliveryAddress.name, disabled: true }, [Validators.required]),
      delivery_country: new FormControl({ value: this.isEditDeliveryAddress.country, disabled: true }, [Validators.required]),
      delivery_zip: new FormControl({ value: this.isEditDeliveryAddress.zip, disabled: true }, [Validators.required]),
      delivery_city: new FormControl({ value: this.isEditDeliveryAddress.city, disabled: true }, [Validators.required]),
      delivery_street: new FormControl({ value: this.isEditDeliveryAddress.street, disabled: true }, [Validators.required]),
      delivery_house_number: new FormControl({ value: this.isEditDeliveryAddress.house_number, disabled: true }, [
        Validators.required,
      ]),
    });
  }

  resetForms() {
    this.customerAuto.options.forEach((option) => option.deselect());
    this.customerForm.reset();
    this.projectForm = this.setProjectForm();
    this.textsForm = this.setTextsForm();
    this.lineItemsAuto.forEach((item) => {
      item.options.forEach((option) => option.deselect());
    });
    this.lineItemsForm.reset();
    while (this.lineItems.length > 1) {
      this.lineItems.removeAt(1);
    }
    this.sumsForm.reset();
  }

  getInputs() {
    return {
      title: this.projectForm.get('title')?.value,
      input_locale: this.detailsForm.get('inputLocale')?.value,
      output_locale: this.detailsForm.get('outputLocale')?.value,
      text_before: this.textsForm.get('textBefore')?.value,
      text_after: this.textsForm.get('textAfter')?.value,
      subtotal: this.sumsForm.get('subtotal')?.value,
      total_taxes: this.sumsForm.get('totalTaxes')?.value,
      customer_id: this.customerForm.get('uuid')?.value,
      customer_uid: this.customerForm.get('uid')?.value,
      project_title: this.projectForm.get('title')?.value,
      project_number: this.projectForm.get('number')?.value,
      project_manager: this.projectForm.get('manager')?.value,
      project_co_manager: this.projectForm.get('coManager')?.value,
      customer_company_register_number: this.customerForm.get('company_register_number')?.value,
      delivery_address_id: this.customerForm.get('delivery_uuid')?.value,
      invoice_address_id: this.customerForm.get('invoice_uuid')?.value,
    };
  }
}
