import { AfterViewInit, ChangeDetectorRef, Component, Inject, ViewChild, inject } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AsyncPipe, CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { KeycloakProfile } from 'keycloak-js';
import { GLOBAL_RX_STATE, GlobalState } from '../global-state';
import { RxState } from '@rx-angular/state';
import { KeycloakService } from 'keycloak-angular';
import { getInitials } from '../utils/common';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { NavigationTemplateComponent } from '../navigation-template/navigation-template.component';
import { InvoiceService } from '../services/finance/invoice.service';

@Component({
  selector: 'financehub-bds421-com-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    AsyncPipe,
    MatMenuModule,
    RouterLink,
    NavigationTemplateComponent,
  ],
})
export class NavigationComponent {
  public isLoggedIn = false;
  public userProfile: KeycloakProfile | null = null;
  private breakpointObserver = inject(BreakpointObserver);
  currentRoute: string = '';
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay(),
  );

  constructor(
    @Inject(GLOBAL_RX_STATE) private state: RxState<GlobalState>,
    private readonly keycloak: KeycloakService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public invoiceService: InvoiceService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });
  }

  ngOnInit() {
    this.refreshAuthState();
  }

  get usercode(): string {
    if (this.isLoggedIn) {
      if (
        this.userProfile == null ||
        this.userProfile.firstName == null ||
        this.userProfile.lastName == null ||
        this.userProfile.firstName.length == 0 ||
        this.userProfile.lastName.length == 0
      ) {
        return 'AN';
      }
      return getInitials(this.userProfile.firstName + ' ' + this.userProfile.lastName.substring(0, 1));
    } else {
      return 'AN';
    }
  }

  public login() {
    this.keycloak.login();
  }

  public logout() {
    this.keycloak.logout();
  }

  public async refreshAuthState() {
    this.isLoggedIn = await this.keycloak.isLoggedIn();

    if (this.isLoggedIn) {
      this.userProfile = await this.keycloak.loadUserProfile();
      this.cdr.detectChanges();
    }
  }
}
