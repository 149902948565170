import { Injectable } from '@angular/core';
import { InvoiceService } from './invoice.service';
import * as math from 'mathjs';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { FinancialService } from './financial.service';

@Injectable({
  providedIn: 'root',
})
export class CalculateService {
  constructor(
    private invoiceService: InvoiceService,
    private fb: FormBuilder,
    public financialService: FinancialService,
  ) {}

  calcSums(i: number, field?: string) {
    if (field) {
      this.changeInput(i, field);
    }
    this.calcSingleSum(i);
    this.calcSubtotal();
    this.calcTaxes();
  }

  changeInput(i: number, field: string) {
    const item = this.financialService.lineItems.at(i).get(field);
    if (this.financialService.detailsForm.get('inputLocale')?.value == 'de-DE') {
      item?.setValue(item?.value.replace(/\./g, ''));
    } else if (this.financialService.detailsForm.get('inputLocale')?.value == 'en-EN') {
      item?.setValue(item?.value.replace(/\,/g, ''));
    }
  }

  calcSingleSum(i: number) {
    const unitPrice = this.financialService.lineItems.at(i).get('unitPrice')?.value;
    const quantity = this.financialService.lineItems.at(i).get('quantity')?.value;
    if (this.financialService.detailsForm.get('inputLocale')?.value == 'de-DE') {
      this.setSingleSumGerman(unitPrice, quantity, i);
    } else if (this.financialService.detailsForm.get('inputLocale')?.value == 'en-EN') {
      this.setSingleSumEnglish(unitPrice, quantity, i);
    }
  }

  setSingleSumGerman(unitPrice: string, quantity: string, i: number) {
    let unitPriceEN = unitPrice?.toString().replace(',', '.');
    let quantityEN = quantity?.toString().replace(',', '.');
    if (unitPriceEN.includes('/')) {
      const unitPriceParts = unitPriceEN.split('/');
      const beforeSlash = parseFloat(unitPriceParts[0].trim());
      const afterSlash = parseFloat(unitPriceParts[1].trim());
      unitPriceEN = math.evaluate(`${beforeSlash}/${afterSlash}`);
    }
    const singleSum = +unitPriceEN * +quantityEN;
    this.financialService.lineItems.at(i).get('singleSum')?.setValue(singleSum.toFixed(2).replace('.', ','));
  }

  setSingleSumEnglish(unitPrice: string, quantity: string, i: number) {
    let unitPriceEN = unitPrice;
    let quantityEN = quantity;
    if (unitPriceEN.includes('/')) {
      const unitPriceParts = unitPriceEN.split('/');
      const beforeSlash = parseFloat(unitPriceParts[0].trim());
      const afterSlash = parseFloat(unitPriceParts[1].trim());
      unitPriceEN = math.evaluate(`${beforeSlash}/${afterSlash}`);
    }
    const singleSum = +unitPriceEN * +quantityEN;
    this.financialService.lineItems.at(i).get('singleSum')?.setValue(singleSum.toFixed(2));
  }

  calcSubtotal() {
    let totalSum: number = 0;
    for (let i = 0; i < this.financialService.lineItems.length; i++) {
      let singleSum;
      if (this.financialService.isEdit && this.financialService.detailsForm.get('inputLocale')?.value == 'de-DE') {
        this.financialService.lineItems
          .at(i)
          .get('singleSum')
          ?.setValue(this.financialService.lineItems.at(i).get('singleSum')?.value.replace(/\./g, ''));
      } else if (this.financialService.isEdit && this.financialService.detailsForm.get('inputLocale')?.value == 'en-EN') {
        this.financialService.lineItems
          .at(i)
          .get('singleSum')
          ?.setValue(this.financialService.lineItems.at(i).get('singleSum')?.value.replace(/\,/g, ''));
      }
      singleSum = this.financialService.lineItems.at(i).get('singleSum')?.value.toString().replace(',', '.');
      totalSum += +singleSum;
    }
    this.financialService.sumsForm.get('subtotal')?.setValue(totalSum);
  }

  calcTaxes() {
    this.financialService.taxes.clear();
    for (let i = 0; i < this.financialService.lineItems.length; i++) {
      const taxPercNew = this.financialService.lineItems.at(i).get('taxPerc')?.value.toString().replace(',', '.');
      const singleSumNew = this.financialService.lineItems.at(i).get('singleSum')?.value.toString().replace(',', '.');
      const taxEuroNew = (taxPercNew / 100) * singleSumNew;
      let taxGroupExists = this.checkExistingTaxes(taxPercNew);
      if (taxGroupExists !== -1) {
        this.updateOldTax(taxGroupExists, taxEuroNew);
      } else {
        this.createNewTax(taxPercNew, taxEuroNew);
      }
    }
    this.calcTotalTaxes();
  }

  checkExistingTaxes(taxPercNew: string) {
    for (let i = 0; i < this.financialService.taxes.length; i++) {
      if (this.financialService.taxes.at(i).get('taxPerc')?.value == taxPercNew) {
        return i;
      }
    }
    return -1;
  }

  updateOldTax(taxGroupExists: number, taxEuroNew: number) {
    let existingTaxGroup = this.financialService.taxes.at(taxGroupExists);
    let taxEuroOld = existingTaxGroup.get('taxEuro')?.value;
    existingTaxGroup.get('taxEuro')?.setValue(+taxEuroOld + +taxEuroNew);
  }

  createNewTax(taxPercNew: number, taxEuroNew: number) {
    const taxGroup = this.fb.group({
      taxPerc: [taxPercNew],
      taxEuro: [taxEuroNew],
    });
    this.financialService.taxes.push(taxGroup);
  }

  calcTotalTaxes() {
    let sum = 0;
    for (let i = 0; i < this.financialService.taxes.length; i++) {
      const taxEuro = this.financialService.taxes.at(i).get('taxEuro')?.value;
      sum += +taxEuro;
    }
    this.financialService.sumsForm.get('totalTaxes')?.setValue(sum);
    this.calcTotalSum();
  }

  calcTotalSum() {
    const subtotal = this.financialService.sumsForm.get('subtotal')?.value;
    const totalTaxes = this.financialService.sumsForm.get('totalTaxes')?.value;
    let totalSum = subtotal + totalTaxes;
    this.financialService.sumsForm.get('totalSum')?.setValue(totalSum);
  }
}
