<div class="flex justify-center">
  <div class="w-full lg:w-4/5">
    <div class="my-5 flex items-center justify-between px-5 lg:px-0">
      <mat-form-field class="filter-without-wrapper mr-3 w-3/5 lg:w-2/5" appearance="outline">
        <mat-label>Filter</mat-label>
        <input #input [(ngModel)]="valueFilter" (keyup)="applyFilter($event)" matInput />
        <mat-icon class="cursor-pointer !p-1" *ngIf="valueFilter" (click)="deleteFilter()" matSuffix>close</mat-icon>
      </mat-form-field>
      <button class="w-2/5" [routerLink]="'../person/create'" mat-raised-button color="primary"><mat-icon>add</mat-icon>Person</button>
    </div>

    <div class="mat-elevation-z8 mx-5 mb-5 overflow-x-auto lg:mx-0">
      <table [dataSource]="dataSource" mat-table matSort>
        <ng-container matColumnDef="bds_number">
          <th class="w-4/12" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by number">bds-Nr.</th>
          <td class="relative w-4/12" *matCellDef="let element" mat-cell>{{ element.bds_number }}</td>
        </ng-container>
        <ng-container matColumnDef="last_name">
          <th class="w-8/12 sm:w-7/12" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by lastname">
            Name
          </th>
          <td class="w-8/12 sm:w-7/12" *matCellDef="let element" mat-cell>{{ element.last_name }} {{ element.first_name }}</td>
        </ng-container>
        <ng-container matColumnDef="icons">
          <th *matHeaderCellDef mat-header-cell></th>
          <td *matCellDef="let element" mat-cell>
            <div class="flex flex-col justify-end xs:flex-row">
              <mat-icon class="mr-3 cursor-pointer" [routerLink]="'../person/' + element.uuid">edit</mat-icon>
            </div>
          </td>
        </ng-container>
        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr *matRowDef="let row; let even = even; columns: displayedColumns" [ngClass]="{ '!bg-gray-200': even }" mat-row></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[3, 5, 10]" showFirstLastButtons aria-label="Select page of periodic elements">
      </mat-paginator>
    </div>
  </div>

  <div
    class="absolute bottom-0 left-0 right-0 top-0 flex flex-col items-center justify-center bg-overlay text-gray-100"
    *ngIf="showOverlay"
    (click)="showOverlay = false"
  >
    <span class="mb-2 text-xl sm:text-2xl">Person löschen?</span>
    <div class="flex">
      <button class="mx-4 bg-bds-blue" (click)="deletePerson(deleteUUID)">Ja</button>
      <button class="mx-4 bg-bds-blue" (click)="showOverlay = false">Nein</button>
    </div>
  </div>
</div>
