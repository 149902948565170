import { Component, Inject } from '@angular/core';
import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { NgForOf, NgIf } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { AddressesService } from 'src/app/services/crm/addresses.service';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PersonsService } from 'src/app/services/crm/persons.service';

@Component({
  selector: 'financehub-dialog',
  templateUrl: './dialog.component.html',
  standalone: true,
  imports: [
    FormsModule,
    MatButtonModule,
    NgIf,
    MatFormFieldModule,
    MatSelectModule,
    NgForOf,
    MatIconModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule,
  ],
})
export class DialogComponent {
  constructor(
    public addressesService: AddressesService,
    public personsService: PersonsService,
    public dialogRef: DialogRef<string>,
    @Inject(DIALOG_DATA) public data: any,
  ) {
    this.addressesService.setAddressToCompanyForm();
    this.personsService.setPersonToCompanyForm();
  }
}
