import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';

const GET_TIME_TRACKING_TEMPLATES = gql`
  query timeTrackingTemplates {
    timeTrackingTemplates {
      name
      hours_amount
      type
      days {
        day_index
        start
        end
        break
      }
    }
  }
`

const CREATE_TIME_TRACKING_TEMPLATE = gql`
  mutation createTimeTrackingTemplate ($createInput: TimeTrackingInput) {
    createTimeTrackingTemplate(createInput: $createInput) {
      name
    }
  }
`

@Injectable({
  providedIn: 'root'
})
export class TimeTrackingTemplateService {

  constructor(private apollo: Apollo) { }

  getTimeTrackingTemplates(): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: GET_TIME_TRACKING_TEMPLATES,
      })
      .valueChanges;
  }

  saveTimeTrackingTemplate(input: any) {
    return this.apollo.mutate({ 
      mutation: CREATE_TIME_TRACKING_TEMPLATE, 
      variables: { createInput: input },
    });
  }

}
