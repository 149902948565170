<form #formProject="ngForm">
  <div class="flex justify-center">
    <form
      class="flex grid w-full grid-cols-1 flex-col items-start px-5 lg:grid-cols-2 lg:gap-x-5"
      [formGroup]="financialService.projectForm"
    >
      <mat-form-field class="w-full" appearance="outline">
        <mat-label>Suche</mat-label>
        <input [matAutocomplete]="projectAuto" (keyup)="onProjectChange($event)" formControlName="search" matInput />
        <mat-error class="text-end" *ngIf="financialService.projectForm.get('search')?.errors?.['required']"
          >Pflichtfeld</mat-error
        >
        <mat-autocomplete #projectAuto="matAutocomplete" (optionSelected)="onProjectSelected($event)" panelWidth="auto">
          <mat-option *ngFor="let p of filteredProjects" [value]="p">{{ p.title }}</mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <financehub-input-field-template
        [formGroup]="financialService.projectForm"
        [readonly]="true"
        label="Name"
        controlName="title"
      ></financehub-input-field-template>

      <financehub-input-field-template
        [formGroup]="financialService.projectForm"
        [readonly]="true"
        label="Projektnummer"
        controlName="number"
      ></financehub-input-field-template>

      <financehub-input-field-template
        [formGroup]="financialService.projectForm"
        [readonly]="true"
        label="Manager 1"
        controlName="manager"
      ></financehub-input-field-template>

      <financehub-input-field-template
        [formGroup]="financialService.projectForm"
        [readonly]="true"
        label="Manager 2"
        controlName="coManager"
      ></financehub-input-field-template>
    </form>
  </div>
</form>
