<a
  class="flex border-l-8 border-r-8"
  [ngClass]="{
    '!bg-[#E0E0E0]': route.toLowerCase().includes(routeIncludes)
  }"
  [class]="borderColor"
  [routerLink]="link"
  mat-list-item
>
  <div *ngIf="route.toLowerCase().includes(routeIncludes) || route.toLowerCase() == routeIs">
    <hr class="absolute left-0 top-[20px] h-2 w-4 border-0" [class]="bgColor" />
    <div class="absolute left-3 top-[8px] h-8 w-8 rounded-full" [class]="bgColor"></div>
  </div>

  <mat-icon class="z-20" *ngIf="route.toLowerCase().includes(routeIncludes) || route.toLowerCase() == routeIs" matListItemIcon>
    <img [src]="'../../assets/icons/' + iconWhite + '.png'" alt="Your Custom Icon" />
  </mat-icon>
  <mat-icon class="z-20" *ngIf="!route.toLowerCase().includes(routeIncludes) && route.toLowerCase() !== routeIs" matListItemIcon>
    <img [src]="'../../assets/icons/' + icon + '.png'" alt="Your Custom Icon" />
  </mat-icon>

  <div matListItemTitle matHint>{{ title }}</div>
</a>
