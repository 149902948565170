import { Route } from '@angular/router';
import { AuthGuard } from './guards/auth-guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AddressesComponent } from './crm/addresses/addresses.component';
import { NewAddressComponent } from './crm/addresses/new-address/new-address.component';
import { PersonsComponent } from './crm/persons/persons.component';
import { NewPersonComponent } from './crm/persons/new-person/new-person.component';
import { ShowPersonComponent } from './crm/persons/show-person/show-person.component';
import { ShowAddressComponent } from './crm/addresses/show-address/show-address.component';
import { CompaniesComponent } from './crm/companies/companies.component';
import { NewCompanyComponent } from './crm/companies/new-company/new-company.component';
import { ShowCompanyComponent } from './crm/companies/show-company/show-company.component';
import { InvoicesComponent } from './finance/invoices/invoices.component';
import { InvoiceComponent } from './finance/invoice/invoice.component';
import { TimeTrackingComponent } from './time-tracking/time-tracking.component';
import { OrbDemoComponent } from './orb-demo/orb-demo.component';
import { MemgraphOrbComponent } from './orb-demo/memgraph-orb.component';
import { OffersComponent } from './finance/offers/offers.component';
import { OfferComponent } from './finance/offer/offer.component';

/*
    /invoices/ -> alle invoices angezeigt werden
    /invoices/{id} -> eine einzelne Invoice
    /invoice/create -> eine neue Erstellen
    /invoice/{id}/edit -> eine bestehende bearbeiten
 */

export const appRoutes: Route[] = [
  // { path: 'invoices', component: InvoicesComponent, canActivate: [AuthGuard], children: [] },
  { path: 'invoices', component: InvoicesComponent, canActivate: [AuthGuard], children: [] },
  { path: 'invoice/create', component: InvoiceComponent, canActivate: [AuthGuard], children: [], data: { isEdit: false } },
  {
    path: 'invoice/:id',
    component: InvoiceComponent,
    canActivate: [AuthGuard],
    children: [],
    data: { isEdit: true },
  },

  { path: 'offers', component: OffersComponent, canActivate: [AuthGuard], children: [] },
  { path: 'offer/create', component: OfferComponent, canActivate: [AuthGuard], children: [], data: { isEdit: false } },
  {
    path: 'offer/:id',
    component: OfferComponent,
    canActivate: [AuthGuard],
    children: [],
    data: { isEdit: true },
  },

  { path: 'addresses', component: AddressesComponent, canActivate: [AuthGuard], children: [] },
  { path: 'address/create', component: NewAddressComponent, canActivate: [AuthGuard] },
  { path: 'address/:uuid', component: ShowAddressComponent, canActivate: [AuthGuard] },

  { path: 'persons', component: PersonsComponent, canActivate: [AuthGuard], children: [] },
  { path: 'person/create', component: NewPersonComponent, canActivate: [AuthGuard], children: [] },
  { path: 'person/:uuid', component: ShowPersonComponent, canActivate: [AuthGuard], children: [] },

  { path: 'companies', component: CompaniesComponent, canActivate: [AuthGuard], children: [] },
  { path: 'company/create', component: NewCompanyComponent, canActivate: [AuthGuard], children: [] },
  { path: 'company/:uuid', component: ShowCompanyComponent, canActivate: [AuthGuard], children: [] },

  { path: 'timeTracking', component: TimeTrackingComponent, canActivate: [AuthGuard], children: [] },

  { path: 'graph', component: OrbDemoComponent, canActivate: [AuthGuard], children: [] },
  { path: 'memgraph-graph', component: MemgraphOrbComponent, canActivate: [AuthGuard], children: [] },

  { path: '', component: DashboardComponent, canActivate: [AuthGuard] },
];
