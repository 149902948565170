<mat-tab-group #matTabGroup>
  <mat-tab label="1 - Details">
    <div class="my-5 flex w-full justify-end px-5 lg:w-4/5 lg:px-0">
      <button *ngIf="!editPersonActive" (click)="editPerson(); $event.stopPropagation()" mat-raised-button color="primary">
        <mat-icon> edit </mat-icon> bearbeiten
      </button>
      <button *ngIf="editPersonActive" (click)="updatePerson()" mat-raised-button color="primary">
        <mat-icon> done </mat-icon> speichern
      </button>
    </div>

    <div class="flex w-full justify-center">
      <form
        class="flex grid w-full grid-cols-1 flex-col items-start px-5 lg:w-4/5 lg:grid-cols-2 lg:gap-x-5 lg:px-0"
        [formGroup]="personForm"
      >
        <financehub-input-field-template
          class="w-full"
          [formGroup]="personForm"
          controlName="first_name"
          label="Vorname"
        ></financehub-input-field-template>

        <financehub-input-field-template
          class="w-full"
          [formGroup]="personForm"
          controlName="last_name"
          label="Nachname"
        ></financehub-input-field-template>

        <financehub-input-field-template
          class="w-full"
          [formGroup]="personForm"
          controlName="title_academic"
          label="Titel"
        ></financehub-input-field-template>

        <financehub-input-field-template
          class="w-full"
          [formGroup]="personForm"
          controlName="uuid"
          label="UUID"
        ></financehub-input-field-template>

        <financehub-input-field-template
          class="w-full"
          [formGroup]="personForm"
          controlName="bds_number"
          label="BDS-Nummer"
        ></financehub-input-field-template>

        <mat-form-field class="w-full" appearance="outline">
          <mat-label>Geburtstag</mat-label>
          <input [matDatepicker]="picker" matInput formControlName="birthday" readonly="true" />
          <mat-datepicker-toggle [for]="picker" matIconSuffix></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <financehub-input-field-template
          class="w-full"
          [formGroup]="personForm"
          [optionsArray]="genders"
          controlName="gender"
          label="Geschlecht"
          type="select"
        ></financehub-input-field-template>

        <financehub-input-field-template
          class="w-full"
          [formGroup]="personForm"
          controlName="mail_private"
          label="Email privat"
        ></financehub-input-field-template>

        <financehub-input-field-template
          class="w-full"
          [formGroup]="personForm"
          controlName="phone_private"
          label="Handy privat"
        ></financehub-input-field-template>

        <financehub-input-field-template
          class="w-full"
          [formGroup]="personForm"
          controlName="fax"
          label="FAX"
        ></financehub-input-field-template>
      </form>
    </div>
  </mat-tab>

  <mat-tab [label]="getAddressesLabel()">
    <div class="w-full lg:w-4/5">
      <div class="my-5 flex w-full justify-between px-5 lg:px-0">
        <mat-form-field class="filter-without-wrapper w-2/5 lg:w-2/5" appearance="outline">
          <mat-label>Filter</mat-label>
          <input #input0 [(ngModel)]="valueFilterAdresses" (keyup)="applyFilter($event, dataSourceAddresses)" matInput />
          <mat-icon
            class="cursor-pointer !p-1"
            *ngIf="valueFilterAdresses"
            (click)="deleteValue('addresses', dataSourceAddresses)"
            matSuffix
            >close</mat-icon
          >
        </mat-form-field>
        <div class="hidden sm:flex">
          <button class="mx-2" (click)="addAddressOverlay = true; $event.stopPropagation()" mat-raised-button color="primary">
            <mat-icon> add </mat-icon>Adresse
          </button>
          <button *ngIf="!editAddressesActive" (click)="editAddresses()" mat-raised-button color="primary">
            <mat-icon> edit </mat-icon>bearbeiten
          </button>
          <button *ngIf="editAddressesActive" (click)="updateAddresses()" mat-raised-button color="primary">
            <mat-icon> check </mat-icon>speichern
          </button>
        </div>
      </div>

      <div class="mat-elevation-z8 mx-5 mb-5 overflow-x-auto lg:mx-0">
        <table #addresses [dataSource]="dataSourceAddresses" mat-table matSort>
          <ng-container matColumnDef="bdsNumber">
            <th class="!pl-5 !pr-2" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by number">
              bds-Nr.
            </th>
            <td class="!pl-5 !pr-2" *matCellDef="let element; let even = even; let i = index" mat-cell></td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th class="!px-2" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by name">Adresse</th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              <span *ngIf="!editAddressesActive">{{ element.name }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="country">
            <th class="!px-2" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by country">Land</th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              <span *ngIf="!editAddressesActive">{{ element.country }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="zip">
            <th class="!px-2" *matHeaderCellDef mat-header-cell>PLZ</th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              <span *ngIf="!editAddressesActive">{{ element.zip }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="city">
            <th class="!px-2" *matHeaderCellDef mat-header-cell>Stadt</th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              <span *ngIf="!editAddressesActive">{{ element.city }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="street">
            <th class="!px-2" *matHeaderCellDef mat-header-cell>Straße</th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              <span *ngIf="!editAddressesActive">{{ element.street }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="house_number">
            <th class="!px-2" *matHeaderCellDef mat-header-cell>Nr.</th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              <span *ngIf="!editAddressesActive">{{ element.number }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="primary">
            <th class="!px-2" *matHeaderCellDef mat-header-cell>HWS</th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              <mat-icon *ngIf="element.primary && !editAddressesActive">done</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="secondary">
            <th class="!pl-2 !pr-5" *matHeaderCellDef mat-header-cell>NWS</th>
            <td class="!pl-2 !pr-5" *matCellDef="let element; let even = even" mat-cell>
              <mat-icon *ngIf="element.secondary && !editAddressesActive">done</mat-icon>
            </td>
          </ng-container>

          <tr *matHeaderRowDef="displayedColumnsAddresses" mat-header-row></tr>
          <tr
            *matRowDef="let row; let even = even; columns: displayedColumnsAddresses"
            [ngClass]="{ '!bg-gray-200': even }"
            mat-row
          ></tr>
        </table>
      </div>
      <div class="my-5 flex justify-end px-5 lg:px-0">
        <div class="flex sm:hidden">
          <button class="mr-3" (click)="addAddressOverlay = true; $event.stopPropagation()" mat-mini-fab color="primary">
            <mat-icon> add </mat-icon>
          </button>
          <button *ngIf="!editAddressesActive" (click)="editAddresses(); $event.stopPropagation()" mat-mini-fab color="primary">
            <mat-icon> edit </mat-icon>
          </button>
          <button *ngIf="editAddressesActive" (click)="updateAddresses(); $event.stopPropagation()" mat-mini-fab color="primary">
            <mat-icon> check </mat-icon>
          </button>
        </div>
      </div>
    </div>
  </mat-tab>

  <mat-tab [label]="getCompaniesLabel()">
    <div class="sticky top-0 my-5 flex w-full justify-between px-5 lg:w-4/5 lg:px-0">
      <mat-form-field class="filter-without-wrapper w-2/5 lg:w-2/5" appearance="outline">
        <mat-label>Filter</mat-label>
        <input #input0 [(ngModel)]="valueFilterCompanies" (keyup)="applyFilter($event, dataSourceCompanies)" matInput />
        <mat-icon
          class="cursor-pointer !p-1"
          *ngIf="valueFilterCompanies"
          (click)="deleteValue('persons', dataSourceCompanies)"
          matSuffix
          >close</mat-icon
        >
      </mat-form-field>
      <div class="hidden sm:flex">
        <button class="mx-2" (click)="addCompanyOverlay = true; $event.stopPropagation()" mat-raised-button color="primary">
          <mat-icon> add </mat-icon>Person
        </button>
        <button *ngIf="!editCompaniesActive" (click)="editCompanies()" mat-raised-button color="primary">
          <mat-icon> edit </mat-icon>bearbeiten
        </button>
        <button *ngIf="editCompaniesActive" (click)="updateCompanies()" mat-raised-button color="primary">
          <mat-icon> check </mat-icon>speichern
        </button>
      </div>
    </div>

    <div class="w-full lg:w-4/5">
      <div class="mat-elevation-z8 mx-5 mb-5 overflow-x-auto lg:mx-0">
        <table #companies [dataSource]="dataSourceCompanies" mat-table matSort>
          <ng-container matColumnDef="bdsNumber">
            <th class="!pl-5 !pr-2" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by number">
              bds-Nr.
            </th>
            <td class="!pl-5 !pr-2" *matCellDef="let element; let even = even; let i = index" mat-cell></td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th class="!px-2" *matHeaderCellDef mat-header-cell>Name</th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              <span *ngIf="!editCompaniesActive">{{ element.name }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="position">
            <th class="!px-2" *matHeaderCellDef mat-header-cell>Position</th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              <span *ngIf="!editCompaniesActive">{{ element.position }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="phoneOffice">
            <th class="!px-2" *matHeaderCellDef mat-header-cell>Handy Firma</th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              <span *ngIf="!editCompaniesActive">{{ element.phoneOffice }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="mailOffice">
            <th class="!px-2" *matHeaderCellDef mat-header-cell>Email Firma</th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              <span *ngIf="!editCompaniesActive">{{ element.mailOffice }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="info">
            <th class="!px-2" *matHeaderCellDef mat-header-cell>Info</th>
            <td class="!pl-2 !pr-5" *matCellDef="let element; let even = even" mat-cell>
              <span *ngIf="!editCompaniesActive">{{ element.info }}</span>
            </td>
          </ng-container>

          <tr *matHeaderRowDef="displayedColumnsCompanies" mat-header-row></tr>
          <tr
            *matRowDef="let row; let even = even; columns: displayedColumnsCompanies"
            [ngClass]="{ '!bg-gray-200': even }"
            mat-row
          ></tr>
        </table>
      </div>
      <div class="my-5 flex justify-end px-5 lg:px-0">
        <div class="flex sm:hidden">
          <button class="mx-2" (click)="addCompanyOverlay = true; $event.stopPropagation()" mat-mini-fab color="primary">
            <mat-icon> add </mat-icon>
          </button>
          <button *ngIf="!editCompaniesActive" mat-mini-fab color="primary"><mat-icon> edit </mat-icon></button>
          <button *ngIf="editCompaniesActive" mat-mini-fab color="primary"><mat-icon> check </mat-icon></button>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
