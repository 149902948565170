import { Component, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteModule } from '@angular/material/autocomplete';
import { InvoiceService } from 'src/app/services/finance/invoice.service';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { MatExpansionModule, MatExpansionPanel } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { InputFieldTemplateComponent } from '../../input-field-template/input-field-template.component';
import { MatInputModule } from '@angular/material/input';
import { FinancialService } from 'src/app/services/finance/financial.service';
import { OfferService } from 'src/app/services/finance/offer.service';

@Component({
  selector: 'financehub-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    MatExpansionModule,
    MatAutocompleteModule,
    NgFor,
    MatOptionModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    InputFieldTemplateComponent,
    NgIf,
    MatInputModule,
  ],
})
export class CustomerComponent {
  @ViewChild(MatExpansionPanel) panel!: MatExpansionPanel;

  constructor(
    public invoiceService: InvoiceService,
    public financialService: FinancialService,
    public offerService: OfferService,
  ) {
    this.financialService.customerForm = this.financialService.setCustomerForm();
  }

  onInvoiceAddressChange(event: KeyboardEvent) {
    let inputValue = '';
    if (event.key == 'Escape') {
      (event.target as HTMLInputElement).value = '';
      this.financialService.customerForm.get('invoice_search')?.setValue('');
    }
    inputValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.financialService.filteredInvoiceAddresses = this.financialService.filteredInvoiceAddressesOriginal.filter((a) =>
      a.name.toLowerCase().includes(inputValue),
    );
  }

  onInvoiceAddressSelected(event: MatAutocompleteSelectedEvent) {
    const address = this.financialService.filteredInvoiceAddresses.find((a) => a.uuid == event.option.value);
    this.financialService.setInvoiceAddressValues(address);
  }

  onDeliveryAddressChange(event: KeyboardEvent) {
    let inputValue = '';
    if (event.key == 'Escape') {
      (event.target as HTMLInputElement).value = '';
      this.financialService.customerForm.get('delivery_search')?.setValue('');
    }
    inputValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.financialService.filteredDeliveryAddresses = this.financialService.filteredDeliveryAddressesOriginal.filter((a) =>
      a.name.toLowerCase().includes(inputValue),
    );
  }

  onDeliveryAddressSelected(event: MatAutocompleteSelectedEvent) {
    const address = this.financialService.filteredDeliveryAddresses.find((a) => a.uuid == event.option.value);
    this.financialService.setDeliveryAddressValues(address);
  }
}
