<div class="flex justify-center">
  <form
    class="flex grid w-full grid-cols-1 flex-col items-start px-5 lg:grid-cols-2 lg:gap-x-5"
    [formGroup]="financialService.detailsForm"
  >
    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Kunde</mat-label>
      <input [matAutocomplete]="customerAuto" (keyup)="onCompanyChange($event)" formControlName="customer" matInput />
      <mat-error class="text-end" *ngIf="financialService.detailsForm.get('customer')?.errors?.['required']"
        >Pflichtfeld</mat-error
      >
      <mat-autocomplete
        #customerAuto="matAutocomplete"
        [displayWith]="displayCustomerName"
        (optionSelected)="onCompanySelected($event)"
        panelWidth="auto"
      >
        <mat-option *ngFor="let c of financialService.filteredCompanies" [value]="c">{{ c.name_company }}</mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <financehub-input-field-template
      [optionsArray]="financialService.localsOutput"
      [formGroup]="financialService.detailsForm"
      type="select"
      label="Output Locale"
      controlName="outputLocale"
    ></financehub-input-field-template>

    <financehub-input-field-template
      *ngIf="!financialService.isEdit"
      [formGroup]="financialService.detailsForm"
      label="Angebotsdatum - Tage"
      controlName="offerDateDays"
    ></financehub-input-field-template>

    <financehub-input-field-template
      *ngIf="!financialService.isEdit"
      [readonly]="true"
      [formGroup]="financialService.detailsForm"
      label="Angebotsdatum"
      controlName="offerDate"
    ></financehub-input-field-template>

    <mat-form-field class="w-full" *ngIf="financialService.isEdit" appearance="outline">
      <mat-label>Angebotsdatum</mat-label>
      <input class="!ring-0" [matDatepicker]="picker" formControlName="offerDate" matInput />
      <mat-datepicker-toggle [for]="picker" matIconSuffix></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <financehub-input-field-template
      *ngIf="!financialService.isEdit"
      [formGroup]="financialService.detailsForm"
      label="Gültigkeitsdatum - Tage"
      controlName="validityDateDays"
    ></financehub-input-field-template>

    <financehub-input-field-template
      *ngIf="!financialService.isEdit"
      [readonly]="true"
      [formGroup]="financialService.detailsForm"
      label="Gültigkeitsdatum"
      controlName="validityDate"
    ></financehub-input-field-template>

    <mat-form-field class="w-full" *ngIf="financialService.isEdit" appearance="outline">
      <mat-label>Gültigkeitsdatum</mat-label>
      <input class="!ring-0" [matDatepicker]="picker" formControlName="validityDate" matInput />
      <mat-datepicker-toggle [for]="picker" matIconSuffix></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <financehub-input-field-template
      [optionsArray]="financialService.localsInput"
      [formGroup]="financialService.detailsForm"
      type="select"
      label="Input Locale"
      controlName="inputLocale"
    ></financehub-input-field-template>
  </form>
</div>
