<mat-tab-group #matTabGroup>
  <mat-tab label="1 - Details">
    <div class="my-5 flex w-full justify-end px-5 lg:w-4/5 lg:px-0">
      <button *ngIf="!editAddressActive" (click)="editAddress(); $event.stopPropagation()" mat-raised-button color="primary">
        <mat-icon> edit </mat-icon> bearbeiten
      </button>
      <button *ngIf="editAddressActive" (click)="updateAddress()" mat-raised-button color="primary">
        <mat-icon> done </mat-icon> speichern
      </button>
    </div>

    <div class="flex w-full justify-center">
      <form
        class="flex grid w-full grid-cols-1 flex-col items-start px-5 lg:w-4/5 lg:grid-cols-2 lg:gap-x-5 lg:px-0"
        [formGroup]="addressForm"
      >
        <financehub-input-field-template class="w-full" [formGroup]="addressForm" controlName="name" label="Name">
        </financehub-input-field-template>

        <financehub-input-field-template class="w-full" [formGroup]="addressForm" controlName="country" label="Land">
        </financehub-input-field-template>

        <financehub-input-field-template class="w-full" [formGroup]="addressForm" controlName="uuid" label="UUID">
        </financehub-input-field-template>

        <financehub-input-field-template class="w-full" [formGroup]="addressForm" controlName="bds_number" label="BDS-Nummer">
        </financehub-input-field-template>

        <financehub-input-field-template class="w-full" [formGroup]="addressForm" controlName="zip" label="PLZ">
        </financehub-input-field-template>

        <financehub-input-field-template class="w-full" [formGroup]="addressForm" controlName="city" label="Stadt">
        </financehub-input-field-template>

        <financehub-input-field-template class="w-full" [formGroup]="addressForm" controlName="street" label="Straße">
        </financehub-input-field-template>

        <financehub-input-field-template class="w-full" [formGroup]="addressForm" controlName="house_number" label="Hausnummer">
        </financehub-input-field-template>
      </form>
    </div>
  </mat-tab>

  <mat-tab [label]="getCompaniesLabel()">
    <div class="w-full lg:w-4/5">
      <div class="my-5 flex w-full justify-between px-5 lg:px-0">
        <mat-form-field class="filter-without-wrapper w-2/5 lg:w-2/5" appearance="outline">
          <mat-label>Filter</mat-label>
          <input #input0 [(ngModel)]="valueFilterCompanies" (keyup)="applyFilter($event, dataSourceCompanies)" matInput />
          <mat-icon
            class="cursor-pointer !p-1"
            *ngIf="valueFilterCompanies"
            (click)="deleteValue('companies', dataSourceCompanies)"
            matSuffix
            >close</mat-icon
          >
        </mat-form-field>
        <div class="hidden sm:flex">
          <button class="mx-2" (click)="addCompanyOverlay = true; $event.stopPropagation()" mat-raised-button color="primary">
            <mat-icon> add </mat-icon>Adresse
          </button>
          <button *ngIf="!editCompaniesActive" (click)="editCompanies()" mat-raised-button color="primary">
            <mat-icon> edit </mat-icon>bearbeiten
          </button>
          <button *ngIf="editCompaniesActive" (click)="updateCompanies()" mat-raised-button color="primary">
            <mat-icon> check </mat-icon>speichern
          </button>
        </div>
      </div>

      <div class="mat-elevation-z8 mx-5 mb-5 overflow-x-auto lg:mx-0">
        <table #companies [dataSource]="dataSourceCompanies" mat-table matSort>
          <ng-container matColumnDef="bdsNumber">
            <th class="!pl-5 !pr-2" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by number">
              bds-Nr.
            </th>
            <td class="!pl-5 !pr-2" *matCellDef="let element; let even = even; let i = index" mat-cell>
              <span *ngIf="!editCompaniesActive">{{ element.bdsNumber }}</span
              ><br />
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th class="!px-2" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by name">Firma</th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              <span *ngIf="!editCompaniesActive">{{ element.name }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="invoice">
            <th class="!px-2" *matHeaderCellDef mat-header-cell><mat-icon>local_shipping</mat-icon></th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              <mat-icon *ngIf="element.delivery && !editCompaniesActive">done</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="delivery">
            <th class="!pl-2 !pr-5" *matHeaderCellDef mat-header-cell><mat-icon>euro</mat-icon></th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              <mat-icon *ngIf="element.invoice && !editCompaniesActive">done</mat-icon>
            </td>
          </ng-container>

          <tr *matHeaderRowDef="displayedColumnsCompanies" mat-header-row></tr>
          <tr
            *matRowDef="let row; let even = even; columns: displayedColumnsCompanies"
            [ngClass]="{ '!bg-gray-200': even }"
            mat-row
          ></tr>
        </table>
      </div>
      <div class="my-5 flex justify-end px-5 lg:px-0">
        <div class="flex sm:hidden">
          <button class="mr-3" (click)="addCompanyOverlay = true; $event.stopPropagation()" mat-mini-fab color="primary">
            <mat-icon> add </mat-icon>
          </button>
          <button *ngIf="!editCompaniesActive" (click)="editCompanies(); $event.stopPropagation()" mat-mini-fab color="primary">
            <mat-icon> edit </mat-icon>
          </button>
          <button *ngIf="editCompaniesActive" (click)="updateCompanies(); $event.stopPropagation()" mat-mini-fab color="primary">
            <mat-icon> check </mat-icon>
          </button>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab [label]="getPersonsLabel()">
    <div class="sticky top-0 my-5 flex w-full justify-between px-5 lg:w-4/5 lg:px-0">
      <mat-form-field class="filter-without-wrapper w-2/5 lg:w-2/5" appearance="outline">
        <mat-label>Filter</mat-label>
        <input #input1 [(ngModel)]="valueFilterPersons" (keyup)="applyFilter($event, dataSourcePersons)" matInput />
        <mat-icon
          class="cursor-pointer !p-1"
          *ngIf="valueFilterPersons"
          (click)="deleteValue('persons', dataSourcePersons)"
          matSuffix
          >close</mat-icon
        >
      </mat-form-field>
      <div class="hidden sm:flex">
        <button class="mx-2" (click)="addPersonOverlay = true; $event.stopPropagation()" mat-raised-button color="primary">
          <mat-icon> add </mat-icon>Person
        </button>
        <button *ngIf="!editPersonsActive" (click)="editPersons()" mat-raised-button color="primary">
          <mat-icon> edit </mat-icon>bearbeiten
        </button>
        <button *ngIf="editPersonsActive" (click)="updatePersons()" mat-raised-button color="primary">
          <mat-icon> check </mat-icon>speichern
        </button>
      </div>
    </div>

    <div class="w-full lg:w-4/5">
      <div class="mat-elevation-z8 mx-5 mb-5 overflow-x-auto lg:mx-0">
        <table #persons [dataSource]="dataSourcePersons" mat-table matSort>
          <ng-container matColumnDef="bdsNumber">
            <th class="!pl-5 !pr-2" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by number">
              bds-Nr.
            </th>
            <td class="!pl-5 !pr-2" *matCellDef="let element; let even = even; let i = index" mat-cell>
              <span *ngIf="!editPersonsActive">{{ element.bdsNumber }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="lastname">
            <th class="!px-2" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by lastname">
              Nachname
            </th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              <span *ngIf="!editPersonsActive">{{ element.lastname }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="firstname">
            <th class="!px-2" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by firstname">
              Vorname
            </th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              <span *ngIf="!editPersonsActive">{{ element.firstname }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="primary">
            <th class="!px-2" *matHeaderCellDef mat-header-cell>HWS</th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              <mat-icon *ngIf="element.primary && !editPersonsActive">done</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="secondary">
            <th class="!pl-2 !pr-5" *matHeaderCellDef mat-header-cell>NWS</th>
            <td class="!px-2" *matCellDef="let element; let even = even" mat-cell>
              <mat-icon *ngIf="element.secondary && !editPersonsActive">done</mat-icon>
            </td>
          </ng-container>

          <tr *matHeaderRowDef="displayedColumnsPersons" mat-header-row></tr>
          <tr
            *matRowDef="let row; let even = even; columns: displayedColumnsPersons"
            [ngClass]="{ '!bg-gray-200': even }"
            mat-row
          ></tr>
        </table>
      </div>
      <div class="my-5 flex justify-end px-5 lg:px-0">
        <div class="flex sm:hidden">
          <button class="mx-2" (click)="addPersonOverlay = true; $event.stopPropagation()" mat-mini-fab color="primary">
            <mat-icon> add </mat-icon>
          </button>
          <button *ngIf="!editPersonsActive" mat-mini-fab color="primary"><mat-icon> edit </mat-icon></button>
          <button *ngIf="editPersonsActive" mat-mini-fab color="primary"><mat-icon> check </mat-icon></button>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
