<div class="flex justify-center">
  <div class="w-full lg:w-4/5">
    <div class="my-5 flex items-center justify-between px-5 lg:px-0">
      <mat-form-field class="companies-filter mr-3 w-3/5 lg:w-2/5" appearance="outline">
        <mat-label>Filter</mat-label>
        <input #input [(ngModel)]="valueFilter" (keyup)="applyFilter($event)" matInput />
        <mat-icon class="cursor-pointer !p-1" *ngIf="valueFilter" (click)="deleteFilter()" matSuffix>close</mat-icon>
      </mat-form-field>
      <button class="w-2/5" [routerLink]="'../company/create'" mat-raised-button color="primary"><mat-icon>add</mat-icon>Firma</button>
    </div>

    <div class="mat-elevation-z8 mx-5 mb-5 overflow-x-auto lg:mx-0">
      <table [dataSource]="dataSource" mat-table matSort>
        <ng-container matColumnDef="bds_number">
          <th class="w-4/12" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by number">bds-Nr.</th>
          <td class="relative w-4/12" *matCellDef="let element" mat-cell>{{ element.bds_number }}</td>
        </ng-container>
        <ng-container matColumnDef="name_company">
          <th
            class="w-8/12 sm:w-7/12"
            *matHeaderCellDef
            mat-header-cell
            mat-sort-header
            sortActionDescription="Sort by name_company"
          >
            Firma
          </th>
          <td class="w-8/12 sm:w-7/12" *matCellDef="let element" mat-cell>{{ element.name_company }}</td>
        </ng-container>
        <ng-container matColumnDef="icons">
          <th *matHeaderCellDef mat-header-cell></th>
          <td *matCellDef="let element" mat-cell>
            <div class="flex flex-row justify-end">
              <mat-icon class="mr-3 cursor-pointer" [routerLink]="'../company/' + element.uuid">edit</mat-icon>
              <!-- <span
                  class="material-icons cursor-pointer text-sm xs:text-lg"
                  *ngIf="
                    element.invoice_addresses.length === 0 &&
                    element.delivery_addresses.length === 0 &&
                    element.persons.length === 0 &&
                    !invoiceService.admin
                  "
                  (click)="overlayDelete(element.uuid)"
                >
                  delete
                </span> -->
              <mat-icon class="cursor-pointer" (click)="openDeleteDialog(element)"> delete </mat-icon>
            </div>
          </td>
        </ng-container>
        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr *matRowDef="let row; let even = even; columns: displayedColumns" [ngClass]="{ '!bg-gray-200': even }" mat-row></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 7, 10]" showFirstLastButtons aria-label="Select page of periodic elements">
      </mat-paginator>
    </div>
  </div>
</div>
