import { Component, OnInit, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { InvoiceService } from 'src/app/services/finance/invoice.service';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Company } from 'src/app/interfaces/company';
import { Person } from 'src/app/interfaces/person';
import { AddressesService } from 'src/app/services/crm/addresses.service';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIf, NgClass } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Address } from 'src/app/interfaces/address';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { InputFieldTemplateComponent } from '../../../finance/input-field-template/input-field-template.component';
import { SnackbarComponent } from 'src/app/snackbar/snackbar.component';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'financehub-show-address',
  templateUrl: './show-address.component.html',
  styleUrls: ['./show-address.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    MatExpansionModule,
    NgIf,
    NgClass,
    FormsModule,
    MatTableModule,
    MatSortModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatTabsModule,
    InputFieldTemplateComponent,
  ],
})
export class ShowAddressComponent implements OnInit, OnDestroy {
  constructor(
    public invoiceService: InvoiceService,
    public addressesService: AddressesService,
    public route: ActivatedRoute,
    private _snackBar: MatSnackBar,
  ) {
    this.addressForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      uuid: new FormControl('', [Validators.required]),
      bds_number: new FormControl(100000, [Validators.required]),
      country: new FormControl(''),
      zip: new FormControl(''),
      city: new FormControl(''),
      street: new FormControl(''),
      house_number: new FormControl(''),
    });
  }

  uuid!: string;
  address!: Address;
  addressForm!: FormGroup;
  editAddressActive: boolean = false;

  displayedColumnsCompanies: string[] = ['bdsNumber', 'name', 'invoice', 'delivery'];
  dataSourceCompanies!: MatTableDataSource<Company>;
  editCompaniesActive: boolean = false;
  addCompanyOverlay: boolean = false;
  valueFilterCompanies!: string;

  displayedColumnsPersons: string[] = ['bdsNumber', 'lastname', 'firstname', 'primary', 'secondary'];
  dataSourcePersons!: MatTableDataSource<Person>;
  editPersonsActive: boolean = false;
  addPersonOverlay: boolean = false;
  valueFilterPersons!: string;

  private querySubscription!: Subscription;

  @ViewChild('companies', { read: MatSort, static: true }) sortCompanies!: MatSort;
  @ViewChild('persons', { read: MatSort, static: true }) sortPersons!: MatSort;
  @ViewChild('matTabGroup') matTabGroup!: MatTabGroup;

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.uuid = params.get('uuid') || '';
      this.querySubscription = this.addressesService.getAddress(this.uuid).subscribe(({ data, loading }) => {
        this.address = { ...data.address };
        this.addressForm = new FormGroup({
          name: new FormControl({ value: this.address.name, disabled: true }, [Validators.required]),
          uuid: new FormControl({ value: this.address.uuid, disabled: true }, [Validators.required]),
          bds_number: new FormControl({ value: this.address.bds_number, disabled: true }, [Validators.required]),
          country: new FormControl({ value: this.address.country, disabled: true }),
          zip: new FormControl({ value: this.address.zip, disabled: true }),
          city: new FormControl({ value: this.address.city, disabled: true }),
          street: new FormControl({ value: this.address.street, disabled: true }),
          house_number: new FormControl({ value: this.address.house_number, disabled: true }),
        });
      });
    });
    //this.getCompanies(); TODO:
    //this.getPersons(); TODO:
  }

  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }

  enableFields() {
    this.addressForm.get('name')?.enable();
    this.addressForm.get('bds_number')?.enable();
    this.addressForm.get('country')?.enable();
    this.addressForm.get('zip')?.enable();
    this.addressForm.get('city')?.enable();
    this.addressForm.get('street')?.enable();
    this.addressForm.get('house_number')?.enable();
  }

  disableFields() {
    this.addressForm.get('name')?.disable();
    this.addressForm.get('bds_number')?.disable();
    this.addressForm.get('country')?.disable();
    this.addressForm.get('zip')?.disable();
    this.addressForm.get('city')?.disable();
    this.addressForm.get('street')?.disable();
    this.addressForm.get('house_number')?.disable();
  }

  editAddress() {
    this.editAddressActive = true;
    this.enableFields();
  }

  updateAddress() {
    this.address.name = this.addressForm.get('name')?.value;
    this.address.bds_number = parseInt(this.addressForm.get('bds_number')?.value);
    this.address.country = this.addressForm.get('country')?.value;
    this.address.city = this.addressForm.get('city')?.value;
    this.address.zip = this.addressForm.get('zip')?.value;
    this.address.street = this.addressForm.get('street')?.value;
    this.address.house_number = this.addressForm.get('house_number')?.value;
    this.addressesService.updateAddress(this.uuid, this.address).subscribe(({ data, loading }) => {
      console.log(data);
      this._snackBar.openFromComponent(SnackbarComponent, { data: { button: 'OK', message: 'Adresse  aktualisiert' } });
    });
    this.editAddressActive = false;
    this.disableFields();
  }

  editCompanies() {
    this.editCompaniesActive = true;
  }

  updateCompanies() {
    this.editCompaniesActive = false;
  }

  editPersons() {
    this.editPersonsActive = true;
  }

  updatePersons() {
    this.editPersonsActive = false;
  }

  getCompaniesLabel() {
    const companiesCount = this.dataSourceCompanies?.data?.length || 0;
    return `2 - Firmen (${companiesCount})`;
  }

  getPersonsLabel() {
    const personsCount = this.dataSourcePersons?.data?.length || 0;
    return `3 - Personen (${personsCount})`;
  }

  applyFilter(event: KeyboardEvent, dataSource: MatTableDataSource<any>) {
    if (event.key == 'Escape') {
      (event.target as HTMLInputElement).value = '';
      dataSource.filter = '';
    } else {
      const filterValue = (event.target as HTMLInputElement).value;
      dataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  deleteValue(value: string, dataSource: MatTableDataSource<any>) {
    dataSource.filter = '';
    switch (value) {
      case 'persons':
        this.valueFilterPersons = '';
        break;
      case 'companies':
        this.valueFilterCompanies = '';
        break;
    }
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const isInputFocused = document.activeElement instanceof HTMLInputElement;
    if (!isInputFocused) {
      switch (event.key) {
        case '1':
          this.matTabGroup.selectedIndex = 0;
          break;
        case '2':
          this.matTabGroup.selectedIndex = 1;
          break;
        case '3':
          this.matTabGroup.selectedIndex = 2;
          break;
        default:
          break;
      }
    }
  }
}

/*  ngAfterViewInit() {
    // TODO:
    //this.dataSourceCompanies.sort = this.sortCompanies;
    //this.dataSourcePersons.sort = this.sortPersons;
  } */

/*  getCompanies() {
    this.companies = this.invoiceService.companies
    .filter(c => c.addresses.some(addr => addr.uuid === this.invoiceService.uuid))
    .map(a => {
      const invoice = a.addresses.some(addr => addr.uuid === this.invoiceService.uuid && addr.invoice);
      const delivery = a.addresses.some(addr => addr.uuid === this.invoiceService.uuid && addr.delivery);
      return {
        ...a,
        invoice,
        delivery
      };
    });
    this.dataSourceCompanies = new MatTableDataSource<Company>(this.companies);
  } */

/* getPersons() {
    this.persons = this.invoiceService.persons
    .filter(p => p.addresses.some(addr => addr.uuid === this.invoiceService.uuid))
    .map(a => {
      const primary = a.addresses.some(addr => addr.uuid === this.invoiceService.uuid && addr.primary);
      const secondary = a.addresses.some(addr => addr.uuid === this.invoiceService.uuid && addr.secondary);
      return {
        ...a,
        primary,
        secondary
      };
    });
    this.dataSourcePersons = new MatTableDataSource<Person>(this.persons);
  } */
