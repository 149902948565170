<form [formGroup]="formGroup">
  <mat-form-field class="w-full" appearance="outline">
    <mat-label>{{ label }}</mat-label>

    <input
      class="!ring-0"
      *ngIf="type !== 'textarea' && type !== 'select' && type !== 'date'"
      [readonly]="readonly"
      [formControlName]="controlName"
      (input)="onInputChange($event)"
      matInput
    />

    <textarea
      class="!h-24 !resize-none !ring-0"
      *ngIf="type == 'textarea'"
      [readonly]="readonly"
      [formControlName]="controlName"
      matInput
    ></textarea>

    <mat-select *ngIf="type == 'select'" [formControlName]="controlName">
      <mat-option *ngFor="let element of optionsArray; let i = index" [value]="element">{{ element }}</mat-option>
    </mat-select>

    <mat-error class="text-end" *ngIf="formGroup.get(controlName)?.errors?.['required']">Pflichtfeld</mat-error>
    <mat-error class="text-end" *ngIf="formGroup.get(controlName)?.errors?.['email']">{{ label }} ungültig</mat-error>
    <mat-error class="text-end" *ngIf="formGroup.get(controlName)?.errors?.['pattern']">nur Zahlen möglich</mat-error>
  </mat-form-field>
</form>
