import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MockedKeycloakService {

  public keycloakEvents$ = new Observable(() => {});

  init() {
    return Promise.resolve(true);
  }

  getKeycloakInstance() {
    return {
      loadUserInfo: () => {
        let callback: any;
        Promise.resolve().then(() => {
          callback(environment.keyCloakUser);
        });
        return {
          success: (fn: any) => callback = fn
        };
      }
    } as any;
  }

  isLoggedIn() {
    return true;
  }

  getUserRoles() {
    // TODO add real user groups
    return [];
  }

  loadUserProfile() {
    return environment.keyCloakUser;
  }
}
