import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'financehub-time-tracking-input-template',
  templateUrl: './time-tracking-input-template.component.html',
  styleUrls: ['./time-tracking-input-template.component.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, NgClass],
})
export class TimeTrackingInputTemplateComponent {
  @Input() label!: string;
}
